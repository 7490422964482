import service from "../service";

const actions = {
  async getActivePromotions({ commit }, payload) {
    let response = await service.getActivePromotions(payload);
    commit("setItem", { resource: "activePromotions", payload: response });
  },
  async getHistory({ commit }, payload) {
    // let user = Object.assign({}, getters["user"]);
    let response = await service.getHistory(payload);
    commit("setItem", { resource: "history", payload: response });
  },
  async getExportPreviewData(_, payload) {
    let response = await service.getExportPreviewData(payload);
    return response;
  },
  async submitPricingForm(_, payload) {
    let response = await service.submitPricingForm(payload);
    return response;
  },
  async getPricingHistory({ commit }, payload) {
    let response = await service.getPricingHistory(payload);

    if (response.status == 1)
      commit("setItem", {
        resource: "pricingManagerId",
        payload: response.data.Id,
      });

    return response;
  },
  async validateFlatFlyerProducts({ commit }, payload) {
    commit("setItem", { resource: "validFlatFlyerData", payload: [] });
    commit("setItem", { resource: "errorFlatFlyerData", payload: null });

    let response = await service.validateFlatFlyerProducts(payload);
    if (response.status == 1)
      commit("setItem", {
        resource: "validFlatFlyerData",
        payload: response.data,
      });
    if (response.status == 0)
      commit("setItem", {
        resource: "errorFlatFlyerData",
        payload: response.data,
      });

    return response.status;
  },
  async updateStatus(_, payload) {
    await service.updateStatus(payload);
  },
  async verifyFlatFlyerCsv(_, payload) {
    let response = await service.verifyFlatFlyerCsv(payload);
    return response;
  },
  async submitFlatFlyerCsv(_, payload) {
    let response = await service.submitFlatFlyerCsv(payload);
    return response;
  },
  async verifyPromotionalImportCsv(_, payload) {
    let response = await service.verifyPromotionalImportCsv(payload);
    return response;
  },
  async submitPromotionalImportCsv(_, payload) {
    let response = await service.submitPromotionalImportCsv(payload);
    return response;
  },

  async verifyImportedCsv(_, payload) {
    let response = await service.verifyImportedCsv(payload);
    return response;
  },
  async submitImportedCsv(_, payload) {
    let response = await service.submitImportedCsv(payload);
    return response;
  },
};

export default actions;
