import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import createPersistedState from "vuex-persistedstate";
import auth from "@/modules/auth/store";
import pricingManager from "@/modules/pricing-manager/store";
import tagManager from "@/modules/tag-manager/store";
import userManager from "@/modules/admin/user-manager/store";
import siteManager from "@/modules/admin/site-manager/store";
import adminSetting from "@/modules/admin/settings/store";
import siteVendorCategory from "@/modules/admin/site-vendor-categories/store";

import SecureLS from "secure-ls";
var ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "G*@XZbVU2fbCX7-j",
  isCompression: false,
});

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: [
    "languages",
    "selected_language",
    "translations",
    "auth",
    "statusCode",
    "userModulerPermission",
    "userDashboardSite",
    "versionHistory",
  ],
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
});

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    auth,
    pricingManager,
    tagManager,
    userManager,
    siteManager,
    adminSetting,
    siteVendorCategory,
  },
  plugins: [dataState],
});
