<template>
  <div class="everyday-pricing-import">
    <div v-if="!isFormSubmitted">
      <b-card>
        <b-form @submit.prevent v-if="showForm">
          <b-tabs
            class="wizard"
            nav-wrapper-class="nav-wrapper"
            v-model="tabIndex"
            no-key-nav
            no-nav-style
            ref="wizard"
          >
            <b-tab title-item-class="disabledTab">
              <template #title>
                <span class="step-circle" :class="{ completed: tabIndex == 1 }"
                  >1</span
                >
                <span class="step-text">{{
                  $t(`everyday-pricing-import.find_products_heading-label`)
                }}</span>
              </template>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <b-form-group
                    id="name"
                    :label="$t(`everyday-pricing-import.name-label`)"
                    label-for="name"
                    :label-class="['form-label', 'required']"
                    :class="{ 'form-group--error': $v.formData.name.$error }"
                  >
                    <b-form-input
                      id="name"
                      v-model="formData.name"
                      :placeholder="
                        $t(`everyday-pricing-import.name-placeholder`)
                      "
                      trim
                      :class="{ 'is-invalid': $v.formData.name.$error }"
                    ></b-form-input>

                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-form-invalid-feedback id="name-feedback">
                      {{
                        $t(`everyday-pricing-import.name-required-error-msg`)
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-sm-12 col-md-6">
                  <b-form-group
                    id="siteId"
                    :label="$t(`everyday-pricing-import.select_site-label`)"
                    label-for="siteId"
                    :label-class="['form-label', 'required']"
                    :class="{ 'form-group--error': $v.formData.siteId.$error }"
                  >
                    <b-dropdown
                      id="dropdown-form"
                      :text="
                        formData.siteId == ''
                          ? $t(
                              `everyday-pricing-import.select_site-placeholder`
                            )
                          : selectedSite.Name
                      "
                      ref="dropdown"
                      block
                      menu-class="w-100 scrollable"
                      no-caret
                      lazy
                      variant="brand-dropdown"
                      :toggle-class="{
                        'is-invalid': $v.formData.siteId.$error,
                      }"
                      :class="{ 'is-invalid': $v.formData.siteId.$error }"
                    >
                      <b-dropdown-item
                        v-for="(site, index) in sitesList"
                        :key="index"
                        @click="formData.siteId = site.value"
                        :active="formData.siteId == site.value"
                        >{{ site.text }}</b-dropdown-item
                      >
                    </b-dropdown>

                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-form-invalid-feedback id="siteId-feedback">
                      {{
                        $t(
                          `everyday-pricing-import.select_site-required-error-msg`
                        )
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
              <div id="productFilters" role="group" class="form-group">
                <label for="chooseProducts" class="form-label required">{{
                  $t(`everyday-pricing-import.choose_product-label`)
                }}</label>
                <i class="icon-tooltip ml-1" id="productFilters-tootltip"></i>
                <b-tooltip target="productFilters-tootltip"
                  ><p
                    v-html="
                      $t(`everyday-pricing-import.choose_product-tooltip`)
                    "
                /></b-tooltip>

                <div class="product-filters" id="productFilters">
                  <product-filters
                    :filterLabel="
                      $t(`everyday-pricing-import.choose_product-label`)
                    "
                    v-for="(productGroup, index) in productGroups"
                    :key="index"
                    :id="productGroup.groupId"
                    :index="index"
                    :totalGroups="productGroups.length"
                    :requiredFilters="requiredFilters"
                    :disabled="formData.siteId == ''"
                    @removeProductGroup="removeProductGroup"
                    :class="{
                      'single-filter':
                        !enableProductGroupBoxStyling && !enableAddProductGroup,
                      'is-invalid':
                        formData.siteId != '' &&
                        $v.productGroups.isInvalidProductGroup &&
                        $v.productGroups.$dirty,
                    }"
                    moduleSlug="everyday-pricing-import"
                    controllerName="EverydayPricingImport.vue"
                    moduleName="Everyday Pricing Import"
                    :isPushPricing="isPushPricing"
                  />
                  <b-form-invalid-feedback>
                    {{
                      !enableProductGroupBoxStyling && !enableAddProductGroup
                        ? $t(
                            `everyday-pricing-import.choose_product-required-error-msg`
                          )
                        : $t(
                            `everyday-pricing-import.choose_product-required-error-msg`
                          ) + availableProductGroups
                    }}
                  </b-form-invalid-feedback>
                </div>
                <b-link
                  variant="link"
                  class="btn-link"
                  @click="createNewProductGroup"
                  v-if="enableProductGroupBoxStyling && enableAddProductGroup"
                  :disabled="formData.siteId == ''"
                  >Add New Product-Group</b-link
                >
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div id="startDate" role="group" class="form-group">
                    <label for="startDate" class="form-label required">{{
                      $t(`everyday-pricing-import.start_date-label`)
                    }}</label>
                    <i class="icon-tooltip ml-1" id="startDate-tootltip"></i>
                    <b-tooltip target="startDate-tootltip"
                      ><p
                        v-html="
                          $t(`everyday-pricing-import.start_date-tooltip`)
                        "
                    /></b-tooltip>

                    <div
                      class="flex w-full"
                      :class="{
                        'is-invalid': $v.formData.priceStartDate.$error,
                      }"
                    >
                      <v-date-picker
                        :locale="selected_language"
                        ref="priceStartDate"
                        class="flex-grow"
                        :class="{
                          'is-invalid': $v.formData.priceStartDate.$error,
                        }"
                        :masks="masks"
                        v-model="formData.priceStartDate"
                        is-required
                        :popover="{ visibility: 'click' }"
                        :model-config="modelConfig"
                        :disabled-dates="[
                          {
                            start: null,
                            end: yesterday,
                          },
                        ]"
                        :available-dates="{
                          start: new Date(formData.priceStartDate),
                          end: new Date(formData.priceStartDate),
                        }"
                      >
                        <template v-slot="{ inputValue }">
                          <div class="d-flex custom-datepicker">
                            <input
                              class="form-control bg-white rounded-top-right-0 rounded-bottom-right-0"
                              :class="{
                                'is-invalid': $v.formData.priceStartDate.$error,
                              }"
                              :value="inputValue"
                              @click="showStartDatePicker"
                              :placeholder="dateFormat"
                              readonly
                            />
                            <div
                              class="picker-icon"
                              :class="{
                                'is-invalid': $v.formData.priceStartDate.$error,
                              }"
                              slot="afterDateInput"
                              role="button"
                              @click="showStartDatePicker"
                            >
                              <i class="icon-date"></i>
                            </div>
                          </div>
                        </template>
                      </v-date-picker>
                    </div>

                    <b-form-invalid-feedback id="startDate-feedback">
                      {{
                        formData.priceStartDate &&
                        $v.formData.priceStartDate.$error &&
                        $v.formData.priceStartDate.minValue == false
                          ? sytemMessages.minimumDateError
                          : sytemMessages.startDateRequired
                      }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div id="endDate" role="group" class="form-group">
                    <label for="endDate" class="form-label">{{
                      $t(`everyday-pricing-import.end_date-label`)
                    }}</label>
                    <i class="icon-tooltip ml-1" id="endDate-tootltip"></i>
                    <b-tooltip target="endDate-tootltip"
                      ><p
                        v-html="$t(`everyday-pricing-import.end_date-tooltip`)"
                    /></b-tooltip>

                    <div
                      class="flex w-full"
                      :class="{ 'is-invalid': $v.formData.priceEndDate.$error }"
                    >
                      <v-date-picker
                        :locale="selected_language"
                        ref="priceEndDate"
                        class="flex-grow"
                        :masks="masks"
                        v-model="formData.priceEndDate"
                        :popover="{ visibility: 'click' }"
                        :model-config="modelConfig"
                        :disabled-dates="[
                          {
                            start: null,
                            end: yesterday,
                          },
                        ]"
                        :available-dates="{
                          start:
                            formData.priceEndDate != ''
                              ? new Date(formData.priceEndDate)
                              : new Date(),
                          end:
                            formData.priceEndDate != ''
                              ? new Date(formData.priceEndDate)
                              : null,
                        }"
                      >
                        <template v-slot="{ inputValue }">
                          <div class="d-flex custom-datepicker">
                            <input
                              class="form-control bg-white rounded-top-right-0 rounded-bottom-right-0"
                              :class="{
                                'is-invalid': $v.formData.priceEndDate.$error,
                              }"
                              :value="inputValue"
                              @click="showEndDatePicker"
                              :placeholder="dateFormat"
                              readonly
                            />
                            <div
                              class="picker-icon"
                              :class="{
                                'is-invalid': $v.formData.priceEndDate.$error,
                              }"
                              slot="afterDateInput"
                              role="button"
                              @click="showEndDatePicker"
                            >
                              <i class="icon-date"></i>
                            </div>
                          </div>
                        </template>
                      </v-date-picker>
                    </div>

                    <b-form-invalid-feedback id="priceEndDate-feedback">
                      {{
                        $t(`everyday-pricing-import.end_date-invalid-error-msg`)
                      }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title-item-class="disabledTab">
              <template #title>
                <span class="step-circle" :class="{ completed: tabIndex == 2 }"
                  >2</span
                >
                <span class="step-text">{{
                  $t(`everyday-pricing-import.import_csv_heading-label`)
                }}</span>
              </template>
              <div
                id="csvFileDiv"
                class="form-group"
                role="group"
                :class="{
                  'form-group--error':
                    $v.CSVFileData.csvFile.$error ||
                    invalidCSV ||
                    duplicateCSVData ||
                    invalidCSVSites ||
                    emptyCSV ||
                    emptySiteIdCsv ||
                    emptyVmnCsv ||
                    emptyReplacementCsv ||
                    emptyPriceCsv ||
                    invalidCSVData,
                }"
              >
                <label class="form-label required">{{
                  $t(`everyday-pricing-import.import_csv-label`)
                }}</label>
                <div
                  class="d-flex align-items-start flex-column"
                  :class="{
                    'is-invalid':
                      $v.CSVFileData.csvFile.$error ||
                      invalidCSV ||
                      duplicateCSVData ||
                      invalidCSVSites ||
                      emptyCSV ||
                      emptySiteIdCsv ||
                      emptyVmnCsv ||
                      emptyReplacementCsv ||
                      emptyPriceCsv ||
                      invalidCSVData,
                  }"
                >
                  <div class="template-div mb-3" v-if="mode && mode == 'edit'">
                    <b-link
                      @click="downloadCSVTemplate"
                      class="btn btn-brand-secondary"
                      >{{
                        $t(
                          `everyday-pricing-import.download_csv_template-label`
                        )
                      }}</b-link
                    >
                  </div>
                  <div
                    class="upload-btn-wrapper d-flex align-items-md-center flex-column flex-md-row align-items-sm-start"
                  >
                    <div>
                      <b-form-file
                        ref="csvFile"
                        id="csvFile"
                        v-model="CSVFileData.csvFile"
                        plain
                        hidden
                        accept=".csv"
                      />
                      <!-- our custom upload button -->
                      <label
                        for="csvFile"
                        class="btn btn-brand-secondary mb-2 mb-md-0"
                        >{{
                          $t(`everyday-pricing-import.csv_browse-label`)
                        }}</label
                      >
                    </div>
                    <!-- name of file chosen -->
                    <span id="file-chosen"
                      >{{
                        CSVFileData.csvFile
                          ? CSVFileData.csvFile.name
                          : $t(`everyday-pricing-import.no_csv-placeholder`)
                      }}
                      <b-link v-if="CSVFileData.csvFile" @click="removeCSVFile">
                        <i class="icon-close"></i>
                      </b-link>
                    </span>
                  </div>
                </div>

                <b-form-invalid-feedback
                  id="csvFile-feedback"
                  v-if="invalidCSV"
                >
                  {{ $t(`everyday-pricing-import.invalid_csv-error-msg`) }}
                </b-form-invalid-feedback>

                <b-form-invalid-feedback
                  id="csvFile-feedback"
                  v-else-if="emptyCSV"
                >
                  {{ $t(`everyday-pricing-import.empty_csv-error-msg`) }}
                </b-form-invalid-feedback>

                <b-form-invalid-feedback
                  id="csvFile-feedback"
                  v-else-if="invalidCSVSites"
                >
                  {{
                    $t(`everyday-pricing-import.not_match_csv_site-error-msg`)
                  }}
                </b-form-invalid-feedback>

                <b-form-invalid-feedback
                  id="csvFile-feedback"
                  v-else-if="invalidCSVData"
                >
                  {{ $t(`everyday-pricing-import.invalid_csv_data-error-msg`) }}
                </b-form-invalid-feedback>

                <b-form-invalid-feedback
                  id="csvFile-feedback"
                  v-else-if="duplicateCSVData"
                >
                  {{
                    $t(`everyday-pricing-import.duplicate_csv_data-error-msg`)
                  }}
                </b-form-invalid-feedback>

                <b-form-invalid-feedback
                  id="csvFile-feedback"
                  v-else-if="emptySiteIdCsv"
                >
                  {{ $t(`everyday-pricing-import.missing-siteId-error-msg`) }}
                </b-form-invalid-feedback>

                <b-form-invalid-feedback
                  id="csvFile-feedback"
                  v-else-if="emptyVmnCsv"
                >
                  {{ $t(`everyday-pricing-import.missing-vmn-error-msg`) }}
                </b-form-invalid-feedback>

                <b-form-invalid-feedback
                  id="csvFile-feedback"
                  v-else-if="emptyReplacementCsv"
                >
                  {{
                    $t(
                      `everyday-pricing-import.missing-replacement_cost-error-msg`
                    )
                  }}
                </b-form-invalid-feedback>

                <b-form-invalid-feedback
                  id="csvFile-feedback"
                  v-else-if="emptyPriceCsv"
                >
                  {{ $t(`everyday-pricing-import.missing-cost-error-msg`) }}
                </b-form-invalid-feedback>

                <b-form-invalid-feedback id="csvFile-feedback" v-else>
                  {{
                    $v.CSVFileData.csvFile.fileValidation == false
                      ? $t(`everyday-pricing-import.invalid_csv_file-error-msg`)
                      : $t(`everyday-pricing-import.import_csv-error-msg`)
                  }}
                </b-form-invalid-feedback>
              </div>
            </b-tab>
          </b-tabs>
        </b-form>
        <preview-form
          :formName="formData.name"
          :showProductGroups="true"
          showStartDate
          :startDate="formData.priceStartDate | formatedDate"
          showEndDate
          :endDate="formData.priceEndDate | formatedDate"
          showImportCSVFile
          :importCSVFileName="CSVFileName"
          moduleSlug="everyday-pricing-import"
          :nameLabel="$t(`everyday-pricing-import.name-label`)"
          :siteLabel="$t(`everyday-pricing-import.selected_site-label`)"
          :importedFromCSVLabel="$t(`everyday-pricing-import.import_csv-label`)"
          :startDateLabel="$t(`everyday-pricing-import.start_date-label`)"
          :endDateLabel="$t(`everyday-pricing-import.end_date-label`)"
          v-if="showFormPreview"
        />
        <div class="d-flex flex-row-reverse">
          <template v-if="showForm">
            <template v-if="tabIndex == 0">
              <b-button
                v-if="!showFilteredProducts"
                @click="findProducts"
                variant="brand-primary"
                id="find-products"
                >{{
                  $t(`everyday-pricing-import.find_products-btn-label`)
                }}</b-button
              >
              <b-button
                class="mr-2"
                v-else
                @click="navigateToImportCSV"
                variant="brand-primary"
                id="next-step"
                >{{ $t(`everyday-pricing-import.next-btn-label`) }}</b-button
              >
            </template>
            <template v-else>
              <b-button
                @click="handleValidate"
                variant="brand-primary"
                id="validate-button"
                >{{
                  $t(`everyday-pricing-import.validate-btn-label`)
                }}</b-button
              >
              <b-button
                @click="navigateToBasicInfo"
                class="mr-3"
                variant="brand-secondary"
                id="prev-step"
                >{{ $t(`everyday-pricing-import.back-btn-label`) }}</b-button
              >
            </template>
          </template>
          <template v-if="showFormPreview">
            <b-button
              variant="brand-primary"
              v-if="isUploadedProductsVerified"
              @click="submitForm"
              id="submit-button"
              >{{ $t(`everyday-pricing-import.submit-btn-label`) }}</b-button
            >
            <b-button
              class="mr-3"
              variant="brand-secondary"
              @click="handleEdit"
              id="edit-button"
              >{{ $t(`everyday-pricing-import.edit-btn-label`) }}</b-button
            >
          </template>
        </div>
      </b-card>
      <b-card
        v-if="
          productPreviewFailed ||
          (showFilteredProducts && gettersfilteredProducts.length > 0) ||
          showValidatedProducts ||
          showLastModifiedProducts
        "
        class="mt-4"
      >
        <product-preview-failed v-if="productPreviewFailed" />
        <product-preview
          v-if="showFilteredProducts && gettersfilteredProducts.length > 0"
          formName="filtered-everyday-pricing-import"
          :fileTitle="formData.name"
          moduleSlug="everyday-pricing-import"
        />
        <product-preview
          v-if="showValidatedProducts"
          formName="everyday-pricing-import"
          :fileTitle="formData.name"
          moduleSlug="everyday-pricing-import"
        />
        <div class="product-preview" v-if="showLastModifiedProducts">
          <div class="d-flex flex-row-reverse mb-3">
            <b-link
              class="export-csv my-md-0 my-2"
              :disabled="importHistoryTotalRecords == '00'"
              @click="downloadImportHistory"
              >{{ $t(`general.export-csv`) }}
              <i class="icon-download ml-1"></i>
            </b-link>
          </div>
          <b-table
            hover
            responsive
            :items="importHistoryItems"
            :fields="importHistoryFields"
            :per-page="perPage"
            :current-page="importHistoryCurrentPage"
            tbody-tr-class="text-nowrap"
            show-empty
            :empty-text="$t('general.no-data-msg')"
          >
            <template #head(products)>
              {{ $t(`everyday-pricing-import.table_head_products`) }}
            </template>
            <template #head(siteName)>
              {{ $t(`everyday-pricing-import.table_head_siteName`) }}
            </template>
            <template #head(sku)>
              {{ $t(`everyday-pricing-import.table_head_sku`) }}
            </template>
            <template #head(vnm)>
              {{ $t(`everyday-pricing-import.table_head_vmn`) }}
            </template>
            <template #head(cost)>
              {{ $t(`everyday-pricing-import.table_head_everydayCost`) }}
            </template>
            <template #head(price)>
              {{ $t(`everyday-pricing-import.table_head_regularPrice`) }}
            </template>
            <template #head(lastModified)>
              {{ $t(`everyday-pricing-import.table_head_lastmodified`) }}
            </template>

            <template #cell(products)="data">
              <b-img
                src="/media/icons/rectangle.svg"
                alt="Product Rectangle Icon"
              />{{ data.item.ProductName }}
            </template>

            <template #cell(siteName)="data">
              {{ data.item.SiteName }}
            </template>

            <template #cell(sku)="data">
              {{ data.item.ProductSKU }}
            </template>

            <template #cell(vnm)="data">
              {{ data.item.VendorModelNumber }}
            </template>

            <template #cell(cost)="data">
              {{ data.item.RegularPrice }}
            </template>

            <template #cell(price)="data">
              {{ data.item.FlyerPrice }}
            </template>

            <template #cell(lastModified)="data">
              {{ data.item.CreatedOn | formatedHistoryDate }}
            </template>
          </b-table>
          <table-pagination
            class="mt-3"
            :fromLimit="importHistoryFromLimit | addLeadingZero"
            :toLimit="importHistoryToLimit | addLeadingZero"
            :totalRecords="importHistoryTotalRecords | addLeadingZero"
            :currentPage="importHistoryCurrentPage"
            :totalPages="importHistoryTotalPages"
            @paginate="importHistoryPaginate"
            :countInfo="`everyday-pricing-import.table_count-info`"
          />
        </div>
      </b-card>
      <b-modal
        dialog-class="success-modal"
        centered
        hide-header
        hide-footer
        ref="success-modal"
      >
        <div
          class="d-flex justify-content-center flex-column align-items-center text-center"
        >
          <i class="font-50 icon-completed mb-2"></i>
          <div class="message-detail">
            <h5 class="msg-title">
              {{ $t(`everyday-pricing-import.success_message-title`) }}
            </h5>
            <i18n
              :path="`everyday-pricing-import.success_message-desc`"
              tag="p"
              class="msg-desc m-0"
            >
              <b-link to="/pricing-history">{{
                $t(`everyday-pricing-import.success_message-desc_term`)
              }}</b-link>
            </i18n>
          </div>
        </div>
      </b-modal>
    </div>
    <div v-else>
      <b-card class="mt-4">
        <form-success>
          <h5 class="msg-title">
            {{ $t(`everyday-pricing-import.success_message-title`) }}
          </h5>
          <i18n
            :path="`everyday-pricing-import.success_message-desc`"
            tag="p"
            class="msg-desc m-0"
          >
            <b-link to="/pricing-history">{{
              $t(`everyday-pricing-import.success_message-desc_term`)
            }}</b-link>
          </i18n>
        </form-success>
      </b-card>
    </div>
  </div>
</template>

<script>
import config from "../../../../config";
import ProductFilters from "../../../components/ProductFilters.vue";
import ProductPreviewFailed from "../../../components/ProductPreviewFailed.vue";
import ProductPreview from "../../../components/ProductPreview.vue";
import PreviewForm from "../../../components/PreviewForm.vue";
import FormSuccess from "../../../components/FormSuccess.vue";
import TablePagination from "../../../components/TablePagination.vue";
import { eventBus } from "@/main";
import { required, requiredIf } from "vuelidate/lib/validators";
import moment from "moment";
import {
  forEach,
  map,
  max,
  cloneDeep,
  isEqual,
  keys,
  sortBy,
  uniq,
  includes,
  isObject,
  ceil,
  clone,
  trim,
  isEmpty,
} from "lodash";

import { mapGetters, mapMutations, mapActions } from "vuex";

const isValidProductGroup = (productGroup) => {
  let isValid = false;
  if (
    productGroup.vendor.length != 0 ||
    productGroup.category.length != 0 ||
    productGroup.productTags.length != 0 ||
    productGroup.productVendorModel.length != 0 ||
    productGroup.productSKU.length != 0
  ) {
    isValid = true;
  }

  return isValid;
};

const isInvalidProductGroup = (value) => {
  let nonValidatedProductGroups = [];
  let validateStatus = [];
  forEach(value, (productGroup, index) => {
    let isValid = isValidProductGroup(productGroup);
    validateStatus.push(isValid);
    if (!isValid) nonValidatedProductGroups.push(index + 1);
  });

  return validateStatus.includes(false);
};
const {
  filterErrorObject,
  dateFormat,
  formPriority,
  exportCSVHeaders,
  tableHeaders,
  pricingManagerConfig,
} = config;

export default {
  name: "EverydayPricingImport",
  components: {
    ProductFilters,
    ProductPreviewFailed,
    ProductPreview,
    PreviewForm,
    FormSuccess,
    TablePagination,
  },
  metaInfo() {
    return {
      title: `${this.$t("general.everyday-pricing-import")} | ${this.$t(
        "general.everyday-pricing"
      )} | ${this.$t("general.pricing-manager")}`,
    };
  },
  data() {
    return {
      isPushPricing: pricingManagerConfig.isPushPricing,
      enableProductGroupBoxStyling: config.enableProductGroupBoxStyling,
      enableAddProductGroup: config.enableAddProductGroup,
      showForm: true,
      showFormPreview: false,
      showFilteredProducts: false,
      isFormSubmitted: false,
      productPreviewFailed: false,
      showValidatedProducts: false,
      invalidCSV: false,
      invalidCSVSites: false,
      invalidCSVData: false,
      emptyCSV: false,
      duplicateCSVData: false,

      emptySiteIdCsv: false,
      emptyVmnCsv: false,
      emptyReplacementCsv: false,
      emptyPriceCsv: false,

      priority: formPriority["everyday-pricing"],
      tabIndex: 0,
      formData: {
        id: 0,
        name: "",
        siteId: "",
        priceStartDate: new Date(),
        priceEndDate: "",
      },
      CSVFileData: {
        csvFile: null,
      },
      filteredProducts: [],
      csvFileData: [],
      CSVFileName: "",
      csvFields: exportCSVHeaders["filtered-everyday-pricing-import"],
      // requiredFilters: ["vendorFilter", "categoryFilter", "productTagFilter"],
      requiredFilters: [
        "vendorFilter",
        "categoryFilter",
        "productTagFilter",
        "vendorModelFilter",
        "skuFilter",
      ],
      dateFormat: dateFormat,
      masks: {
        input: dateFormat,
      },
      modelConfig: {
        type: "string",
        mask: "iso",
      },
      validatedProducts: [],
      isUploadedProductsVerified: false,

      // All the data used for history of import flyer
      importHistoryCurrentPage: 1,
      importHistoryData: [],
      perPage: 20,
      showLastModifiedProducts: false,
      importHistoryFields: tableHeaders[`everyday-pricing-import-history`],
      // importHistoryCsvFields: exportCSVHeaders[`${this.moduleSlug}-history`],
      importHistoryCsvFields:
        exportCSVHeaders["everyday-pricing-import-history"],
    };
  },
  computed: {
    ...mapGetters([
      "productGroups",
      "filteredProductError",
      "selectedSite",
      "selected_language",
    ]),
    ...mapGetters({ gettersfilteredProducts: "filteredProducts" }),
    ...mapGetters("auth", ["siteAccess"]),
    ...mapGetters("pricingManager", [
      "pricingManagerId",
      "mode",
      "preFilledData",
    ]),
    sitesList() {
      // let sites = [{ text: "Select site", value: "", disabled: true }];
      let sites = [];
      forEach(this.siteAccess, (site) => {
        let obj = { text: site.Name, value: site.SiteId };
        sites.push(obj);
      });
      return sites;
    },
    templateCSVHeader() {
      let header = {};
      forEach(this.csvFields, (field) => {
        header[field.key] = field.label;
      });
      return header;
    },
    availableProductGroups() {
      let group = [];
      forEach(this.productGroups, (productGroup, index) => {
        group.push(index + 1);
      });
      return group.join();
    },
    // All the computed property used for history of import flyer
    importHistoryItems() {
      return this.importHistoryData;
    },
    importHistoryTotalRecords() {
      return this.importHistoryItems.length;
    },
    importHistoryTotalPages() {
      return ceil(this.importHistoryTotalRecords / this.perPage);
    },
    importHistoryFromLimit() {
      let response = this.getPaginationResultLimit(
        this.importHistoryCurrentPage,
        this.perPage,
        this.importHistoryTotalRecords
      );
      return response.fromlimit;
    },
    importHistoryToLimit() {
      let response = this.getPaginationResultLimit(
        this.importHistoryCurrentPage,
        this.perPage,
        this.importHistoryTotalRecords
      );
      return response.tolimit;
    },
  },
  methods: {
    ...mapActions([
      "setProductGroups",
      "resetFormState",
      "getEverydayImportFilteredProducts",
    ]),
    ...mapActions("pricingManager", ["verifyImportedCsv", "submitImportedCsv"]),
    ...mapMutations(["setItem"]),
    ...mapMutations("pricingManager", { pricingSetItem: "setItem" }),
    navigateToImportCSV() {
      this.$v.formData.$touch();

      if (this.$v.formData.$invalid) {
        return;
      }

      this.tabIndex = 1;
      this.showFilteredProducts = false;
      this.showValidatedProducts = false;
    },
    navigateToBasicInfo() {
      this.tabIndex = 0;
      this.showFilteredProducts = true;
      this.showValidatedProducts = false;
    },
    createNewProductGroup() {
      let groupIds = map(this.productGroups, (_data) => Number(_data.groupId));
      let lastGroupId = max(groupIds);
      this.addNewProductGroup(lastGroupId + 1);
    },
    removeProductGroup(index) {
      let _productGroup = this.productGroups;
      _productGroup.splice(index, 1);
      this.setProductGroups({ type: "update", data: _productGroup });
    },
    showStartDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceEndDate.hidePopover();
        this.$refs.priceStartDate.togglePopover();
      });
    },
    showEndDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceStartDate.hidePopover();
        this.$refs.priceEndDate.togglePopover();
      });
    },
    hideStartDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceStartDate.hidePopover();
      });
    },
    hideEndDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceEndDate.hidePopover();
      });
    },
    showSuccessModal() {
      this.$refs["success-modal"].show();
    },
    hideSuccessModal() {
      this.$refs["success-modal"].hide();
    },
    async createInput(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsText(file);
      });
    },
    handleValidate() {
      this.invalidCSV = false;
      this.emptyCSV = false;
      this.invalidCSVSites = false;
      this.invalidCSVData = false;
      this.duplicateCSVData = false;

      this.emptySiteIdCsv = false;
      this.emptyVmnCsv = false;
      this.emptyReplacementCsv = false;
      this.emptyPriceCsv = false;

      this.$v.CSVFileData.$touch();

      if (this.$v.CSVFileData.$invalid) {
        return;
      } else {
        let siteIds = [];
        siteIds.push(this.formData.siteId);
        if (this.csvFileData.length == 0) {
          this.emptyCSV = true;
          return;
        }

        let csvFileDataLocal = cloneDeep(this.csvFileData);

        let csvHeader = keys(csvFileDataLocal[0]);
        let templateCSVHeader = keys(this.templateCSVHeader);

        if (csvFileDataLocal.length == 0) {
          this.invalidCSVData = true;
          return;
        }

        let validColumns = csvFileDataLocal.every(
          (value) => Object.keys(value).length === templateCSVHeader.length
        );

        if (
          (!isEqual(sortBy(csvHeader), sortBy(templateCSVHeader)) &&
            validColumns == true) ||
          validColumns == false
        ) {
          this.invalidCSV = true;
          return;
        }

        const isSiteIdBlank = csvFileDataLocal.every(
          (value) => value.siteId != ""
        );

        if (isSiteIdBlank === false) {
          this.emptySiteIdCsv = true;
          return;
        }

        const isVendorModelNumberBlank = csvFileDataLocal.every(
          (value) => value.vendorModelNumber != ""
        );

        if (isVendorModelNumberBlank === false) {
          this.emptyVmnCsv = true;
          return;
        }

        const isReplacementCostBlank = csvFileDataLocal.every(
          (value) => value.replacementCost != ""
        );

        if (isReplacementCostBlank === false) {
          this.emptyReplacementCsv = true;
          return;
        }

        const isPriceBlank = csvFileDataLocal.every(
          (value) => value.price != ""
        );

        if (isPriceBlank === false) {
          this.emptyPriceCsv = true;
          return;
        }

        const isNullish = csvFileDataLocal.every((value) => {
          if (
            value.siteId != "" &&
            value.vendorModelNumber != "" &&
            value.replacementCost != "" &&
            value.price != ""
          ) {
            return true;
          }

          return false;
        });

        if (!isNullish) {
          this.invalidCSVData = true;
          return;
        }

        let csvSiteIds = uniq(csvFileDataLocal.map((_file) => _file.siteId));
        let invalidSites = [];
        forEach(csvSiteIds, (siteId) => {
          if (!includes(siteIds, Number(siteId))) {
            invalidSites.push(siteId);
          }
        });

        if (invalidSites.length > 0) {
          this.invalidCSVSites = true;
          return;
        }

        let invalidData = csvFileDataLocal.filter((_data) => {
          // return Number(_data.compareAtPrice) < Number(_data.price);
          if (Number(_data.replacementCost) <= 0) {
            return true;
          } else if (Number(_data.price) <= 0) {
            return true;
          } else if (Number(_data.replacementCost) > Number(_data.price)) {
            return true;
          }
        });

        if (invalidData.length > 0) {
          this.invalidCSVData = true;
          return;
        }

        forEach(csvFileDataLocal, (data) => {
          if ("sku" in data) {
            data["productId"] = data["sku"];
            delete data["sku"];
          }
          if ("vendorModelNumber" in data) {
            data["VendorModelNumber"] = data["vendorModelNumber"];
            delete data["vendorModelNumber"];
          }

          if ("price" in data) {
            data["promoPrice"] = data["price"];
            delete data["price"];
          }

          if ("replacementCost" in data) {
            data["compareAtPrice"] = data["replacementCost"];
            delete data["replacementCost"];
          }

          if (data["productId"] == "") {
            delete data["productId"];
          }
        });

        let sdate = moment(this.formData.priceStartDate);
        sdate.set("hour", 0);
        sdate.set("minute", 0);
        sdate.set("second", 0);
        sdate.set("millisecond", 0);

        let edate = "";

        if (this.formData.priceEndDate != "") {
          edate = moment(this.formData.priceEndDate);
          edate.set("hour", 0);
          edate.set("minute", 0);
          edate.set("second", 0);
          edate.set("millisecond", 0);

          if (
            moment(edate)._i == "2099-12-31T00:00:00" ||
            moment(edate)._i == "2099-12-31T00:00:00Z" ||
            moment(edate)._i == "2099-12-31T00:00:00.000Z"
          ) {
            edate = "";
          }
        }

        let isValidStartDate = this.checkStartDateValidation(
          sdate,
          edate,
          this.mode
        );

        if (isValidStartDate == false) {
          this.$bvToast.toast(this.sytemMessages.minimumDateError, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
            solid: true,
          });
          return;
        }

        let isValidEndDate = this.checkEndDateValidation(sdate, edate);

        if (isValidEndDate == false) {
          this.$bvToast.toast(
            this.$t(`everyday-pricing-import.end_date-invalid-error-msg`),
            {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
              solid: true,
            }
          );
          return;
        }

        let validateDuplicateCSVData =
          this.validateDuplicateCSVData(csvFileDataLocal);

        if (!validateDuplicateCSVData) {
          this.duplicateCSVData = true;
          return;
        }

        const h = this.$createElement;

        const titleVNode = h("h5", {
          class: ["modal-title font-weight-bold"],
          domProps: {
            innerHTML: this.$t(
              `everyday-pricing-import.upload_confirmation-title`
            ),
          },
        });

        const messageVNode = h("p", { class: ["text-center m-0 font-14"] }, [
          this.$t(`everyday-pricing-import.upload_confirmation-msg`),
        ]);

        this.$bvModal
          .msgBoxConfirm([messageVNode], {
            title: [titleVNode],
            size: "sm",
            dialogClass: "confirm-import-modal",
            buttonSize: "sm",
            okVariant: "brand-primary w-50 confirm-btn",
            cancelVariant: "brand-secondary border-0 w-50 cancel-btn",
            okTitle: this.$t(
              `everyday-pricing-import.upload_confirmation_proceed_btn-label`
            ),
            cancelTitle: this.$t(
              `everyday-pricing-import.upload_confirmation_cancel_btn-label`
            ),
            headerClass: "justify-content-center p-0 mt-3 border-bottom-0",
            footerClass: "p-3 border-top-0 flex-nowrap",
            hideHeaderClose: true,
            centered: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
          })
          .then((value) => {
            if (value) {
              let _priceEndDate = "2099-12-31T00:00:00Z";

              this.formData.priceStartDate = new Date(
                this.formData.priceStartDate
              ).toISOString();

              this.formData.priceEndDate =
                this.formData.priceEndDate == "" ||
                this.formData.priceEndDate == null
                  ? _priceEndDate
                  : new Date(this.formData.priceEndDate).toISOString();

              this.setItem({ resource: "isLoading", payload: true });

              let _csvFileDataLocal = csvFileDataLocal.map((item) => ({
                ...item,
                priceTypeId: `${this.priority}`,
              }));

              this.verifyImportedCsv({
                // data: csvFileDataLocal,
                data: _csvFileDataLocal,
                exceptionParams: {
                  userId: this.loggedUserId,
                  controllerName: "EverydayPricingImport.vue",
                  moduleName: "Everyday Pricing Import",
                },
              })
                .then((response) => {
                  this.setItem({ resource: "isLoading", payload: false });
                  if (response.status == 1) {
                    if (response.data.length == 0) {
                      this.productPreviewFailed = true;
                    } else {
                      this.validatedProducts = response.data;
                      this.pricingSetItem({
                        resource: "validEverydayImportData",
                        payload: response.data,
                      });
                      this.isUploadedProductsVerified = response.data.every(
                        (item) => item.status == 1
                      );
                      this.showForm = false;

                      this.showValidatedProducts = true;
                      this.showFormPreview = true;
                    }
                  }
                })
                .catch(() => {
                  this.setItem({ resource: "isLoading", payload: false });
                });
            }
          })
          .catch(() => {
            // An error occurred
            this.setItem({ resource: "isLoading", payload: false });
          });
      }
    },
    async findProducts() {
      this.$v.formData.$touch();
      this.$v.productGroups.$touch();

      if (
        this.$v.formData.$invalid ||
        this.$v.productGroups.isInvalidProductGroup
      ) {
        return;
      } else {
        let _priceEndDate = "2099-12-31T00:00:00Z";

        this.formData.priceEndDate =
          this.formData.priceEndDate == "" || this.formData.priceEndDate == null
            ? _priceEndDate
            : new Date(this.formData.priceEndDate).toISOString();

        let formData = Object.assign({}, this.formData);
        formData.userId = this.loggedUserId;
        formData.priceTypeId = this.priority;
        formData.productGroups = this.productGroups;
        formData.priceStartDate = new Date(
          formData.priceStartDate
        ).toISOString();

        formData.exceptionParams = {
          userId: this.loggedUserId,
          controllerName: "EverydayPricingImport.vue",
          moduleName: "Everyday Pricing Import",
        };

        await this.getEverydayImportFilteredProducts(formData);

        if (this.filteredProductError == false) {
          this.showFilteredProducts = true;
          this.productPreviewFailed = false;
        } else {
          this.productPreviewFailed = true;
        }
      }
    },
    async submitForm() {
      this.setItem({ resource: "isLoading", payload: true });
      let validatedProducts = this.validatedProducts;
      if (this.mode == "edit" && this.importHistoryData.length > 0) {
        validatedProducts = [];
        forEach(this.importHistoryData, (data) => {
          let obj = {
            compareAtPrice: data.RegularPrice,
            productId: data.ProductSKU,
            productName: data.ProductName,
            promoPrice: data.FlyerPrice,
            siteId: data.SiteId,
            siteName: data.SiteName,
            status: 1,
            statusMessage: "SUCCESS",
            vendorModelNumber: data.VendorModelNumber,
          };

          validatedProducts.push(obj);
        });
      }

      let final_filteredProducts = validatedProducts.filter(
        (item) => item.status == 1
      );

      let submitData = {
        id: this.formData.id,
        userId: this.loggedUserId,
        name: this.formData.name,
        priceStartDate: this.formData.priceStartDate,
        priceEndDate: this.formData.priceEndDate,
        priceTypeId: `${this.priority}`,
        pricingData: final_filteredProducts,
        productGroups: this.productGroups,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: "EverydayPricingImport.vue",
          moduleName: "Everyday Pricing Import",
        },
      };

      this.submitImportedCsv(submitData)
        .then((response) => {
          if (response.status == 1) {
            // this.isFormSubmitted = true;
            this.showSuccessModal();
            this.formData.siteId = "";
          }
          this.setItem({ resource: "isLoading", payload: false });
        })
        .catch(() => {
          this.setItem({ resource: "isLoading", payload: false });
        });
    },
    resetForm() {
      this.resetFormState();
      this.$v.$reset();
      //   this.showForm = true;
      //   this.showFormPreview = false;
      //   this.showFilteredProducts = false;
      //   this.productPreviewFailed = false;
      this.tabIndex = 0;
      this.showForm = true;
      this.showFormPreview = false;
      this.showFilteredProducts = false;
      this.isFormSubmitted = false;
      this.productPreviewFailed = false;
      this.showValidatedProducts = false;
      this.invalidCSV = false;
      this.invalidCSVSites = false;
      this.invalidCSVData = false;
      this.formData.id = 0;
      this.formData.name = "";
      this.formData.priceStartDate = new Date();
      this.formData.priceEndDate = "";
      this.CSVFileData.csvFile = null;
      this.emptySiteIdCsv = false;
      this.emptyVmnCsv = false;
      this.emptyReplacementCsv = false;
      this.emptyPriceCsv = false;
      this.duplicateCSVData = false;
    },
    async handleEdit() {
      this.showForm = true;
      this.showFormPreview = false;
      this.productPreviewFailed = false;
      this.showValidatedProducts = false;
      this.tabIndex = 1;
      this.showLastModifiedProducts = false;
      this.showFilteredProducts = false;

      this.filteredProducts = [];
      this.importHistoryData = [];

      if (this.gettersfilteredProducts.length == 0) {
        let formData = Object.assign({}, this.formData);
        formData.userId = this.loggedUserId;
        formData.priceTypeId = this.priority;
        formData.productGroups = this.productGroups;
        formData.priceStartDate = new Date(
          formData.priceStartDate
        ).toISOString();

        formData.exceptionParams = {
          userId: this.loggedUserId,
          controllerName: "EverydayPricingImport.vue",
          moduleName: "Everyday Pricing Import",
        };

        await this.getEverydayImportFilteredProducts(formData);
      }
    },
    importHistoryPaginate(eventType) {
      if (eventType == "next") {
        this.importHistoryCurrentPage = this.importHistoryCurrentPage + 1;
      } else {
        this.importHistoryCurrentPage = this.importHistoryCurrentPage - 1;
      }
    },
    downloadImportHistory() {
      // flyer-pricing-import-history
      try {
        this.setItem({ resource: "isLoading", payload: true });
        let headers = {};
        forEach(this.importHistoryCsvFields, (field) => {
          headers[field.key] = field.label;
        });

        let csvData = [];

        forEach(this.importHistoryData, (data) => {
          let _csvData = {};
          forEach(headers, (val, key) => {
            _csvData[key] = "";

            if (key == "products") {
              if ("ProductName" in data) {
                _csvData[key] = String(data.ProductName).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "siteName") {
              if ("SiteName" in data) {
                _csvData[key] = String(data.SiteName).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "sku") {
              if ("ProductSKU" in data) {
                _csvData[key] = String(data.ProductSKU).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "vnm" || key == "VendorModelNumber") {
              if ("VendorModelNumber" in data) {
                _csvData[key] = String(data.VendorModelNumber).replace(
                  /,/g,
                  ""
                ); // remove commas to avoid errors,
              }
              if ("vnm" in data) {
                _csvData[key] = String(data.vnm).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "cost") {
              if ("RegularPrice" in data) {
                _csvData[key] = String(data.RegularPrice).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "price") {
              if ("FlyerPrice" in data) {
                _csvData[key] = String(data.FlyerPrice).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "lastModified") {
              if ("CreatedOn" in data) {
                let _date = String(data.CreatedOn).replace(/,/g, "");
                _csvData[key] = `${moment(_date).format("DD-MMM-YYYY h:mm A")}`; // remove commas to avoid errors,
              }
            }
          });
          csvData.push(_csvData);
        });

        let fileTitle =
          this.formData.name + "_" + moment(new Date()).format("YYYY/MM/DD");
        this.setItem({ resource: "isLoading", payload: false });

        this.exportCSVFile(headers, csvData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
      } catch {
        this.setItem({ resource: "isLoading", payload: false });
      }
    },
    downloadCSVTemplate() {
      try {
        this.setItem({ resource: "isLoading", payload: true });
        let headers = this.templateCSVHeader;
        let csvData = [];

        forEach(this.csvFileData, (data) => {
          let _csvData = {};
          forEach(headers, (val, key) => {
            _csvData[key] = "";

            if (key == "siteId") {
              if ("SiteId" in data) {
                _csvData[key] = String(data.SiteId).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "sku") {
              if ("ProductSKU" in data) {
                _csvData[key] = String(data.ProductSKU).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (
              key == "vnm" ||
              key == "VendorModelNumber" ||
              key == "vendorModelNumber"
            ) {
              if ("VendorModelNumber" in data) {
                _csvData[key] = String(data.VendorModelNumber).replace(
                  /,/g,
                  ""
                ); // remove commas to avoid errors,
              }
              if ("vendorModelNumber" in data) {
                _csvData[key] = String(data.vendorModelNumber).replace(
                  /,/g,
                  ""
                ); // remove commas to avoid errors,
              }
              if ("vmn" in data) {
                _csvData[key] = String(data.vmn).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "replacementCost" || key == "ReplacementCost") {
              if ("RegularPrice" in data) {
                _csvData[key] = String(data.RegularPrice).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }

            if (key == "price") {
              if ("FlyerPrice" in data) {
                _csvData[key] = String(data.FlyerPrice).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }
          });
          csvData.push(_csvData);
        });

        let fileTitle =
          "Everyday Pricing Import Template_" +
          moment(new Date()).format("YYYY/MM/DD");
        this.setItem({ resource: "isLoading", payload: false });

        this.exportCSVFile(headers, csvData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
      } catch {
        this.setItem({ resource: "isLoading", payload: false });
      }
    },
    removeCSVFile() {
      // this.formData.csvFile = null;
      this.CSVFileData = {
        csvFile: null,
      };
      this.invalidCSV = false;
      this.emptyCSV = false;
      this.invalidCSVSites = false;
      this.invalidCSVData = false;

      this.emptySiteIdCsv = false;
      this.emptyVmnCsv = false;
      this.emptyReplacementCsv = false;
      this.emptyPriceCsv = false;
      // this.$refs["csvFile"].reset();
    },
  },
  watch: {
    "formData.siteId": function (newVal, oldVal) {
      let selectedSite = this.siteAccess.find((site) => site.SiteId == newVal);

      if (oldVal != "") this.resetForm();
      if (!selectedSite) return;

      this.setItem({ resource: "selectedSite", payload: selectedSite });
      let _filterErrorObject = Object.assign({}, filterErrorObject);
      this.setItem({ resource: "filterError", payload: _filterErrorObject });
      eventBus.$emit("resetFilters");
    },
    "CSVFileData.csvFile": async function (newValue) {
      this.csvFileData = [];
      this.productPreviewFailed = false;
      this.invalidCSV = false;
      this.invalidCSVSites = false;
      this.invalidCSVData = false;
      this.duplicateCSVData = false;
      if (newValue != null) {
        this.CSVFileName = newValue.name;
        let fileContent = await this.createInput(newValue);
        let delimiter = this.csvHelpers.guessDelimiters(fileContent, [
          "\t",
          ",",
        ]);
        if (delimiter.length == 0) {
          this.csvFileData = [];
        } else {
          let jsonContent = JSON.parse(
            this.csvHelpers.CSVToJSON(fileContent, delimiter)
          );

          let _csvFileData = jsonContent.filter((element, index) => {
            return (
              (element.siteId != "" ||
                element.vendorModelNumber != "" ||
                element.compareAtPrice != "" ||
                element.price != "") &&
              index < jsonContent.length - 1
            );
          });

          this.csvFileData = _csvFileData;
        }
      }
    },
    formData: {
      handler: function () {
        if (this.showFilteredProducts) this.showFilteredProducts = false;
      },
      deep: true,
    },
    productGroups: {
      handler: function () {
        if (this.showFilteredProducts) this.showFilteredProducts = false;
      },
      deep: true,
    },
    selected_language() {
      this.$v.$reset();
    },
  },
  mounted() {
    this.resetForm();

    let preFilledData = this.preFilledData;

    if (
      preFilledData &&
      isObject(preFilledData) &&
      Object.keys(preFilledData).length > 0
    ) {
      let productCondition = preFilledData.ConditionType;

      let flyerPricing = preFilledData.flyerpricing;

      this.CSVFileName = preFilledData.Name + ".csv";

      this.formData.id = this.mode == "edit" ? preFilledData.Id : 0;
      this.formData.name =
        this.mode == "edit"
          ? preFilledData.Name
          : "Copy of " + preFilledData.Name;

      this.formData.priceStartDate = new Date(preFilledData.PriceStartDate);

      if (
        preFilledData.PriceEndDate == "2099-12-31T00:00:00" ||
        preFilledData.PriceEndDate == "2099-12-31T00:00:00Z" ||
        preFilledData.PriceEndDate == "2099-12-31T00:00:00.000Z"
      ) {
        let date = moment(preFilledData.PriceEndDate);
        date.set("hour", 0);
        date.set("minute", 0);
        date.set("second", 0);
        date.set("millisecond", 0);

        if (this.mode == "clone") this.formData.priceEndDate = null;
        else this.formData.priceEndDate = date;
      } else {
        this.formData.priceEndDate = new Date(preFilledData.PriceEndDate);
      }
      if (flyerPricing && flyerPricing.length) {
        let csvSiteIds = uniq(flyerPricing.map((a) => a.SiteId));
        let selectedSites = [];
        this.sitesList.forEach((site) => {
          if (csvSiteIds.includes(site.value)) {
            selectedSites.push(site.value);
          }
        });

        let siteId = selectedSites[0];

        this.formData.siteId = siteId;

        this.csvFileData = this.importHistoryData = flyerPricing;
        if (productCondition && productCondition.length > 0) {
          let _productGroups = [];

          let proccessData = productCondition.reduce(function (results, item) {
            let loopIndex = "key_" + item.ProductGroupId;

            results[loopIndex] = results[loopIndex] || [];

            results[loopIndex].vendor = results[loopIndex].vendor || [];
            results[loopIndex].category = results[loopIndex].category || [];
            results[loopIndex].productTags =
              results[loopIndex].productTags || [];
            results[loopIndex].productVendorModel =
              results[loopIndex].productVendorModel || [];
            results[loopIndex].productSKU = results[loopIndex].productSKU || [];

            if (item.ConditionType == "Vendor") {
              let vendorObj = {
                condition: trim(item.Condition),
                siteId: siteId,
                vendorId: trim(item.ConditionId),
                vendorName: trim(item.ConditionValue),
              };

              results[loopIndex].vendor.push(vendorObj);
            } else if (item.ConditionType == "Category") {
              let cateObj = {
                roomGroupingId: trim(item.ConditionId),
                roomGrouping: trim(item.ConditionValue),
                condition: trim(item.Condition),
                siteId: siteId,
                storeBrandId: item.storeBrandId
                  ? trim(item.storeBrandId)
                  : null,
              };

              results[loopIndex].category.push(cateObj);
            } else if (item.ConditionType == "ProductTag") {
              let tagObj = {
                id: trim(item.ConditionId),
                tag: trim(item.ConditionValue),
                condition: trim(item.Condition),
                siteId: this.formData.siteId,
              };

              results[loopIndex].productTags.push(tagObj);
            } else if (item.ConditionType == "VendorModelNumber") {
              let vmnObj = {
                productVendorModelId: trim(item.ConditionId),
                productVendorModel: trim(item.ConditionValue),
                condition: trim(item.Condition),
              };

              results[loopIndex].productVendorModel.push(vmnObj);
            } else if (item.ConditionType == "ProductSKU") {
              let skuObj = {
                productSKUId: trim(item.ConditionId),
                productSKU: trim(item.ConditionValue),
                condition: trim(item.Condition),
              };

              results[loopIndex].productSKU.push(skuObj);
            }
            return results;
          }, {});

          let _productGroupObject = {
            groupId: "1",
            vendor: [],
            category: [],
            productTags: [],
            productVendorModel: [],
            productSKU: [],
          };

          for (const key in proccessData) {
            let groupId = key.replace("key_", "");

            let obj = clone(_productGroupObject);
            obj.groupId = groupId;
            obj.vendor = proccessData[key]["vendor"];
            obj.category = proccessData[key]["category"];
            obj.productTags = proccessData[key]["productTags"];
            obj.productVendorModel = proccessData[key]["productVendorModel"];
            obj.productSKU = proccessData[key]["productSKU"];

            _productGroups.push(obj);

            setTimeout(() => {
              eventBus.$emit("setFilter", {
                id: groupId,
                filterData: [
                  {
                    filterName: "vendorFilter",
                    filters: obj.vendor,
                  },
                  {
                    filterName: "categoryFilter",
                    filters: obj.category,
                  },
                  {
                    filterName: "productTagFilter",
                    filters: obj.productTags,
                  },
                  {
                    filterName: "vmnFilter",
                    filters: obj.productVendorModel,
                  },
                  {
                    filterName: "skuFilter",
                    filters: obj.productSKU,
                  },
                ],
              });
            }, 900);
          }
          setTimeout(() => {
            this.setProductGroups({ type: "update", data: _productGroups });
          }, 700);
        }
      }
      if (this.mode == "edit") {
        this.showForm = false;
        this.showLastModifiedProducts = true;
        this.showFormPreview = true;
      }
    }
  },
  beforeDestroy() {
    this.resetForm();
    this.pricingSetItem({ resource: "pricingManagerId", payload: 0 });
    this.pricingSetItem({ resource: "mode", payload: "" });
    this.pricingSetItem({ resource: "preFilledData", payload: null });
  },
  validations: {
    formData: {
      name: { required },
      siteId: { required },
      priceStartDate: {
        required,
        minValue(value) {
          let sdate = moment(value);
          sdate.set("hour", 0);
          sdate.set("minute", 0);
          sdate.set("second", 0);
          sdate.set("millisecond", 0);

          let edate = "";

          if (!isEmpty(this.formData.priceEndDate)) {
            edate = moment(this.formData.priceEndDate);
            edate.set("hour", 0);
            edate.set("minute", 0);
            edate.set("second", 0);
            edate.set("millisecond", 0);

            if (
              moment(edate)._i == "2099-12-31T00:00:00" ||
              moment(edate)._i == "2099-12-31T00:00:00Z" ||
              moment(edate)._i == "2099-12-31T00:00:00.000Z"
            ) {
              edate = "";
            }
          }

          let status = this.checkStartDateValidation(sdate, edate, this.mode);

          return status;
        },
      },
      priceEndDate: {
        minValue(value) {
          let sdate = moment(this.formData.priceStartDate);
          sdate.set("hour", 0);
          sdate.set("minute", 0);
          sdate.set("second", 0);
          sdate.set("millisecond", 0);

          let edate = moment(value);
          edate.set("hour", 0);
          edate.set("minute", 0);
          edate.set("second", 0);
          edate.set("millisecond", 0);

          if (
            moment(edate)._i == "2099-12-31T00:00:00" ||
            moment(edate)._i == "2099-12-31T00:00:00Z" ||
            moment(edate)._i == "2099-12-31T00:00:00.000Z"
          ) {
            edate = "";
          }

          let status = this.checkEndDateValidation(sdate, edate);

          return status;
        },
      },
    },
    CSVFileData: {
      csvFile: {
        required: requiredIf(function () {
          return this.tabIndex == 1;
        }),
        fileValidation(value) {
          if (value != null) {
            // let allowedExtensions = ['xlsx','xls','csv'];
            let allowedExtensions = ["csv"];
            let fileExtension = value.name
              .substring(value.name.lastIndexOf(".") + 1)
              .toLowerCase();
            if (allowedExtensions.indexOf(fileExtension) <= -1) {
              return false;
            }
          }
          return true;
        },
      },
    },
    productGroups: {
      isInvalidProductGroup,
    },
  },
};
</script>
