import Vue from "vue";

import i18n from "@/plugins/i18n";

import LayoutDefault from "@/layouts/LayoutDefault";

import VueRouter from "vue-router";

import pricingRoute from "@/modules/pricing-manager/router";

import tagRoute from "@/modules/tag-manager/router";

import authRoute from "@/modules/auth/router";

import auth from "./middleware/auth";

import isAdmin from "./middleware/isAdmin";

// import checkUserPermission from "./middleware/checkUserPermission";

import maintenance from "./middleware/maintenance";

import middlewarePipeline from "./middlewarePipeline";

import store from "../store";

/**
 * Admin Modules routes and layout
 */
import AdminLayout from "@/layouts/AdminLayout";
import userRoutes from "@/modules/admin/user-manager/router";
import sitesRoute from "@/modules/admin/site-manager/router";
import settingRoute from "@/modules/admin/settings/router";
import siteVendorCategoryRoute from "@/modules/admin/site-vendor-categories/router";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: LayoutDefault,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue"),
        meta: {
          middleware: [maintenance, auth],
        },
      },
      pricingRoute,
      tagRoute,
      {
        path: "/help",
        name: "help",
        component: () => import("@/views/Help.vue"),
        meta: {
          middleware: [maintenance, auth],
        },
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/Profile.vue"),
        meta: {
          label: "Profile",
          controlLink: "profile",
          module: "setting",
          moduleMenuId: "setting",
          moduleLabel: "Setting",
          languageSlug: "profile",
          moduleLanguageSlug: "setting",
          middleware: [maintenance, auth],
        },
      },
      {
        path: "/version-history",
        name: "version-history",
        component: () => import("@/views/VersionHistory.vue"),
        meta: {
          label: "Version History",
          controlLink: "version-history",
          module: "setting",
          moduleMenuId: "setting",
          moduleLabel: "Setting",
          languageSlug: "version-history",
          moduleLanguageSlug: "setting",
          middleware: [maintenance, auth],
        },
      },
      {
        path: "/permission-denied",
        name: "permission-denied",
        component: () => import("@/views/PermissionDenied.vue"),
        meta: {
          moduleLabel: "Home",
          label: "Permission Denied",
          middleware: [maintenance, auth],
        },
      },
    ],
    meta: {
      middleware: [maintenance, auth],
    },
  },
  authRoute,
  {
    path: "/maintenance",
    name: "maintenance",
    component: () => import("@/views/Maintenance.vue"),
  },
  {
    path: "/admin",
    component: AdminLayout,
    children: [
      {
        path: "/",
        name: "admin-home",
        // redirect: "/admin/users",
        component: () => import("@/views/AdminHome.vue"),
        meta: {
          middleware: [maintenance, auth, isAdmin],
        },
      },
      userRoutes,
      sitesRoute,
      settingRoute,
      siteVendorCategoryRoute,
    ],
    meta: {
      middleware: [maintenance, auth],
    },
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("@/views/PageNotFound.vue"),
    meta: {
      middleware: [maintenance, auth],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name != "login") {
    let local = store.getters["selected_language"] || "en";
    let translations = store.getters["translations"];

    i18n.locale = local;
    i18n.setLocaleMessage(local, translations[local]);
  }

  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
