// const AdminLayout = () => import('@/layouts/AdminLayout');
const Index = () => import("./index");
const UserList = () => import("./components/Listing");
const Create = () => import("./components/Create");
const Edit = () => import("./components/Edit");
const Permission = () => import("./components/Permission");

import auth from "@/router/middleware/auth";
import maintenance from "@/router/middleware/maintenance";
import isAdmin from "@/router/middleware/isAdmin";

const usersRoute = {
  path: "users",
  meta: { label: "Users" },
  component: Index,
  children: [
    {
      path: "",
      name: "users-list",
      meta: {
        label: "All Users",
        controlLink: "users-list",
        module: "users",
        moduleMenuId: "userMenu",
        moduleLabel: "User Manager",
        middleware: [maintenance, auth, isAdmin],
      },
      component: UserList,
    },
    {
      path: ":id/edit",
      name: "edit-users",
      meta: {
        label: "All Users",
        controlLink: "editUserLink",
        module: "users",
        moduleMenuId: "userMenu",
        moduleLabel: "User Manager",
        middleware: [maintenance, auth, isAdmin],
      },
      component: Edit,
    },
    {
      path: "create",
      name: "create-users",
      meta: {
        label: "Add New User",
        controlLink: "create-users",
        module: "users",
        moduleMenuId: "userMenu",
        moduleLabel: "User Manager",
        middleware: [maintenance, auth, isAdmin],
      },
      component: Create,
    },
    {
      path: "permission",
      name: "user-permissions",
      meta: {
        label: "Permission",
        controlLink: "users-permission",
        module: "users",
        moduleMenuId: "userMenu",
        moduleLabel: "User Manager",
        middleware: [maintenance, auth, isAdmin],
      },
      component: Permission,
    },
  ],
};

export default usersRoute;
