const state = {
  gridList: [],
  sortBy: "CreatedOn",
  sortOrder: "DESC",
  user: null,
  store: {},
  searchQuery: "",
  tblLoader: false,
  formLoader: false,
  isLoading: false,
};

export default state;
