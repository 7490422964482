const Index = () => import("./index");
const VendorCategoriesList = () => import("./components/VendorCategoriesList");

import auth from "@/router/middleware/auth";
// import maintenance from "@/router/middleware/checkMaintenanceMode";
import isAdmin from "@/router/middleware/isAdmin";

const siteVendorCategoryRoute = {
  path: "",
  meta: { label: "Site Vendor Categories" },
  component: Index,
  children: [
    {
      path: "site-vendor-categories",
      name: "site-vendor-categories",
      meta: {
        label: "Vendor Categories List",
        controlLink: "site-vendor-categories",
        module: "siteVendorCategories",
        moduleMenuId: "siteVendorCategoriesMenu",
        moduleLabel: "Site Vendor Categories",
        middleware: [auth, isAdmin],
      },
      component: VendorCategoriesList,
    },
  ],
};

export default siteVendorCategoryRoute;
