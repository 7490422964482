const getters = {
  isAuthenticated: (state) => !!state.user,
  accessToken: (state) => state.accessToken,
  siteAccess: (state) => state.siteAccess,
  user: (state) => state.user,
  userModulerPermission: (state) => state.userModulerPermission,
  defaultLandingRoute: (state) => state.defaultLandingRoute,
};

export default getters;
