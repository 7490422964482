<template>
  <create-form :showFirstStep="showFirstStep" />
</template>

<script>
import CreateForm from "./Form.vue";
export default {
  name: "CreateSite",
  components: {
    CreateForm,
  },
  data() {
    return {
      showFirstStep: true,
    };
  },
};
</script>
