<template>
  <pricing-import
    class="promotional-pricing-import"
    formName="promotional-pricing-import"
    moduleSlug="promotional-pricing-import"
    controllerName="PromotionalPricingImport.vue"
    moduleName="Promotional Pricing Import"
    priceTypeId="2"
  ></pricing-import>
</template>

<script>
import PricingImport from "./PricingImport.vue";
export default {
  name: "PromotionalPricingImport",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Promotional Pricing Import",
  },
  components: {
    PricingImport,
  },
};
</script>
