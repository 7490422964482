<template>
  <div>
    <portal to="breadcrumb">
      <div class="d-flex align-items-center justify-content-sm-between">
        <div class="versionHistory-list-breadcrumb">
          <bread-crumb
            :activeModule="$route.meta.moduleLabel"
            :activePage="$route.meta.label"
            :activePageUrl="$route.path"
          />
        </div>
        <b-button
          :to="{ name: 'version-history-create' }"
          variant="brand-primary"
          >Create
        </b-button>
      </div>
    </portal>
    <b-card class="mt-3 admin-grid">
      <div class="row d-md-flex align-items-center mb-0 mb-md-3">
        <div
          class="col-xl-4 col-md-6 col-sm-12 pl-xxl-0 pr-md-0 mb-2 mb-md-2 mb-xl-0"
        >
          <input
            type="search"
            v-model="searchText"
            placeholder="Search"
            class="nosubmit w-100"
          />
        </div>
      </div>
      <b-table
        hover
        responsive
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        tbody-tr-class="text-nowrap"
        ref="versionHistoryTable"
        show-empty
        :empty-text="$t('general.no-data-msg')"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :filter-function="filterTable"
        v-model="displayedRows"
      >
        <template #cell(deploymentDate)="data">
          {{ data.item.deploymentDate | formatedHistoryDate }}
        </template>
        <template #cell(features)="data">
          <i
            :id="'features-tootltip' + data.item.id"
            class="icon-tooltip ml-1 mt-1"
            role="button"
          ></i>
          <b-tooltip :target="'features-tootltip' + data.item.id"
            ><span v-html="data.item.features"></span
          ></b-tooltip>
        </template>
        <template #cell(historyAction)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none menu-link position-relative"
            no-caret
            boundary="window"
          >
            <template #button-content>
              <b-img
                src="/media/icons/menu-black.svg"
                class="me-2"
                alt="Menu"
                role="button"
              />
            </template>
            <b-dropdown-item @click="performEditClone({ data: data.item })"
              >Edit</b-dropdown-item
            >
          </b-dropdown>
        </template>
        <template #cell(language)="data">
          {{ data.item.languageId }}
        </template>
      </b-table>
      <table-pagination
        class="mb-3"
        :fromLimit="fromLimit | addLeadingZero"
        :toLimit="toLimit | addLeadingZero"
        :totalRecords="totalRecords | addLeadingZero"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @paginate="paginate"
        recordsTitle="records"
      />
    </b-card>
  </div>
</template>

<script>
import config from "../../../../../config";
import { ceil } from "lodash";
import TablePagination from "../../../../components/TablePagination.vue";
import BreadCrumb from "../../../../components/BreadCrumb.vue";
const { tableHeaders } = config;
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "VersionList",
  components: { TablePagination, BreadCrumb },
  data() {
    return {
      currentPage: 1,
      totalRecords: 0,
      perPage: 20,
      totalPages: 0,
      fields: tableHeaders["version-history-list"],
      searchText: "",
      displayedRows: [],
      filterOn: [],
      filter: {
        searchText: "",
      },
    };
  },
  computed: {
    ...mapGetters("adminSetting", ["versionHistory"]),
    ...mapGetters(["languages", "selected_language"]),
    selectedLangId() {
      let selectedLang = this.languages.find(
        (item) => item.languageCode == this.selected_language
      );
      return selectedLang.languageId;
    },
    items() {
      return this.versionHistory;
    },
    fromLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.fromlimit;
    },
    toLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.tolimit;
    },
  },
  methods: {
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    ...mapMutations("adminSetting", ["setItem"]),
    ...mapActions("adminSetting", ["getVHistoryData"]),
    async getData() {
      this.generalSetItem({ resource: "isLoading", payload: true });
      await this.getVHistoryData({ userId: this.loggedUserId });
      this.generalSetItem({ resource: "isLoading", payload: false });
    },
    filterTable(row, filter) {
      let filterCondition = {};

      if (this.filterOn.length) {
        this.filterOn.forEach((item) => {
          if (filter[item].length) {
            filterCondition[item] = true;
            if (item == "searchText") {
              let projectTitleMatched = row["projectTitle"]
                .toLowerCase()
                .includes(filter[item].toLowerCase());
              let versionMatched = row["version"].includes(`${filter[item]}`);
              filterCondition[item] = projectTitleMatched || versionMatched;
            }
          }
        });
      }
      const areTrue = Object.values(filterCondition).every(
        (value) => value === true
      );
      return areTrue;
    },
    onFiltered(filteredItems) {
      this.currentPage = 1;
      this.totalRecords = filteredItems.length;
      this.totalPages = ceil(filteredItems.length / this.perPage);
    },
    paginate(eventType) {
      if (eventType == "next") {
        this.currentPage = this.currentPage + 1;
      } else {
        this.currentPage = this.currentPage - 1;
      }
    },
    performEditClone(item) {
      const { data } = item;
      this.generalSetItem({ resource: "isLoading", payload: true });
      this.setItem({ resource: "preFilledData", payload: data });

      // this.$router.push({
      //   name: "version-history-edit",
      // });

      this.generalSetItem({ resource: "isLoading", payload: false });
      this.$router.push({
        name: "version-history-edit",
        params: { id: data.id, languageId: this.selectedLangId },
      });
    },
  },
  watch: {
    searchText(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("searchText");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("searchText");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["searchText"] = newValue;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
