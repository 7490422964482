<template>
  <div class="version-history">
    <div
      class="row"
      :class="{ 'mt-3': index > 0 }"
      v-for="(year_data, index) in historyData"
      :key="'year_' + year_data.year"
    >
      <div
        class="col-md-12"
        :class="{ 'mt-3': index > 0 }"
        v-for="(month_data, index) in year_data.data"
        :key="year_data.year + '_' + month_data.month"
      >
        <div class="text-center">
          <div
            class="bg-brand-dark d-inline-block align-top text-white font-weight-bold rounded mb-3 px-5 py-2"
          >
            {{ deploymentMonth(month_data.month) }}, {{ year_data.year }}
          </div>
        </div>

        <div class="main-timeline">
          <div class="timeline-bullets">
            <div
              class="timeline"
              v-for="deployment in month_data.data"
              :key="deployment.id"
            >
              <div class="timeline-data align-items-start">
                <div class="timeline-year">
                  <p class="timeline-date font-weight-bold mb-0">
                    {{ deploymentDate(deployment.deploymentDate) }}
                  </p>
                  <p class="timeline-version">{{ deployment.version }}</p>
                </div>
              </div>
              <div class="timeline-icon">
                <i
                  :class="deployment.icon ? deployment.icon : 'icon-globe'"
                ></i>
              </div>
              <div class="timeline-content">
                <div class="timeline-title h6 mb-0">
                  {{ deployment.projectTitle }}
                </div>
                <p class="timeline-features brand-info mb-0">
                  {{ $t("general.list-of-feature") }}
                  <span
                    ><i
                      class="icon-tooltip ml-1"
                      :id="'tootltip_' + deployment.id"
                    ></i
                  ></span>
                </p>
                <b-tooltip :target="'tootltip_' + deployment.id"
                  ><p v-html="deployment.features"
                /></b-tooltip>
                <div class="timeline-description brand-text-gray">
                  {{ deployment.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import moment from "moment";
import { orderBy, chain, toNumber, capitalize } from "lodash";
export default {
  name: "VesrionHistory",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Version History",
  },
  data() {
    return {
      historyData: [],
    };
  },
  computed: {
    ...mapGetters(["languages", "selected_language", "versionHistory"]),
    // ...mapGetters("auth", ["user"]),
    isNotificationActive() {
      return this.user.activeNotification;
    },
    selectedLangId() {
      let selectedLang = this.languages.find(
        (item) => item.languageCode == this.selected_language
      );
      return selectedLang.languageId;
    },
  },
  methods: {
    ...mapMutations(["setItem"]),
    ...mapActions(["getVersionHistory"]),
    ...mapActions("auth", ["updateNotificationSeen"]),
    async getData() {
      await this.getVersionHistory(this.selectedLangId);

      // const response = await this.getVersionHistory();
      // this.historyData = response;
    },
    deploymentDate(value) {
      return capitalize(
        moment(value).locale(this.selected_language).format("MMM DD")
      );
    },
    deploymentMonth(value) {
      let month = parseInt(value) + 1;
      return capitalize(
        moment(month, "MM").locale(this.selected_language).format("MMMM")
      );
    },
  },
  mounted() {
    this.setItem({ resource: "versionHistory", payload: [] });
    if (this.user.activeNotification == 1) {
      this.updateNotificationSeen({ userId: this.loggedUserId });
    }
    this.getData();
  },
  watch: {
    versionHistory(newValue) {
      if (newValue.length == 0) return;

      this.historyData = orderBy(
        chain(newValue)
          // Group the elements of Array based on `color` property
          .groupBy("year")
          // `key` is group's name (color), `value` is the array of objects
          .map((value, key) => ({
            year: key,
            data: chain(value)
              .groupBy("month")
              .map((value, key) => ({ month: key, data: value }))
              .value(),
          }))
          .value(),
        ["year"],
        ["desc"]
      ).map((el) => {
        let sorted_data = orderBy(el.data, (i) => toNumber(i.month), "desc");
        return { ...el, data: sorted_data };
      });
    },
  },
};
</script>
