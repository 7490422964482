<template>
  <div class="pricing-history">
    <portal to="breadcrumb">
      <div
        class="users-filter d-flex align-items-center justify-content-sm-between"
      >
        <div class="user-list-breadcrumb">
          <bread-crumb
            :activeModule="$route.meta.moduleLabel"
            :activePage="$route.meta.label"
            :activePageUrl="$route.path"
          />
        </div>
        <div class="filter position-relative" v-clickoutside="closeFilter">
          <b-button variant="brand-secondary" @click="showFilter = !showFilter"
            ><i class="icon-filter"></i> Filter</b-button
          >
          <div
            class="filter-menu p-3 bg-white shadow-sm radius-25 position-absolute"
            v-if="showFilter"
            :class="filterStyle"
          >
            <b-form-group
              label-for="site-selection-form"
              label-class="form-label"
              :label="$t(`pricing-history.table_head_siteName`)"
              v-slot="{ ariaDescribedby }"
            >
              <b-dropdown
                id="site-selection-form"
                :ariaDescribedby="ariaDescribedby"
                :text="$t(`pricing-history.table_head_siteName`)"
                ref="siteFiltering"
                block
                no-caret
                lazy
                variant="brand-select"
                menu-class="w-100"
                @show="toggleSiteDropdownFilter('open')"
                @hide="toggleSiteDropdownFilter('close')"
              >
                <b-dropdown-form @submit.prevent>
                  <div class="form-group">
                    <b-form-input
                      type="search"
                      class="nosubmit"
                      :placeholder="
                        $t(
                          `pricing-history.table_head_siteName-search-placeholder`
                        )
                      "
                      v-model="searchSiteText"
                    />
                  </div>
                  <b-form-checkbox-group
                    class="scrollable"
                    id="sites"
                    v-model="selected"
                    :options="sitesOptions"
                    name="sites"
                  ></b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-form-group>
            <hr />
            <b-form-group
              label-for="pricingType"
              label-class="form-label"
              :label="$t(`pricing-history.table_head_pricingType`)"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                id="pricingType"
                :aria-describedby="ariaDescribedby"
                v-model="pricingType"
                :options="pricingTypeOption"
                name="pricingType"
              ></b-form-checkbox-group>
            </b-form-group>
            <hr />
            <b-form-group
              label-for="pricingStatus"
              label-class="form-label"
              :label="$t(`pricing-history.table_head_status`)"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                id="pricingStatus"
                :aria-describedby="ariaDescribedby"
                v-model="pricingStatus"
                :options="statuOption"
                name="pricingStatus"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </div>
    </portal>
    <b-card class="mt-3">
      <b-overlay :show="isLoading" rounded="sm">
        <product-preview-failed v-if="productPreviewFailed" />
        <div class="product-preview" v-else>
          <!-- <table-pagination
            :fromLimit="fromLimit | addLeadingZero"
            :toLimit="toLimit | addLeadingZero"
            :totalRecords="totalRecords | addLeadingZero"
            :showExportButton="false"
            :currentPage="currentPage"
            :totalPages="totalPages"
            :countInfo="'pricing-history.table_count-info'"
            @paginate="paginate"
            class="mb-3"
          /> -->

          <div class="row d-md-flex align-items-center mb-0 mb-md-3">
            <div
              class="col-xl-4 col-md-6 col-sm-12 pl-xxl-0 pr-md-0 mb-2 mb-md-2 mb-xl-0"
            >
              <input
                type="search"
                v-model="searchValue"
                :placeholder="$t(`pricing-history.table_search_placeholder`)"
                class="nosubmit w-100"
              />
            </div>
          </div>

          <b-table
            hover
            responsive
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            tbody-tr-class="text-nowrap"
            show-empty
            :empty-text="$t('general.no-data-msg')"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            :filter-debounce="300"
            :filter-function="filterTable"
            v-model="displayedRows"
          >
            <!--- Custom configuration for table headers -->
            <template #head(pricingHistoryName)>
              {{ $t(`pricing-history.table_head_pricingName`) }}
            </template>
            <template #head(pricingManagerId)>
              {{ $t(`pricing-history.table_head_pricingManagerId`) }}
            </template>
            <template #head(siteName)>
              {{ $t(`pricing-history.table_head_siteName`) }}
            </template>
            <template #head(pricingHistoryType)>
              {{ $t(`pricing-history.table_head_pricingType`) }}
            </template>
            <template #head(pricingHistoryStatus)>
              {{ $t(`pricing-history.table_head_status`) }}
            </template>
            <template #head(pricingHistoryLastEdit)>
              {{ $t(`pricing-history.last-time-edit`) }}
            </template>
            <template #head(historyAction)="data">
              {{ data.label }}
            </template>

            <template #cell(pricingHistoryName)="data">
              {{ data.item.name }}
            </template>

            <template #cell(pricingManagerId)="data">
              {{ data.item.id }}
            </template>

            <template #cell(siteName)="data">
              {{ data.item.siteName }}
            </template>

            <template #cell(pricingHistoryType)="data">
              {{ data.item.pricing }}
            </template>

            <template #cell(pricingHistoryStatus)="data">
              <span
                class="tag-status"
                :class="{
                  inactive: data.item.isActive == 0,
                  active: data.item.isActive == 1,
                  scheduled: data.item.isActive == 2,
                }"
                >{{ data.item.status }}</span
              >
            </template>

            <template #cell(pricingHistoryLastEdit)="data">
              {{ data.item.lastEditTime | formatedHistoryDate }}
            </template>

            <template #cell(historyAction)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none menu-link position-relative"
                no-caret
                boundary="window"
              >
                <template #button-content>
                  <b-img
                    src="/media/icons/menu-black.svg"
                    class="me-2"
                    alt="Menu"
                    role="button"
                  />
                </template>
                <b-dropdown-item
                  @click="performEditClone({ id: data.item.id, mode: 'edit' })"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  @click="performEditClone({ id: data.item.id, mode: 'clone' })"
                  >Clone</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="data.item.isActive == 1 || data.item.isActive == 2"
                  @click="disableEntry(data.item.id)"
                  >Disable</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-table>

          <table-pagination
            class="mt-3"
            :fromLimit="fromLimit | addLeadingZero"
            :toLimit="toLimit | addLeadingZero"
            :totalRecords="totalRecords | addLeadingZero"
            :currentPage="currentPage"
            :totalPages="totalPages"
            :countInfo="'pricing-history.table_count-info'"
            @paginate="paginate"
          />
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { forEach, isObject, ceil } from "lodash";
import config from "../../../../config";
import ProductPreviewFailed from "@/components/ProductPreviewFailed.vue";
import TablePagination from "@/components/TablePagination.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
const { pricingManagerConfig, mapRouteToForm, tableHeaders } = config;

export default {
  name: "History",
  metaInfo() {
    return {
      title: `${this.$t("general.pricing-history")} | ${this.$t(
        "general.pricing-manager"
      )}`,
    };
  },
  components: {
    ProductPreviewFailed,
    TablePagination,
    BreadCrumb,
  },
  data() {
    return {
      sitesOptions: [],
      currentPage: 1,
      totalRecords: 0,
      totalPages: 0,
      selected: [],
      showProductIcon: false,
      exportData: false,
      isLoading: false,
      search: "",
      orderBy: "lastEditTime",
      orderByDirection: "DESC",
      formName: "pricing-history",
      perPage: pricingManagerConfig.pricingHistoryPerPage,
      displayedRows: [],
      searchSiteText: "",
      showFilter: false,
      filterOn: [],
      filter: {
        searchValue: "",
        name: "",
        siteName: [],
        isActive: [],
        pricing: [],
      },
      searchValue: "",
      pricingName: "",
      pricingStatus: [],
      pricingType: [],
      statuOption: pricingManagerConfig.statuOption,
      pricingTypeOption: pricingManagerConfig.pricingTypeOption,
    };
  },
  computed: {
    ...mapGetters("auth", ["siteAccess"]),
    ...mapGetters("pricingManager", ["history"]),
    fields() {
      return tableHeaders[this.formName];
    },
    productPreviewFailed() {
      return this.history.length == 0 ? true : false;
    },
    productdata() {
      let data = this.history.map((item) => {
        let siteNames = item.siteName.split(",");
        return { ...item, siteNames: siteNames };
      });
      return data;
    },
    items() {
      return this.productdata;
    },
    fromLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.fromlimit;
    },
    toLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.tolimit;
    },
  },
  watch: {
    searchSiteText(newValue) {
      if (this.siteAccess.length > 0) {
        let newOptions = [];
        forEach(this.siteAccess, (site) => {
          if (
            site.Name.toLowerCase().includes(newValue.toLowerCase()) ||
            newValue == ""
          ) {
            let obj = {
              text: site.Name,
              value: { siteId: site.SiteId, siteName: site.Name },
            };
            newOptions.push(obj);
          }
        });
        this.sitesOptions = newOptions;
      }
    },
    selected(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("siteName");

      if (newValue.length) {
        if (index == -1) {
          _filterOn.push("siteName");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["siteName"] = newValue.length
        ? newValue.map((a) => a.siteName)
        : [];
    },
    productdata(newValue) {
      this.currentPage = 1;
      if (newValue.length) {
        this.totalRecords = newValue.length;
        this.totalPages = ceil(newValue.length / this.perPage);
      }
    },
    searchValue(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("searchValue");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("searchValue");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["searchValue"] = newValue;
    },
    pricingName(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("name");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("name");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["name"] = newValue;
    },
    pricingStatus(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("isActive");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("isActive");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;

      this.filter["isActive"] = newValue;
    },
    pricingType(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("pricing");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("pricing");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;

      this.filter["pricing"] = newValue;
    },
  },
  methods: {
    ...mapActions("pricingManager", [
      "getHistory",
      "updateStatus",
      "getPricingHistory",
    ]),
    ...mapMutations("pricingManager", ["setItem"]),
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    async getHistoryData() {
      let _siteIds = this.selected.map((_selected) => {
        return _selected.siteId;
      });
      // this.isLoading = true;
      this.generalSetItem({ resource: "isLoading", payload: true });
      this.setItem({ resource: "history", payload: [] });

      let filterObject = {
        siteIds: _siteIds.join(),
        userId: this.loggedUserId,
        orderBy: this.orderBy,
        orderByDirection: this.orderByDirection,
      };

      if (this.search) {
        filterObject = { ...filterObject, searchText: this.search };
      }

      this.getHistory(filterObject)
        .then(() => {
          // this.isLoading = false;
          this.generalSetItem({ resource: "isLoading", payload: false });
        })
        .catch(() => {
          // this.isLoading = false;
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    async disableEntry(id) {
      await this.updateStatus({
        pricingManagerId: id,
        userId: this.loggedUserId,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: "PricingHistory.vue",
          moduleName: "Pricing History",
        },
      });
      this.getHistoryData();
    },
    async performEditClone(params) {
      let { id, mode } = params;

      this.generalSetItem({ resource: "isLoading", payload: true });
      this.getPricingHistory({
        id: id,
        userId: this.loggedUserId,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: "PricingHistory.vue",
          moduleName: "Pricing History",
        },
      })
        .then((response) => {
          // console.log("response ::", response);
          // return;

          if (response.status == 1) {
            let _data = response.data;
            let routeName = "";

            if (isObject(mapRouteToForm[_data.PriceTypeId])) {
              switch (_data.DiscountType) {
                case 2:
                  routeName = mapRouteToForm[_data.PriceTypeId][2];
                  break;
                case 3:
                  routeName = mapRouteToForm[_data.PriceTypeId][3];
                  break;
                default:
                  routeName = mapRouteToForm[_data.PriceTypeId][1];
                  break;
              }
            } else {
              routeName = mapRouteToForm[_data.PriceTypeId];
            }

            this.setItem({ resource: "mode", payload: mode });
            this.setItem({ resource: "preFilledData", payload: _data });

            this.$router.push({
              name: routeName,
            });
          } else {
            this.$bvToast.toast(response.error, {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          }
          this.generalSetItem({ resource: "isLoading", payload: false });
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    paginate(eventType) {
      if (eventType == "next") {
        this.currentPage = this.currentPage + 1;
      } else {
        this.currentPage = this.currentPage - 1;
      }
    },
    filterTable(row, filter) {
      if (filter.siteName.length == 0) return false;
      let filterCondition = {};

      if (this.filterOn.length) {
        this.filterOn.forEach((item) => {
          if (filter[item].length) {
            filterCondition[item] = true;
            if (item == "siteName") {
              let existingSites = {};
              row.siteNames.forEach((site) => {
                existingSites[site] = false;
                if (filter[item].includes(site)) existingSites[site] = true;
              });
              const areTrue = Object.values(existingSites).some(
                (value) => value === true
              );
              filterCondition[item] = areTrue;
            }
            // else if (item == "name") {
            //   if (
            //     !row[item].toLowerCase().includes(filter[item].toLowerCase())
            //   ) {
            //     filterCondition[item] = false;
            //   }
            // }
            else if (item == "searchValue") {
              let idMatched = filter[item].includes(row["id"]);
              let titleMatched = row["name"]
                .toLowerCase()
                .includes(filter[item].toLowerCase());

              filterCondition[item] = idMatched || titleMatched;
            } else if (item == "pricing" || item == "isActive") {
              if (!filter[item].includes(row[item])) {
                filterCondition[item] = false;
              }
            }
          }
        });
      }

      const areTrue = Object.values(filterCondition).every(
        (value) => value === true
      );
      return areTrue;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
      this.totalRecords = filteredItems.length;
      this.totalPages = ceil(filteredItems.length / this.perPage);
    },
    closeFilter() {
      this.showFilter = false;
      this.toggleSiteDropdownFilter("close");
    },
  },
  beforeMount() {
    this.setItem({ resource: "history", payload: [] });
    let options = [];
    forEach(this.siteAccess, (site) => {
      let obj = {
        text: site.Name,
        value: { siteId: site.SiteId, siteName: site.Name },
      };
      options.push(obj);
    });
    this.sitesOptions = options;
    this.selected = [];
    this.searchSiteText = "";

    this.searchValue = "";
    this.pricingName = "";
    this.pricingStatus = [];
    this.pricingType = [];
    this.filterOn = [];
    this.filter = {
      searchValue: "",
      name: "",
      siteName: [],
      isActive: [],
      pricing: [],
    };
  },
  mounted() {
    forEach(this.sitesOptions, (option) => {
      this.selected.push(option.value);
    });
    // this.getHistoryData();
    this.getHistoryData().then(() => {
      let routeParams = this.$route.params;
      if (Object.keys(routeParams).length) {
        setTimeout(() => {
          let filteredSite = this.sitesOptions.find(
            (item) => item.value.siteId == routeParams.selectedSite
          );
          let selected = [{ ...filteredSite.value }];
          this.selected = selected;

          this.pricingType.push(routeParams.pricingType);

          if (routeParams.pricingStatus != "") {
            this.pricingStatus.push(Number(routeParams.pricingStatus));
          }
        }, 3500);
      }
    });
  },
  beforeDestroy() {
    this.setItem({ resource: "history", payload: [] });
  },
};
</script>
