import ajax from "@/customApi";
const qs = require("qs");
import config from "../../../../config";
import { eventBus } from "@/main";
const { path, messages } = config;

const service = {
  getAll,
  manageUser,
  getById,
  deleteById,
  updateUserTypes,
  updateStatus,
  userStoreMapping,
  getModuleMasterList,
  getUsermodulerPermissionById,
  userModuleMapping,
};

async function getAll(params = {}) {
  let { userId } = params;

  delete params.userId;
  try {
    const { data } = await ajax.get(path.getDetailUsersList, {
      name: "users",
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "users",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "Listing.vue",
        actionName: "getAll",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getDetailUsersList}`,
        moduleName: "Listing",
        isApi: 0,
      },
    });
    return [];
  }
}

async function getById(params) {
  try {
    const { data } = await ajax.get(path.getDetailUsersList, {
      name: "user edit",
      params: { userId: params.id },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return {};
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "user edit",
      body: {
        id: 0,
        userId: params.userId,
        exceptionMessage: error.response.data,
        controllerName: "Form.vue",
        actionName: "getById",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getDetailUsersList}`,
        moduleName: "Form",
        isApi: 0,
      },
    });
    return {};
  }
}

async function manageUser(user) {
  let { form, userId } = user;
  delete user.userId;
  try {
    var { data } = await ajax.post(path.manageUsers, {
      name: "users",
      body: form,
    });

    if (data[0] == "DuplicateEmail") {
      return {
        status: 0,
        data: user,
        type: "DuplicateEmail",
        error: messages.duplicateEmail,
      };
    } else {
      return { status: 1, data: null };
    }
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return { status: 0, user: form, type: "other", error: error };
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "users",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "Form.vue",
        actionName: "manageUser",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.manageUsers}`,
        moduleName: "Form",
        isApi: 0,
      },
    });
    return { status: 0, user: form, type: "other", error: error };
  }
}

async function deleteById(id) {
  try {
    const { data } = await ajax.get(`user/${id}/delete`, {
      name: "user delete",
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
    }
    return {};
  }
}

async function updateUserTypes(params) {
  let { userId } = params;

  delete params.userId;
  try {
    // let _params = qs.stringify(params)

    const { data } = await ajax.post(path.updateUserTypes, {
      body: null,
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return 0;
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "updateUserTypes",
      body: {
        id: 0,
        userId: userId,
        exceptionMessage: error.response.data,
        controllerName: "List.vue",
        actionName: "updateUserTypes",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.updateUserTypes}`,
        moduleName: "List",
        isApi: 0,
      },
    });
    return 0;
  }
}

async function updateStatus(params) {
  let { loginId } = params;

  try {
    const { data } = await ajax.post(path.updateUserStatus, {
      body: null,
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return 0;
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "updateStatus",
      body: {
        id: 0,
        userId: loginId,
        exceptionMessage: error.response.data,
        controllerName: "List.vue",
        actionName: "updateStatus",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.updateUserStatus}`,
        moduleName: "List",
        isApi: 0,
      },
    });
    return 0;
  }
}

async function userStoreMapping(params) {
  let { loginId } = params;

  try {
    const { data } = await ajax.post(path.userStoreMapping, {
      body: params,
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return 0;
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "userStoreMapping",
      body: {
        id: 0,
        userId: loginId,
        exceptionMessage: error.response.data,
        controllerName: "List.vue",
        actionName: "userStoreMapping",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.userStoreMapping}`,
        moduleName: "List",
        isApi: 0,
      },
    });
    return 0;
  }
}

async function getModuleMasterList(params) {
  let { loginId } = params;

  try {
    const { data } = await ajax.get(path.getmodulemasterlist, {
      name: "module master list",
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "module master list",
      body: {
        id: 0,
        userId: loginId,
        exceptionMessage: error.response.data,
        controllerName: "Permission.vue",
        actionName: "getModuleMasterList",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getmodulemasterlist}`,
        moduleName: "Permission",
        isApi: 0,
      },
    });
    return [];
  }
}

async function getUsermodulerPermissionById(params) {
  let { loginId } = params;
  try {
    const { data } = await ajax.get(path.getUsermodulerPermissionById, {
      params: params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
    }
    eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "getUsermodulerPermissionById",
      body: {
        id: 0,
        userId: loginId,
        exceptionMessage: error.response.data,
        controllerName: "Permission.vue",
        actionName: "getUsermodulerPermissionById",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getUsermodulerPermissionById}`,
        moduleName: "Permission",
        isApi: 0,
      },
    });
    return [];
  }
}

async function userModuleMapping(params) {
  let { loginUserId } = params;

  try {
    await ajax.post(path.userModuleMapping, {
      body: params,
    });
    return 1;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return 0;
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "userModuleMapping",
      body: {
        id: 0,
        userId: loginUserId,
        exceptionMessage: error.response.data,
        controllerName: "Permission.vue",
        actionName: "userModuleMapping",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.userModuleMapping}`,
        moduleName: "Permission",
        isApi: 0,
      },
    });
    return 0;
  }
}

export default service;
