const mutations = {
  setItem(state, { resource, payload, debug = false }) {
    state[resource] = payload;
    if (debug) {
      console.log("setItem debug", resource, payload, state[resource]);
    }
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },
  setRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  setSiteAccess(state, sites) {
    state.siteAccess = sites;
  },
  setUser(state, user) {
    state.user = user;
  },
  LogOut(state) {
    state.user = null;
    state.accessToken = null;
    state.refreshToken = null;
    state.siteAccess = null;
    state.userModulerPermission = null;
    localStorage.removeItem("lastLoginAt");
  },
  updateNotificationSeen(state) {
    state.user.activeNotification = 0;
  },
};

export default mutations;
