import { mapActions, mapGetters } from "vuex";
import config from "../../config";
import moment from "moment";
import { ceil, isEmpty, map, find, isEqual, cloneDeep } from "lodash";

const { tooltip, messages, userType } = config;

const mixins = {
  name: "mixins",
  data() {
    return {
      sytemMessages: messages,
      tooltipMessages: tooltip,
      tdgUser: userType.tdgUser,
      drsgUser: userType.drsgUser,
      adminUser: userType.adminUser,
      filterStyle: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(["translations", "selected_language"]),
    isAdminUser() {
      return this.user && this.user.userType == this.adminUser;
    },
    loggedUserId() {
      return this.user.id;
    },
    yesterday() {
      var date = new Date();

      date; //# => current date

      date.setDate(date.getDate() - 1);

      return date; //# => yesterday
    },
  },
  methods: {
    ...mapActions("auth", { logoutUser: "LogOut" }),
    ...mapActions(["resetSavedLanguage"]),
    logout() {
      this.logoutUser()
        .then(() => {
          this.resetSavedLanguage();
          this.$router.push("/login");
        })
        .catch(() => {});
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
        return false;
      } else {
        return true;
      }
    },
    allowOnlyNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
        return false;
      } else {
        return true;
      }
    },
    allowOnlyAlphaNumeric(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z0-9]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    allowOnlyAlphabetic(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    allowOnlyAlphabeticWithSpace: function (e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z ]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    getPaginationResultLimit: (currentPage, perPage, totalDocs) => {
      const pgs = ceil(totalDocs / perPage);

      const nextlimit =
        parseInt(currentPage) * parseInt(perPage) - parseInt(perPage);

      let fromlimit = 0;

      let tolimit = 0;

      if (pgs == 1) {
        fromlimit = 1;
      } else {
        fromlimit = parseInt(nextlimit) + parseInt(1); //+1 to offset 0 count
      }

      if (pgs == currentPage) {
        tolimit = totalDocs;
      } else {
        tolimit = parseInt(nextlimit) + parseInt(perPage);
      }

      return { fromlimit: fromlimit, tolimit: tolimit };
    },
    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";
      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }
        str += line + "\r\n";
      }
      return str;
    },
    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + ".csv" || "export.csv";

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    toggleSiteDropdownFilter(evertType) {
      if (evertType == "open") {
        this.filterStyle = ["filter-auto-height"];
      } else {
        this.filterStyle = [];
      }
    },
    checkStartDateValidation(sdate, edate, mode) {
      let status = false;
      let today = moment();
      today.set("hour", 0);
      today.set("minute", 0);
      today.set("second", 0);
      today.set("millisecond", 0);

      switch (true) {
        case mode != "edit" && Date.parse(sdate) < Date.parse(today): //mode is clone and today's date is smaller then today's date
          status = false; //validation fail
          break;
        case Date.parse(sdate) < Date.parse(today) && isEmpty(edate): //start-date is smaller then today's date and end-date is empty.
          status = true; //validation pass
          break;
        case Date.parse(sdate) < Date.parse(today) &&
          isEmpty(edate) == false &&
          Date.parse(edate) >= Date.parse(today): //start-date is smaller then today and end-date is bigger or equal to today's date.
          status = true; //validation pass
          break;
        case Date.parse(sdate) < Date.parse(today) &&
          isEmpty(edate) == false &&
          Date.parse(edate) < Date.parse(today): //start-date is smaller then today's date and end-date is not empty.
          status = false; //validation fail
          break;
        default:
          status = true;
      }

      return status;
    },
    checkEndDateValidation(sdate, edate) {
      let status = false;

      let today = moment();
      today.set("hour", 0);
      today.set("minute", 0);
      today.set("second", 0);
      today.set("millisecond", 0);

      switch (true) {
        case isEmpty(edate): //end-date is empty(it is 2099-12-31:00:00:00)
          status = true; //validation pass
          break;
        case isEmpty(edate) == false && Date.parse(edate) < Date.parse(today): //start-date is smaller then today's date and end-date is also smaller then today's date.
          status = false; //validation fail
          break;
        case isEmpty(edate) == false &&
          Date.parse(edate) >= Date.parse(sdate) &&
          Date.parse(edate) >= Date.parse(today): //end-date is bigger or equal to today's date.
          status = true; //validation pass
          break;
        case isEmpty(edate) == false &&
          Date.parse(sdate) > Date.parse(edate) &&
          Date.parse(edate) >= Date.parse(today): //end-date is smaller then start-date.
          status = false; //validation fail
          break;
        default:
          status = true;
      }

      return status;
    },
    validateDuplicateCSVData(data) {
      const result = map(data, (o, i) => {
        let eq = find(data, (e, ind) => {
          if (i > ind) {
            let _e;
            let _o;
            if ("sku" in e && "sku" in o) {
              _e = {
                VendorModelNumber: e.VendorModelNumber,
                siteId: e.siteId,
                sku: e.sku ? e.sku : "",
              };
              _o = {
                VendorModelNumber: o.VendorModelNumber,
                siteId: o.siteId,
                sku: o.sku ? o.sku : "",
              };
            } else if ("productId" in e && "productId" in o) {
              _e = {
                VendorModelNumber: e.VendorModelNumber,
                siteId: e.siteId,
                productId: e.productId ? e.productId : "",
              };
              _o = {
                VendorModelNumber: o.VendorModelNumber,
                siteId: o.siteId,
                productId: o.productId ? o.productId : "",
              };
            } else {
              _e = cloneDeep(e);
              _o = cloneDeep(o);
            }

            return isEqual(_e, _o);
          }
        });
        if (eq) {
          o.isDuplicate = true;
          return o;
        } else {
          return o;
        }
      });

      let _result = find(result, { isDuplicate: true });
      if (_result) return false;
      else return true;
    },
  },
  filters: {
    formatedDate: function (value) {
      if (!value) return "N/A";
      return moment(value).format("DD/MM/YYYY");
    },
    addLeadingZero: function (value) {
      return value < 10 ? "0" + value.toString() : value.toString();
    },
    formatedHistoryDate: function (value) {
      return moment(value).format("lll");
    },
    truncate: function (text, length, clamp) {
      clamp = clamp || "...";
      var node = document.createElement("div");
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length
        ? content.slice(0, length) + clamp
        : content;
    },
  },
};

export default mixins;
