<template>
  <div class="error-message d-flex flex-row align-items-center">
    <i class="icon-error mr-1"></i>
    <p class="error-msg m-0">{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: ["message"],
};
</script>
