<template>
  <div class="product-preview">
    <div class="d-flex flex-row-reverse mb-3">
      <b-link
        class="export-csv my-md-0 my-2"
        v-if="exportData"
        :disabled="totalRecords == '00'"
        @click="download"
        >{{ $t(`general.export-csv`) }}
        <i class="icon-download ml-1"></i>
      </b-link>
    </div>
    <b-table
      hover
      responsive
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      show-empty
      :empty-text="$t('general.no-data-msg')"
      :tbody-tr-class="rowClass"
    >
      <template #head(products)>
        {{ $t(`${moduleSlug}.table_head_products`) }}
      </template>
      <template #head(pricingManagerId)>
        {{ $t(`${moduleSlug}.table_head_pricingManagerId`) }}
      </template>
      <template #head(productTagManagerId)>
        {{ $t(`${moduleSlug}.table_head_tagManagerId`) }}
      </template>
      <template #head(siteName)>
        {{ $t(`${moduleSlug}.table_head_siteName`) }}
      </template>
      <template #head(sku)>
        {{ $t(`${moduleSlug}.table_head_sku`) }}
      </template>
      <template #head(vmn)>
        {{ $t(`${moduleSlug}.table_head_vmn`) }}
      </template>
      <template #head(everydayCost)>
        {{ $t(`${moduleSlug}.table_head_everydayCost`) }}
      </template>
      <template #head(regularPrice)>
        {{ $t(`${moduleSlug}.table_head_regularPrice`) }}
      </template>
      <template #head(promoPrice)>
        {{ $t(`${moduleSlug}.table_head_promoPrice`) }}
      </template>
      <template #head(replacementCost)="data">
        {{ data.label }}
      </template>
      <template #head(flyerImportCost)>
        {{ $t(`${moduleSlug}.table_head_flyerImportCost`) }}
      </template>
      <template #head(flyerImportPrice)>
        {{ $t(`${moduleSlug}.table_head_flyerImportPrice`) }}
      </template>
      <template #head(promotionalImportCost)>
        {{ $t(`${moduleSlug}.table_head_promotionalImportCost`) }}
      </template>
      <template #head(promotionalImportPrice)>
        {{ $t(`${moduleSlug}.table_head_promotionalImportPrice`) }}
      </template>
      <template #head(flatFlyerRegularPrice)>
        {{ $t(`${moduleSlug}.table_head_flatFlyerRegularPrice`) }}
      </template>
      <template #head(flatFlyerPrice)>
        {{ $t(`${moduleSlug}.table_head_flatFlyerPrice`) }}
      </template>

      <template #head(everydayImportCost)>
        {{ $t(`${moduleSlug}.table_head_everydayCost`) }}
      </template>
      <template #head(everydayImportPrice)>
        {{ $t(`${moduleSlug}.table_head_regularPrice`) }}
      </template>

      <template #cell(products)="data">
        <b-img
          src="/media/icons/rectangle.svg"
          alt="Product Rectangle Icon"
          v-if="showProductIcon"
        />{{ data.item.productName }}
      </template>

      <template #cell(productTagManagerId)="data">
        {{ data.item.productTagManagerId }}
      </template>

      <template #cell(pricingManagerId)="data">
        {{ data.item.pricingManagerId }}
      </template>

      <template #cell(siteName)="data">
        {{ data.item.siteName }}
      </template>

      <template #cell(sku)="data">
        {{ data.item.productId }}
      </template>

      <template #cell(vmn)="data">
        {{ data.item.vendorModelNumber }}
      </template>

      <template #cell(everydayCost)="data">
        $&nbsp;{{ data.item.replacementCost }}
      </template>

      <template #cell(regularPrice)="data">
        $&nbsp;{{ data.item.regularPrice }}
      </template>

      <template #cell(promoPrice)="data">
        $&nbsp;{{ data.item.promoPrice }}
      </template>

      <template #cell(replacementCost)="data">
        $&nbsp;{{ data.item.replacementCost }}
      </template>

      <template #cell(flyerImportCost)="data">
        $&nbsp;{{ data.item.compareAtPrice }}
      </template>

      <template #cell(flyerImportPrice)="data">
        $&nbsp;{{ data.item.promoPrice }}
      </template>

      <template #cell(promotionalImportCost)="data">
        $&nbsp;{{ data.item.compareAtPrice }}
      </template>
      <template #cell(promotionalImportPrice)="data">
        $&nbsp;{{ data.item.promoPrice }}
      </template>

      <template #cell(flatFlyerRegularPrice)="data">
        $&nbsp;{{ data.item.regularPrice }}
      </template>

      <template #cell(flatFlyerPrice)="data">
        $&nbsp;{{ data.item.flyerPrice }}
      </template>

      <template #cell(flyerImportStatus)="data">
        <i class="icon-success" v-if="data.item.status == 1"></i>
        <i
          class="icon-error"
          v-else
          v-b-tooltip.hover="$t(`general.${data.item.statusMessage}`)"
        ></i>
      </template>

      <template #cell(everydayImportCost)="data">
        $&nbsp;{{ data.item.compareAtPrice }}
      </template>
      <template #cell(everydayImportPrice)="data">
        $&nbsp;{{ data.item.promoPrice }}
      </template>

      <template #cell(promotionalImportStatus)="data">
        <i class="icon-success" v-if="data.item.status == 1"></i>
        <i
          class="icon-error"
          v-else
          v-b-tooltip.hover="$t(`general.${data.item.statusMessage}`)"
        ></i>
      </template>

      <template #cell(everydayImportStatus)="data">
        <i class="icon-success" v-if="data.item.status == 1"></i>
        <i
          class="icon-error"
          v-else
          v-b-tooltip.hover="$t(`general.${data.item.statusMessage}`)"
        ></i>
      </template>
    </b-table>
    <table-pagination
      class="mt-3"
      :fromLimit="fromLimit | addLeadingZero"
      :toLimit="toLimit | addLeadingZero"
      :totalRecords="totalRecords | addLeadingZero"
      :currentPage="currentPage"
      :totalPages="totalPages"
      @paginate="paginate"
      :countInfo="`${moduleSlug}.table_count-info`"
      :recordsTitle="recordsTitle"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import config from "../../config";
import { forEach, ceil } from "lodash";
import moment from "moment";
import TablePagination from "./TablePagination.vue";

const { tableHeaders, exportCSVHeaders } = config;

export default {
  components: { TablePagination },
  name: "ProductPreview",
  props: {
    formName: {
      type: String,
      default: "everyday-pricing",
    },
    showProductIcon: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: [String, Array],
      default: "20",
    },
    exportData: {
      type: Boolean,
      default: true,
    },
    fileTitle: {
      type: String,
      default: "data",
    },
    recordsTitle: {
      type: String,
      default: "products",
    },
    moduleSlug: {
      type: String,
      default: "everyday-pricing",
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters(["filteredProducts", "selectedSite"]),
    ...mapGetters("pricingManager", [
      "pricingManagerId",
      "activePromotions",
      "history",
      "validFlyerImportData",
      "validFlatFlyerData",
      "validEverydayImportData",
      "validPromotionalImportData",
    ]),
    ...mapGetters("tagManager", {
      tagManagerId: "tagManagerId",
      tagManagerHistory: "history",
    }),
    fields() {
      return tableHeaders[this.formName];
    },
    csvFields() {
      return exportCSVHeaders[this.formName];
    },
    productdata() {
      if (this.formName == "active-promotion") {
        return this.activePromotions;
      } else if (this.formName == "pricing-history") {
        return this.history;
      } else if (this.formName == "flyer-pricing-import") {
        return this.validFlyerImportData;
      } else if (this.formName == "promotional-pricing-import") {
        return this.validPromotionalImportData;
      } else if (this.formName == "flat-flyer-pricing") {
        return this.validFlatFlyerData;
      } else if (this.formName == "tag-history") {
        return this.tagManagerHistory;
      } else if (this.formName == "everyday-pricing-import") {
        return this.validEverydayImportData;
      } else {
        return this.filteredProducts;
      }
    },
    items() {
      return this.productdata;
    },
    totalRecords() {
      return this.productdata.length;
    },
    totalPages() {
      return ceil(this.productdata.length / this.perPage);
    },
    fromLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.fromlimit;
    },
    toLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.tolimit;
    },
    csvHeader() {
      let header = {};
      forEach(this.csvFields, (field) => {
        header[field.key] = field.label;
      });
      return header;
    },
    controllerName() {
      let controllerName = "";
      switch (this.formName) {
        case "flyer-pricing":
          controllerName = "FlyerPricing.vue";
          break;
        case "promotional-pricing":
          controllerName = "PromotionalPricing.vue";
          break;
        case "tag-manager":
          controllerName = "TagManager.vue";
          break;
        case "flat-flyer-pricing":
          controllerName = "FlatFlyerPricing.vue";
          break;
        case "flyer-pricing-import":
          controllerName = "FlyerPricingImport.vue";
          break;
        case "promotional-pricing-import":
          controllerName = "PromotionalPricingImport.vue";
          break;
        case "filtered-everyday-pricing-import":
          controllerName = "EverydayPricingImport.vue";
          break;
        case "everyday-pricing-import":
          controllerName = "EverydayPricingImport.vue";
          break;
        default:
          controllerName = "EverydayPricing.vue";
          break;
      }
      return controllerName;
    },
    moduleName() {
      let moduleName = "";
      switch (this.formName) {
        case "flyer-pricing":
          moduleName = "Flyer Pricing";
          break;
        case "promotional-pricing":
          moduleName = "Promotional Pricing";
          break;
        case "tag-manager":
          moduleName = "Tag manager";
          break;
        case "flat-flyer-pricing":
          moduleName = "Flat Flyer Pricing";
          break;
        case "flyer-pricing-import":
          moduleName = "Flyer Pricing Import";
          break;
        case "promotional-pricing-import":
          moduleName = "Promotional Pricing Import";
          break;
        case "filtered-everyday-pricing-import":
          moduleName = "Everyday Pricing Import";
          break;
        case "everyday-pricing-import":
          moduleName = "Everyday Pricing Import";
          break;
        default:
          moduleName = "Everyday Pricing";
          break;
      }
      return moduleName;
    },
  },
  watch: {
    productdata() {
      this.currentPage = 1;
    },
  },
  methods: {
    ...mapActions("pricingManager", {
      pricingExportPreviewData: "getExportPreviewData",
    }),
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    ...mapActions("tagManager", {
      tagExportPreviewData: "getExportPreviewData",
    }),
    prepareCSVdata(headers, productdata) {
      let csvData = [];
      forEach(productdata, (data) => {
        let _csvData = {};
        forEach(headers, (val, key) => {
          _csvData[key] = "";

          if (key == "pricingManagerId") {
            _csvData[key] = String(this.pricingManagerId).replace(/,/g, ""); // remove commas to avoid errors,
          }

          if (key == "tagManagerId") {
            _csvData[key] = String(this.tagManagerId).replace(/,/g, ""); // remove commas to avoid errors,
          }

          if (key == "pricing") {
            if ("pricing" in data) {
              let formList = [
                "everyday-pricing",
                "promotional-pricing",
                "active-promotion",
                "flyer-pricing",
                "flat-flyer-pricing",
              ];
              if (formList.includes(this.formName)) {
                _csvData[key] = String(data.pricing).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }
          }

          if (key == "productId") {
            if ("productId" in data) {
              _csvData[key] = String(data.productId).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }

          if (key == "products") {
            _csvData[key] = String(data.productName).replace(/,/g, ""); // remove commas to avoid errors,
          }

          if (key == "vmn" || key == "vendorModelNumber") {
            if ("vendorModelNumber" in data) {
              _csvData[key] = String(data.vendorModelNumber).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }

          if (key == "sku") {
            if ("productSKU" in data) {
              _csvData[key] = String(data.productSKU).replace(/,/g, ""); // remove commas to avoid errors,
            }
            if ("productId" in data) {
              _csvData[key] = String(data.productId).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }

          if (key == "regularPrice") {
            if ("regularPrice" in data) {
              if (this.formName == "everyday-pricing-import") {
                _csvData[key] = "";
              } else {
                _csvData[key] = String(data.regularPrice).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }
          }
          if (key == "promoPrice") {
            if ("promoPrice" in data) {
              _csvData[key] = String(data.promoPrice).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "everydayCost") {
            if ("replacementCost" in data) {
              _csvData[key] = String(data.replacementCost).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "replacementCost") {
            if ("replacementCost" in data) {
              _csvData[key] = String(data.replacementCost).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "vendor") {
            if ("vendor" in data) {
              _csvData[key] = String(data.vendor).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "category") {
            if ("category" in data) {
              _csvData[key] = String(data.category).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "productTags") {
            if ("productTag" in data) {
              _csvData[key] = String(data.productTag).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "siteId") {
            if ("siteId" in data) {
              _csvData[key] = String(data.siteId).replace(/,/g, ""); // remove commas to avoid errors,
            } else if (this.formName == "filtered-everyday-pricing-import") {
              _csvData[key] = String(this.selectedSite.SiteId).replace(
                /,/g,
                ""
              );
            }
          }
          if (key == "siteName") {
            if ("siteName" in data) {
              _csvData[key] = String(data.siteName).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "flyerPrice") {
            if ("flyerPrice" in data) {
              _csvData[key] = String(data.flyerPrice).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "compareAtPrice") {
            if ("compareAtPrice" in data) {
              _csvData[key] = String(data.compareAtPrice).replace(/,/g, ""); // remove commas to avoid errors,
            } else if (this.formName == "filtered-everyday-pricing-import") {
              _csvData[key] = String(data.replacementCost).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "flyerImportStatus") {
            if ("status" in data) {
              _csvData[key] =
                data.status == 1
                  ? "Success"
                  : data.statusMessage != null
                  ? String(data.statusMessage).replace(/,/g, "")
                  : "Failed";
            }
          }
        });
        csvData.push(_csvData);
      });
      return csvData;
    },
    async download() {
      try {
        this.generalSetItem({ resource: "isLoading", payload: true });
        let headers = this.csvHeader;
        let productdata = this.productdata;
        if (
          this.formName == "everyday-pricing" ||
          this.formName == "promotional-pricing" ||
          this.formName == "flyer-pricing" ||
          this.formName == "flat-flyer-pricing" ||
          this.formName == "everyday-pricing-import-filter"
        ) {
          productdata = await this.pricingExportPreviewData({
            pricingManagerId: this.pricingManagerId,
            exceptionParams: {
              userId: this.loggedUserId,
              controllerName: this.controllerName,
              moduleName: this.moduleName,
            },
          });
        } else if (this.formName == "tag-manager") {
          productdata = await this.tagExportPreviewData({
            productTagManagerId: this.tagManagerId,
            exceptionParams: {
              userId: this.loggedUserId,
              controllerName: this.controllerName,
              moduleName: this.moduleName,
            },
          });
        }

        let csvData = this.prepareCSVdata(headers, productdata);

        let fileTitle =
          this.fileTitle + "_" + moment(new Date()).format("YYYY/MM/DD");

        this.generalSetItem({ resource: "isLoading", payload: false });

        this.exportCSVFile(headers, csvData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
      } catch {
        this.generalSetItem({ resource: "isLoading", payload: true });
      }
    },
    disableEntry(id) {
      this.$emit("disableEntry", id);
    },
    async performEditClone(params) {
      this.$emit("performEditClone", params);
    },
    paginate(eventType) {
      if (eventType == "next") {
        this.currentPage = this.currentPage + 1;
      } else {
        this.currentPage = this.currentPage - 1;
      }
    },
    rowClass(item, type) {
      let rowClass = "text-nowrap";

      if (!item || type !== "row") return;
      if (item && type === "row" && item.status === 1) return rowClass;
      if (item && type === "row" && item.status === 0)
        return `${rowClass} bg-brand-alert-error`;
    },
  },
};
</script>
