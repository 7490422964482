const mutations = {
  setItem(state, { resource, payload, debug = false }) {
    state[resource] = payload;
    if (debug) {
      console.log("setItem debug", resource, payload, state[resource]);
    }
  },
  resetSavedLanguage(state) {
    state.languages = [];
    state.selected_language = "en";
    state.translations = [];
  },
  // setFilterError(state, error) {},
  setProductGroups(state, data) {
    state.productGroups = data;
  },
  updateProductGroups(state, data) {
    let _productGroups = state.productGroups;
    _productGroups.map((group) => {
      if (group.groupId == data.groupId) {
        group[data.type] = data.filterData;
      }
    });
    state.productGroups = _productGroups;
  },
  addNewProductGroup(state, data) {
    let _productGroups = state.productGroups;
    _productGroups.push(data);
    state.productGroups = _productGroups;
  },
};

export default mutations;
