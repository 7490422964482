import config from "../../config";
const { filterErrorObject } = config;
const state = {
  languages: [],
  selected_language: "en",
  translations: [],
  userDashboardSite: null,
  selectedSite: null,
  isLoading: false,
  loadingFilters: false,
  vendorFilters: [],
  categoriesFilters: [],
  productTagFilters: [],
  vmnFilters: [],
  skuFilters: [],
  filterError: filterErrorObject,
  productGroups: [],
  statusCode: null,
  filteredProductError: false,
  filteredProducts: [],
  versionHistory: [],
};

export default state;
