<template>
  <div
    role="group"
    class="form-group"
    :class="{
      'mb-3': totalGroups > 1,
      'product-groups': enableProductGroupBoxStyling,
    }"
  >
    <label
      class="d-block form-label d-flex justify-content-between"
      v-if="enableProductGroupBoxStyling && enableAddProductGroup"
    >
      Product group {{ index + 1 }}
      <i
        class="icon-close"
        v-if="index > 0"
        @click="$emit('removeProductGroup', index)"
        role="button"
      ></i>
    </label>
    <div id="filter-div" v-clickoutside="minimizeFilters">
      <b-button
        class="btn-group"
        :class="{ collapsed: !visible }"
        :aria-expanded="visible ? 'true' : 'false'"
        aria-controls="productFilters"
        @click="visible = !visible"
        variant="brand-accordion"
        :disabled="disabled"
        >{{ $t(`${moduleSlug}.choose_product-placeholder`) }}</b-button
      >
      <b-collapse id="productFilters" class="" v-model="visible">
        <b-card>
          <common-filters
            key="vendorFilter"
            filterName="vendorFilter"
            v-if="enableVendorFilter"
            :moduleSlug="moduleSlug"
            :controllerName="controllerName"
            :moduleName="moduleName"
            :id="id"
            ref="vendorFilter"
            class="mb-3"
            :isPushPricing="isPushPricing"
          />
          <common-filters
            key="categoryFilter"
            filterName="categoryFilter"
            :moduleSlug="moduleSlug"
            ref="categoryFilter"
            :id="id"
            v-if="enableCategoryFilter"
            :controllerName="controllerName"
            :moduleName="moduleName"
            class="mb-3"
            :isPushPricing="isPushPricing"
          />
          <common-filters
            key="productTagFilter"
            filterName="productTagFilter"
            :moduleSlug="moduleSlug"
            ref="productTagFilter"
            :id="id"
            v-if="enableProductTagFilter"
            :controllerName="controllerName"
            :moduleName="moduleName"
            class="mb-3"
            :isPushPricing="isPushPricing"
          />
          <common-filters
            filterName="vmnFilter"
            key="vmnFilter"
            ref="vendorModelFilter"
            :id="id"
            v-if="enableVendorModelFilter"
            :moduleSlug="moduleSlug"
            :controllerName="controllerName"
            :moduleName="moduleName"
            class="mb-3"
            :isPushPricing="isPushPricing"
          />
          <common-filters
            filterName="skuFilter"
            key="skuFilter"
            ref="skuFilter"
            :id="id"
            v-if="enableSkuFilter"
            :moduleSlug="moduleSlug"
            :controllerName="controllerName"
            :moduleName="moduleName"
            class="mb-3"
            :isPushPricing="isPushPricing"
          />

          <b-button variant="brand-primary" @click="minimizeFilters">{{
            $t(`${moduleSlug}.minimize_filter_btn-label`)
          }}</b-button>
        </b-card>
      </b-collapse>
    </div>
    <!--
      Tags Div for Product Group
    -->
    <filtered-tags
      :id="id"
      :vendors="vendors"
      :category="category"
      :productTags="productTags"
      :vmn="vmn"
      :sku="sku"
      removeTags
    />
  </div>
</template>

<script>
import { eventBus } from "../main";
import { mapGetters } from "vuex";
import config from "../../config";
// import CategoryFilter from "./CategoryFilter.vue";
// import ProductTagFilter from "./ProductTagFilter.vue";
// import SkuFilter from "./SkuFilter.vue";
// import VendorFilter from "./VendorFilter.vue";
// import VendorModelFilter from "./VendorModelFilter.vue";
import { includes, forEach } from "lodash";
import FilteredTags from "./FilteredTags.vue";
import CommonFilters from "./CommonFilters.vue";
export default {
  name: "ProductFilters",
  props: [
    "moduleSlug",
    "id",
    "index",
    "totalGroups",
    "filterLabel",
    "requiredFilters",
    "disabled",
    "controllerName",
    "moduleName",
    "isPushPricing",
  ],
  components: {
    // VendorFilter,
    // CategoryFilter,
    // ProductTagFilter,
    // SkuFilter,
    // VendorModelFilter,
    FilteredTags,
    CommonFilters,
  },
  data() {
    return {
      enableProductGroupBoxStyling: config.enableProductGroupBoxStyling,
      enableAddProductGroup: config.enableAddProductGroup,
      visible: false,
      isVendorVisible: false,
      isCategoryVisible: false,
      isProductTagVisible: false,
    };
  },
  computed: {
    ...mapGetters(["selectedSite", "productGroups"]),
    enableVendorFilter() {
      return includes(this.requiredFilters, "vendorFilter");
    },
    enableCategoryFilter() {
      return includes(this.requiredFilters, "categoryFilter");
    },
    enableProductTagFilter() {
      return includes(this.requiredFilters, "productTagFilter");
    },
    enableVendorModelFilter() {
      return includes(this.requiredFilters, "vendorModelFilter");
    },
    enableSkuFilter() {
      return includes(this.requiredFilters, "skuFilter");
    },
    siteId() {
      return this.selectedSite.SiteId;
    },
    currentProductGroup() {
      return this.productGroups.find((group) => group.groupId == this.id);
    },
    vendors() {
      return this.currentProductGroup.vendor;
    },
    category() {
      return this.currentProductGroup.category;
    },
    productTags() {
      return this.currentProductGroup.productTags;
    },
    vmn() {
      return this.currentProductGroup.productVendorModel;
    },
    sku() {
      return this.currentProductGroup.productSKU;
    },
  },
  mounted() {
    eventBus.$emit("setFilter", {
      id: this.id,
      filterData: [
        {
          filterName: "vendorFilter",
          filters: this.vendors,
        },
        {
          filterName: "categoryFilter",
          filters: this.category,
        },
        {
          filterName: "productTagFilter",
          filters: this.productTags,
        },
        {
          filterName: "vmnFilter",
          filters: this.vmn,
        },
        {
          filterName: "skuFilter",
          filters: this.sku,
        },
      ],
    });
  },
  methods: {
    minimizeFilters() {
      this.visible = false;
      forEach(
        [
          "vendorFilter",
          "categoryFilter",
          "productTagFilter",
          "vendorModelFilter",
          "skuFilter",
        ],
        (filter) => {
          eventBus.$emit("closeFilters", filter);
        }
      );
    },
  },
};
</script>
