const mutations = {
  setItem(state, { resource, payload, debug = false }) {
    state[resource] = payload;
    if (debug) {
      console.log("setItem debug", resource, payload, state[resource]);
    }
  },
};

export default mutations;
