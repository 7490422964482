const state = {
  accessToken: null,
  refreshToken: null,
  siteAccess: null,
  user: null,
  userModulerPermission: null,
  defaultLandingRoute: "/everyday-pricing",
};

export default state;
