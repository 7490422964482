<template>
  <div :id="filterName + '_div'">
    <b-button
      class="sub-accordion"
      :class="{ collapsed: !visible, 'is-invalid': isFilterError }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="filterName"
      @click="getProductFilter"
      variant="brand-accordion"
      >{{ $t(`${moduleSlug}.${filterSlugs.filterLabel}`) }}</b-button
    >
    <b-collapse :id="filterName" class="sub-filter" v-model="visible">
      <b-card header-tag="header" class="sub-filter-card">
        <template #header>
          <b-form-input
            type="search"
            class="nosubmit w-100"
            v-model="text"
            :placeholder="$t(`${moduleSlug}.${filterSlugs.searchPlaceholder}`)"
          ></b-form-input>
          <b-form-checkbox
            v-model="allSelected"
            :indeterminate="indeterminate"
            :aria-describedby="filterArea + '_' + id"
            :aria-controls="filterArea + '_' + id"
            @change="toggleAll"
          >
            {{
              allSelected
                ? $t(`${moduleSlug}.${filterSlugs.selectAllLabel}`)
                : $t(`${moduleSlug}.${filterSlugs.unSelectAllLabel}`)
            }}
          </b-form-checkbox>
        </template>

        <template v-slot="{ ariaDescribedby }">
          <div class="scrollable row row-no-gutters">
            <div
              class="checkbox"
              :class="getClass"
              v-for="(option, index) in options"
              :key="index + '_' + option.name"
            >
              <b-form-checkbox
                :id="filterArea + '_' + index + '_' + option.name"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                :name="filterArea + '_' + index + '_' + option.name"
                :value="option.item"
                stacked
              >
                {{ option.name }}
              </b-form-checkbox>
            </div>
          </div>
          <div
            class="btn-condition mt-2"
            v-if="
              filterName === 'categoryFilter' ||
              filterName === 'productTagFilter'
            "
          >
            <button
              class="condition-or"
              :disabled="disabledCondition"
              :class="{
                'active-button': activeCondition == filterConditions.OR,
              }"
              @click="updateCondition($event, filterConditions.OR)"
            >
              {{ $t(`general.filter_condition_or-label`) }}
            </button>
            <button
              class="condition-and"
              :disabled="disabledCondition"
              :class="{
                'active-button': activeCondition == filterConditions.AND,
              }"
              @click="updateCondition($event, filterConditions.AND)"
            >
              {{ $t(`general.filter_condition_and-label`) }}
            </button>
            <i class="icon-tooltip ml-1" :id="filterSlugs.tooltipId"></i>
            <b-tooltip :target="filterSlugs.tooltipTarget"
              ><p v-html="$t(`${moduleSlug}.${filterSlugs.tooltip}`)"
            /></b-tooltip>
          </div>
        </template>
      </b-card>
    </b-collapse>
    <b-form-invalid-feedback>
      {{ sytemMessages.noProductsFound }}
    </b-form-invalid-feedback>
    <b-spinner small v-if="showLoader" />
  </div>
</template>

<script>
import { eventBus } from "../main";
import config from "../../config";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { forEach, findIndex, debounce, map } from "lodash";

const { filterConditions, filterErrorObject } = config;

export default {
  name: "CommonFilters",
  props: [
    "id",
    "moduleSlug",
    "controllerName",
    "moduleName",
    "filterName",
    "isPushPricing",
  ],
  data() {
    return {
      visible: false,
      showLoader: false,
      text: "",
      options: [],
      selected: [],
      allSelected: false,
      indeterminate: false,
      isFilterError: false,
      filterConditions: filterConditions,
      categoryCondition: filterConditions.OR,
      tagCondition: filterConditions.OR,
      vmnCondition: filterConditions.OR,
      skuCondition: filterConditions.OR,
    };
  },
  created() {
    eventBus.$on("closeFilters", (data) => {
      if (data != this.filterName) {
        this.visible = this.isFilterError = false;
      }
    });
    eventBus.$on("resetFilters", () => {
      this.selected = [];
    });
    eventBus.$on("setFilter", (data) => {
      if (data && data.id == this.id) {
        let filterData = data.filterData.find(
          (el) => el.filterName === this.filterName
        );
        if (
          filterData &&
          Object.keys(filterData).length != 0 &&
          this.filterName === filterData.filterName
        ) {
          this.selected = filterData.filters;
        }
      }
    });
    eventBus.$on("deleteFilter", (data) => {
      if (data.id == this.id) {
        const filterData = data.filterData;
        if (filterData.filterName === this.filterName) {
          let filter = filterData.filter;
          let _selected = this.selected;
          let index = this.getFilterIndex(filterData.filterName, filter);
          if (index != -1) {
            _selected.splice(index, 1);
            this.selected = _selected;
            this.updateProductGroups({
              type: this.determineType(),
              groupId: this.id,
              filterData: _selected,
            });
          }
        }
      }
    });
  },
  computed: {
    ...mapState(["vendorFilters", "categoriesFilters"]),
    ...mapGetters([
      "selectedSite",
      "vmnFilters",
      "skuFilters",
      "productTagFilters",
      "productGroups",
      "filterError",
    ]),
    filterSlugs() {
      let filterLabel = "",
        searchPlaceholder = "",
        selectAllLabel = "",
        unSelectAllLabel = "",
        tooltip = "",
        tooltipId = "",
        tooltipTarget = "";

      switch (this.filterName) {
        case "categoryFilter":
          filterLabel = "category_filter-label";
          searchPlaceholder = "category_filter_search-placeholder";
          selectAllLabel = "category_filter_unselect_all-label";
          unSelectAllLabel = "category_filter_select_all-label";
          tooltip = "category_filter_condition-tooltip";
          tooltipId = "category_condition-tootltip";
          tooltipTarget = "category_condition-tootltip";
          break;
        case "productTagFilter":
          filterLabel = "product_tag_filter-label";
          searchPlaceholder = "product_tag_filter_search-placeholder";
          selectAllLabel = "product_tag_filter_unselect_all-label";
          unSelectAllLabel = "product_tag_filter_select_all-label";
          tooltip = "product_tag_filter_condition-tooltip";
          tooltipId = "ProductTag_condition-tootltip";
          tooltipTarget = "ProductTag_condition-tootltip";
          break;
        case "vmnFilter":
          filterLabel = "vmn_filter-label";
          searchPlaceholder = "vmn_filter_search-placeholder";
          selectAllLabel = "vmn_filter_unselect_all-label";
          unSelectAllLabel = "vmn_filter_select_all-label";
          break;
        case "skuFilter":
          filterLabel = "sku_filter-label";
          searchPlaceholder = "sku_filter_search-placeholder";
          selectAllLabel = "sku_filter_unselect_all-label";
          unSelectAllLabel = "sku_filter_select_all-label";
          break;
        default:
          filterLabel = "vendor_filter-label";
          searchPlaceholder = "vendor_filter_search-placeholder";
          selectAllLabel = "vendor_filter_unselect_all-label";
          unSelectAllLabel = "vendor_filter_select_all-label";
          break;
      }

      return {
        filterLabel,
        searchPlaceholder,
        selectAllLabel,
        unSelectAllLabel,
        tooltip,
        tooltipId,
        tooltipTarget,
      };
    },
    filterArea() {
      let _filterArea = "";
      if (this.filterName == "categoryFilter") {
        _filterArea = "categories";
      } else if (this.filterName == "productTagFilter") {
        _filterArea = "productTags";
      } else if (this.filterName == "vmnFilter") {
        _filterArea = "vendorModel";
      } else if (this.filterName == "skuFilter") {
        _filterArea = "sku";
      } else {
        _filterArea = "vendors";
      }
      return _filterArea;
    },
    activeCondition() {
      if (this.filterName === "categoryFilter") return this.categoryCondition;
      else return this.tagCondition;
    },
    disabledCondition() {
      if (this.filterName === "categoryFilter")
        return (
          this.selected.length < 2 && this.filterName === this.activeFilter
        );
      else
        return (
          (this.selected.length < 2 && this.filterName === this.activeFilter) ||
          this.text == ""
        );
    },
    activeFilter() {
      if (this.filterName === "categoryFilter") return "categoryFilter";
      else return "productTagFilter";
    },
    siteId() {
      return this.selectedSite.SiteId;
    },
    currentProductGroup() {
      return this.productGroups.find((group) => group.groupId == this.id);
    },
    currentProductGroupVendor() {
      return this.currentProductGroup.vendor;
    },
    vendorIds() {
      let data = [];
      forEach(this.currentProductGroup.vendor, (vendorItem) => {
        data.push(vendorItem.vendorId);
      });
      return data.join();
    },
    categoryIds() {
      let data = [];
      forEach(this.currentProductGroup.category, (categoryItem) => {
        data.push(categoryItem.roomGroupingId);
      });
      return data.join();
    },
    productTags() {
      let data = [];
      forEach(this.currentProductGroup.productTags, (productTagItem) => {
        data.push(productTagItem.id);
      });
      return data.join();
    },
    getClass() {
      return this.options.length > 10
        ? "col-xl-6 col-lg-6 col-md-6 col-sm-6"
        : "col-12";
    },
  },
  methods: {
    ...mapMutations(["setItem"]),
    ...mapActions([
      "getVmnFilters",
      "getSkuFilters",
      "getProductTagFilters",
      "getVendorFilters",
      "getCategoriesFilters",
      "updateProductGroups",
    ]),
    determineType() {
      if (this.filterName == "vendorFilter") return "vendor";
      else if (this.filterName == "categoryFilter") return "category";
      else if (this.filterName == "productTagFilter") return "productTags";
      else if (this.filterName == "vmnFilter") return "productVendorModel";
      else if (this.filterName === "skuFilter") return "productSKU";
    },
    getProductFilter() {
      this.options = [];
      this.text = "";
      if (this.filterName === "vendorFilter") {
        this.getVendorFilter();
      } else if (this.filterName === "productTagFilter") {
        this.getProductTagFilter();
      } else if (this.filterName === "vmnFilter") {
        this.getVMNFilter();
      } else if (this.filterName === "skuFilter") {
        this.getSKUFilter();
      } else {
        this.getCategoryFilter();
      }
    },
    getVendorFilter() {
      if (this.visible) {
        this.visible = false;
        return;
      }
      let _filterErrorObject = Object.assign({}, filterErrorObject);
      this.setItem({ resource: "filterError", payload: _filterErrorObject });
      eventBus.$emit("closeFilters", "vendorFilter");
      this.showLoader = true;
      this.getVendorFilters({
        siteId: this.siteId,
        categoryIds: this.categoryIds,
        productTags: this.productTags,
        isPushPricing: this.isPushPricing,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        },
      })
        .then(() => {
          if (this.filterError.vendorFilter == true) {
            this.visible = false;
            this.isFilterError = true;
          } else {
            this.visible = true;
          }
          this.showLoader = false;
        })
        .catch(() => {
          this.showLoader = false;
        });
    },
    getCategoryFilter() {
      if (this.visible) {
        this.visible = false;
        return;
      }
      let _filterErrorObject = Object.assign({}, filterErrorObject);
      this.setItem({ resource: "filterError", payload: _filterErrorObject });
      eventBus.$emit("closeFilters", "categoryFilter");
      this.showLoader = true;
      this.getCategoriesFilters({
        siteId: this.siteId,
        vendorIds: this.vendorIds,
        productTags: this.productTags,
        isPushPricing: this.isPushPricing,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        },
      })
        .then(() => {
          if (this.filterError.categoriesFilter == true) {
            this.visible = false;
            this.isFilterError = true;
          } else {
            this.visible = true;
          }
          this.showLoader = false;
        })
        .catch(() => {
          this.showLoader = false;
        });
    },
    getProductTagFilter() {
      if (this.visible) {
        this.visible = false;
        return;
      }
      let _filterErrorObject = Object.assign({}, filterErrorObject);
      this.setItem({ resource: "filterError", payload: _filterErrorObject });
      eventBus.$emit("closeFilters", "productTagFilter");
      this.showLoader = true;
      this.getProductTagFilters({
        siteId: this.siteId,
        vendorIds: this.vendorIds,
        categoryIds: this.categoryIds,
        isPushPricing: this.isPushPricing,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        },
      })
        .then(() => {
          if (this.filterError.productTagFilter == true) {
            this.visible = false;
            this.isFilterError = true;
          } else {
            this.visible = true;
          }
          this.showLoader = false;
        })
        .catch(() => {
          this.showLoader = false;
        });
    },
    async getVMNFilter() {
      if (this.visible) {
        this.visible = false;
        return;
      }
      let _filterErrorObject = Object.assign({}, filterErrorObject);
      this.setItem({ resource: "filterError", payload: _filterErrorObject });
      eventBus.$emit("closeFilters", "vmnFilter");
      this.showLoader = true;
      this.getVmnFilters({
        siteId: this.siteId,
        vendorIds: this.vendorIds,
        categoryIds: this.categoryIds,
        productTagIds: this.productTags,
        isPushPricing: this.isPushPricing,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        },
      })
        .then(() => {
          if (this.filterError.vmnFilter == true) {
            this.visible = false;
            this.isFilterError = true;
          } else {
            this.visible = true;
          }
          this.showLoader = false;
        })
        .catch(() => {
          this.showLoader = false;
        });
    },
    async getSKUFilter() {
      if (this.visible) {
        this.visible = false;
        return;
      }
      let _filterErrorObject = Object.assign({}, filterErrorObject);
      this.setItem({ resource: "filterError", payload: _filterErrorObject });
      eventBus.$emit("closeFilters", "skuFilter");
      this.showLoader = true;
      this.getSkuFilters({
        siteId: this.siteId,
        vendorIds: this.vendorIds,
        categoryIds: this.categoryIds,
        productTagIds: this.productTags,
        isPushPricing: this.isPushPricing,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        },
      })
        .then(() => {
          if (this.filterError.skuFilter == true) {
            this.visible = false;
            this.isFilterError = true;
          } else {
            this.visible = true;
          }
          this.showLoader = false;
        })
        .catch(() => {
          this.showLoader = false;
        });
    },
    toggleAll(checked) {
      let selected = [];
      if (checked) {
        forEach(this.options, (option) => {
          selected.push(option.item);
        });
      }
      this.selected = selected;
    },
    updateCondition(event, condition) {
      if (this.filterName === "categoryFilter")
        this.categoryCondition = condition;
      else if (this.filterName === "productTagFilter")
        this.tagCondition = condition;
    },
    getFilterIndex(filterName, filter) {
      let index;
      if (filterName == "vendorFilter") {
        index = findIndex(
          this.selected,
          (e) => {
            return (
              e.condition == filter.condition &&
              e.siteId == filter.siteId &&
              e.vendorId == filter.vendorId &&
              e.vendorName == filter.vendorName
            );
          },
          0
        );
      } else if (filterName == "categoryFilter") {
        index = findIndex(
          this.selected,
          (e) => {
            return (
              e.roomGrouping == filter.roomGrouping &&
              e.roomGroupingId == filter.roomGroupingId &&
              e.siteId == filter.siteId &&
              e.storeBrandId == filter.storeBrandId &&
              e.condition == filter.condition
            );
          },
          0
        );
      } else if (filterName == "productTagFilter") {
        index = findIndex(
          this.selected,
          (e) => {
            return (
              e.id == filter.id &&
              e.siteId == filter.siteId &&
              e.tag == filter.tag &&
              e.condition == filter.condition
            );
          },
          0
        );
      } else if (filterName == "vmnFilter") {
        index = findIndex(
          this.selected,
          (e) => {
            return (
              e.productVendorModelId == filter.productVendorModelId &&
              e.siteId == filter.siteId &&
              e.productVendorModel == filter.productVendorModel &&
              e.condition == filter.condition
            );
          },
          0
        );
      } else if (filterName == "skuFilter") {
        index = findIndex(
          this.selected,
          (e) => {
            return (
              e.productSKU == filter.productSKU &&
              e.siteId == filter.siteId &&
              e.productSKUId == filter.productSKUId &&
              e.condition == filter.condition
            );
          },
          0
        );
      }

      return index;
    },
  },
  watch: {
    selected(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newValue.length === this.options.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }

      this.updateProductGroups({
        type: this.determineType(),
        groupId: this.id,
        filterData: newValue,
      });
    },
    vendorFilters(newValue) {
      this.options = [];
      if (newValue.length > 0) {
        let _options = [];
        forEach(newValue, (filter) => {
          let obj = {
            item: {
              ...filter,
              condition: this.filterConditions.OR,
              siteId: filter.siteId || this.siteId,
            },
            name: filter.vendorName,
          };
          _options.push(obj);
        });
        this.options = _options;
      }
    },
    categoriesFilters(newValue) {
      this.options = [];
      if (newValue.length > 0) {
        let _options = [];
        forEach(newValue, (filter) => {
          let obj = {
            item: {
              roomGrouping: filter.roomGrouping,
              roomGroupingId: filter.roomGroupingId,
              siteId: filter.siteId || this.siteId,
              storeBrandId: filter.storeBrandId,
              condition: this.categoryCondition,
            },
            name: filter.roomGrouping,
          };
          _options.push(obj);
        });
        this.options = _options;
      }
    },
    text: debounce(function (newValue) {
      if (this.filterName === "vendorFilter") {
        if (this.vendorFilters.length > 0) {
          let newOptions = [];
          forEach(this.vendorFilters, (filter) => {
            if (
              filter.vendorName
                .toLowerCase()
                .includes(newValue.toLowerCase()) ||
              newValue == ""
            ) {
              let obj = {
                item: {
                  ...filter,
                  condition: this.filterConditions.OR,
                  siteId: filter.siteId || this.siteId,
                },
                name: filter.vendorName,
              };
              newOptions.push(obj);
            }
          });
          this.options = newOptions;
        }
      } else if (this.filterName === "categoryFilter") {
        if (this.categoriesFilters.length > 0) {
          let newOptions = [];
          forEach(this.categoriesFilters, (filter) => {
            if (
              filter.roomGrouping
                .toLowerCase()
                .includes(newValue.toLowerCase()) ||
              newValue == ""
            ) {
              let obj = {
                item: {
                  roomGrouping: filter.roomGrouping,
                  roomGroupingId: filter.roomGroupingId,
                  siteId: filter.siteId || this.siteId,
                  storeBrandId: filter.storeBrandId,
                  condition: this.categoryCondition,
                },
                name: filter.roomGrouping,
              };
              newOptions.push(obj);
            }
          });
          this.options = newOptions;
        }
      } else if (this.filterName === "productTagFilter") {
        let newOptions = [];
        if (this.productTagFilters.length > 0 && newValue != "") {
          forEach(this.productTagFilters, (filter) => {
            if (
              filter.tag.toLowerCase().includes(newValue.toLowerCase()) ||
              newValue == ""
            ) {
              let obj = {
                item: {
                  id: filter.id,
                  siteId: filter.siteId || this.siteId,
                  tag: filter.tag,
                  condition: this.tagCondition,
                },
                name: filter.tag,
              };
              newOptions.push(obj);
            }
          });
        }

        if (newOptions.length === 0) {
          this.indeterminate = false;
          this.allSelected = false;
        } else if (newOptions.length === this.selected.length) {
          this.indeterminate = false;
          this.allSelected = true;
        } else {
          this.indeterminate = true;
          this.allSelected = false;
        }

        this.options = newOptions;
      } else if (this.filterName === "vmnFilter") {
        let newOptions = [];
        if (this.vmnFilters.length > 0 && newValue != "") {
          forEach(this.vmnFilters, (filter) => {
            if (
              filter.vendorModelNumber
                .toLowerCase()
                .includes(newValue.toLowerCase()) ||
              newValue == ""
            ) {
              let obj = {
                item: {
                  productVendorModelId: filter.vendorModelNumber,
                  siteId: filter.siteId || this.siteId,
                  productVendorModel: filter.vendorModelNumber,
                  condition: this.vmnCondition,
                },
                name: filter.vendorModelNumber,
              };
              newOptions.push(obj);
            }
          });
        }
        this.options = newOptions;
      } else if (this.filterName === "skuFilter") {
        let newOptions = [];
        if (this.skuFilters.length > 0 && newValue != "") {
          forEach(this.skuFilters, (filter) => {
            if (
              filter.productID.toLowerCase().includes(newValue.toLowerCase()) ||
              newValue == ""
            ) {
              let obj = {
                item: {
                  productSKU: filter.productID,
                  siteId: filter.siteId || this.siteId,
                  productSKUId: filter.productID,
                  condition: this.skuCondition,
                },
                name: filter.productID,
              };
              newOptions.push(obj);
            }
          });
        }
        this.options = newOptions;
      }
    }, 500),
    categoryCondition(newValue) {
      map(this.options, (option) => {
        option.item.condition = newValue;
        return option;
      });
      map(this.selected, (option) => {
        option.condition = newValue;
        return option;
      });

      this.updateProductGroups({
        type: "category",
        groupId: this.id,
        filterData: this.selected,
      });
    },
    tagCondition(newValue) {
      map(this.options, (option) => {
        option.item.condition = newValue;
        return option;
      });
      map(this.selected, (option) => {
        option.condition = newValue;
        return option;
      });

      this.updateProductGroups({
        type: "productTags",
        groupId: this.id,
        filterData: this.selected,
      });
    },
  },
};
</script>
