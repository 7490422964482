<template>
  <div class="sites-list">
    <portal to="breadcrumb">
      <div
        class="sites-filter d-flex align-items-center justify-content-sm-between"
      >
        <div class="sites-list-breadcrumb">
          <bread-crumb
            :activeModule="$route.meta.moduleLabel"
            :activePage="$route.meta.label"
            :activePageUrl="$route.path"
          />
        </div>
        <div class="filter position-relative" v-clickoutside="closeFilter">
          <b-button variant="brand-secondary" @click="showFilter = !showFilter"
            ><i class="icon-filter"></i> Filter</b-button
          >
          <div
            class="filter-menu p-3 bg-white shadow-sm radius-25 position-absolute"
            v-if="showFilter"
          >
            <b-form-group
              label-for="statusOptions"
              label-class="form-label"
              label="Status:"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                id="statusOptions"
                :aria-describedby="ariaDescribedby"
                v-model="statusFilterSelection"
                :options="statusFilterOptions"
                name="statusOptions"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </div>
    </portal>
    <b-card class="mt-2 admin-grid">
      <div
        class="row d-md-flex align-items-center mb-0 mb-md-3"
        v-if="showGridActions"
      >
        <div
          class="col-xl-4 col-md-6 col-sm-12 pl-xxl-0 pr-md-0 mb-2 mb-md-2 mb-xl-0"
        >
          <input
            type="search"
            v-model="siteSearch"
            placeholder="Search"
            class="nosubmit w-100"
          />
        </div>
        <div
          class="col-xl-3 col-md-4 col-sm-12 pl-xxl-0 pr-md-0 mb-2 mb-md-0 mb-xl-0"
        >
          <b-dropdown
            id="site-status"
            text="Change status"
            ref="statusDropdown"
            block
            no-caret
            variant="brand-dropdown"
            menu-class="w-100 scrollable"
            :disabled="siteIds.length == 0"
          >
            <b-dropdown-item
              v-for="(option, index) in statusOptions"
              :key="index"
              :active="selectedStatus == option.value"
              @click="setSelectedStatus(option)"
            >
              {{ option.text }}</b-dropdown-item
            >
            <b-dropdown-item class="dropdown-footer">
              <b-button
                variant="brand-secondary"
                size="sm"
                @click="handleChangeStatus"
                >Apply</b-button
              >
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <b-table
        responsive
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        tbody-tr-class="text-nowrap"
        ref="sitesTable"
        show-empty
        :empty-text="$t('general.no-data-msg')"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :filter-debounce="300"
        :filter-function="filterTable"
        v-model="displayedRows"
      >
        <template #head(checkbox)>
          <b-form-checkbox v-model="allSelected" @change="toggleAll">
          </b-form-checkbox>
        </template>
        <template #cell(checkbox)="data">
          <b-form-checkbox
            class="d-block"
            :id="'checkbox-' + data.item.siteId"
            :value="data.item.siteId"
            v-model="siteIds"
            v-if="
              data.item.siteCreationStatusId == 0 ||
              data.item.siteCreationStatusId == 1
            "
          />
          <span v-else class="disabled-checkbox-placeholder"></span>
        </template>

        <template #cell(status)="data">
          <!-- <span
            :id="'tooltip-_' + data.item.siteId"
            class="tag-status"
            :class="{
              active: data.item.siteCreationStatusId == 0,
              inactive: data.item.siteCreationStatusId == 1,
              'in-progress': data.item.siteCreationStatusId == 2,
              failed: data.item.siteCreationStatusId == 3,
            }"
            >{{ data.item.siteCreationStatus }}</span
          >

          <b-tooltip
            :disabled.sync="data.item.disableTooltip"
            :target="'tooltip-_' + data.item.siteId"
          >
            {{ data.item.summary }}
          </b-tooltip> -->
          <span
            :id="'tooltip-_' + data.item.siteId"
            class="tag-status"
            :class="{
              active: data.item.active == 1,
              inactive: data.item.active == 0,
            }"
            >{{ data.item.status }}</span
          >

          <b-tooltip
            v-if="
              data.item.siteCreationStatusId == 2 ||
              data.item.siteCreationStatusId == 3
            "
            :target="'tooltip-_' + data.item.siteId"
          >
            {{ data.item.siteCreationStatus }}
          </b-tooltip>
        </template>

        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
      <table-pagination
        class="mb-3"
        :fromLimit="fromLimit"
        :toLimit="toLimit"
        :totalRecords="totalRecords"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @paginate="paginate"
        recordsTitle="sites"
      />
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import config from "../../../../../config";
import { forEach, ceil } from "lodash";

import TablePagination from "../../../../components/TablePagination.vue";
import BreadCrumb from "../../../../components/BreadCrumb.vue";

const { tableHeaders, siteManager } = config;

const { modalSetting, resultPerPage } = siteManager;

export default {
  name: "SitesIndex",
  components: {
    TablePagination,
    BreadCrumb,
  },
  data() {
    return {
      showFilter: false,
      showGridActions: true,
      currentPage: 1,
      totalRecords: 0,
      totalPages: 0,
      perPage: resultPerPage,

      fields: tableHeaders["sites-list"],
      allSelected: false,
      indeterminate: false,
      isStatusItemClicked: false,
      siteIds: [],
      displayedRows: [],
      statusOptions: [
        {
          text: "Active",
          value: 1,
        },
        {
          text: "Inactive",
          value: 0,
        },
      ],
      statusFilterOptions: [
        {
          text: "Active",
          value: 0,
        },
        {
          text: "Inactive",
          value: 1,
        },
        {
          text: "Inprogress",
          value: 2,
        },
        {
          text: "Creation failed",
          value: 3,
        },
      ],
      selectedStatus: 0,
      siteSearch: "",
      businessSearchText: "",
      domainSearchText: "",
      siteIdSearchText: "",
      statusFilterSelection: [],
      filterOn: [],
      filter: {
        siteSearch: "",
        name: "",
        url: [],
        siteId: [],
        siteCreationStatusId: [],
      },
    };
  },
  computed: {
    ...mapGetters("siteManager", ["gridList", "tblLoader"]),
    items() {
      let data = this.gridList.map((item) => {
        let disableTooltip = item.siteCreationStatusId == 3 ? false : true;
        return { ...item, disableTooltip: disableTooltip };
      });

      return data;
      // return this.gridList;
    },
    fromLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.fromlimit;
    },
    toLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.tolimit;
    },
  },
  watch: {
    gridList() {
      this.resetPageFilters(1);
    },
    currentPage(newValue) {
      this.resetPageFilters(newValue);
    },
    siteIds(newValue) {
      if (
        this.displayedRows.length &&
        this.displayedRows.length == newValue.length
      ) {
        this.allSelected = true;
        this.indeterminate = false;
      } else {
        this.allSelected = false;
        this.indeterminate = true;
      }
    },
    siteSearch(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("siteSearch");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("siteSearch");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["siteSearch"] = newValue;
    },
    businessSearchText(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("name");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("name");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["name"] = newValue;
    },
    domainSearchText(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("url");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("url");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["url"] = newValue;
    },
    siteIdSearchText(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("siteId");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("siteId");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["siteId"] = newValue;
    },
    statusFilterSelection(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("siteCreationStatusId");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("siteCreationStatusId");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["siteCreationStatusId"] = newValue;
    },
  },
  methods: {
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    ...mapActions("siteManager", ["getAll", "updateStatus"]),
    ...mapMutations("siteManager", ["setItem"]),
    toggleAll(checked) {
      this.indeterminate = false;
      let selectedSites = [];
      if (checked) {
        forEach(this.displayedRows, (row) => {
          selectedSites.push(row.siteId);
        });
      }
      this.siteIds = selectedSites;
    },
    setSelectedStatus(option) {
      this.isStatusItemClicked = true;
      this.selectedStatus = option.value;
    },
    async handleChangeStatus() {
      let modalTitle = "";
      let modalDescription = "";
      if (this.selectedStatus == 0) {
        modalTitle = modalSetting.activateSite.title;
        modalDescription = modalSetting.activateSite.description;
      } else {
        modalTitle = modalSetting.inActiveSite.title;
        modalDescription = modalSetting.inActiveSite.description;
      }

      const h = this.$createElement;

      const titleVNode = h("h5", {
        class: ["modal-title font-weight-bold"],
        domProps: { innerHTML: modalTitle },
      });

      this.$bvModal
        .msgBoxConfirm(modalDescription, {
          title: [titleVNode],
          size: "sm",
          buttonSize: "md",
          okVariant: "brand-primary w-50",
          cancelVariant: "brand-secondary border-0 w-50",
          okTitle: "Save",
          cancelTitle: "Cancel",
          headerClass:
            "justify-content-center p-3 border-bottom-0 font-weight-bolder",
          footerClass: "p-3 border-top-0 flex-nowrap",
          hideHeaderClose: true,
          centered: true,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
        })
        .then(async (value) => {
          if (value) {
            this.generalSetItem({ resource: "isLoading", payload: true });
            let data = {
              loginId: this.loggedUserId,
              sitesIds: this.siteIds.join(),
              status: this.selectedStatus,
            };
            await this.updateStatus(data);
            await this.getAll({ userId: this.loggedUserId });
            this.generalSetItem({ resource: "isLoading", payload: false });
          }
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
          // An error occurred
        });
    },
    // async editSite(item) {
    //   this.$router.push({ name: "edit-users", params: { id: item.userId } });
    // },
    resetPageFilters(currentPage) {
      this.currentPage = currentPage;
      this.siteIds = [];
      this.allSelected = this.indeterminate = false;
    },
    paginate(eventType) {
      if (eventType == "next") {
        this.currentPage = this.currentPage + 1;
      } else {
        this.currentPage = this.currentPage - 1;
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
      this.totalRecords = filteredItems.length;
      this.totalPages = ceil(filteredItems.length / this.perPage);
    },
    filterTable(row, filter) {
      let filterCondition = {};

      if (this.filterOn.length) {
        this.filterOn.forEach((item) => {
          if (filter[item].length) {
            filterCondition[item] = true;
            if (item == "name" || item == "url") {
              if (!row[item].toLowerCase().includes(filter[item])) {
                filterCondition[item] = false;
              }
            } else if (item == "siteId") {
              if (!String(row[item]).includes(String(filter[item]))) {
                filterCondition[item] = false;
              }
            } else if (item == "siteCreationStatusId") {
              if (!filter[item].includes(row[item])) {
                filterCondition[item] = false;
              }
            } else if (item == "siteSearch") {
              let nameMatched = row["name"]
                .toLowerCase()
                .includes(filter[item].toLowerCase());
              let urlMatched = row["url"]
                .toLowerCase()
                .includes(filter[item].toLowerCase());
              let siteIdMatched = String(row["siteId"])
                .toLowerCase()
                .includes(String(filter[item]).toLowerCase());

              filterCondition[item] =
                nameMatched || urlMatched || siteIdMatched;
            }
          }
        });
      }

      const areTrue = Object.values(filterCondition).every(
        (value) => value === true
      );
      return areTrue;
    },
    resetGridFilter() {
      this.siteSearch = "";
      this.businessSearchText = "";
      this.domainSearchText = "";
      this.siteIdSearchText = "";
      this.statusFilterSelection = [];
      this.filterOn = [];
      this.filter = {
        siteSearch: "",
        name: "",
        url: [],
        siteId: [],
        siteCreationStatusId: [],
      };
    },
    closeFilter() {
      this.showFilter = false;
      this.toggleSiteDropdownFilter("close");
    },
  },
  async beforeMount() {
    try {
      this.resetGridFilter();
      this.generalSetItem({ resource: "isLoading", payload: true });
      await this.getAll({ userId: this.loggedUserId });
      this.generalSetItem({ resource: "isLoading", payload: false });
    } catch {
      this.generalSetItem({ resource: "isLoading", payload: false });
    }
  },
  mounted: function () {
    this.$root.$on("bv::dropdown::hide", (bvEvent) => {
      if (bvEvent.componentId === "site-status") {
        if (this.isStatusItemClicked) {
          bvEvent.preventDefault();
          this.isStatusItemClicked = false;
        }
      }
    });
  },
  destroyed() {
    this.setItem({ resource: "searchQuery", payload: "" });
    this.resetGridFilter();
  },
};
</script>
