<template>
  <div class="tag-history">
    <portal to="breadcrumb">
      <div
        class="users-filter d-flex align-items-sm-center justify-content-sm-between flex-md-row flex-column"
      >
        <div class="user-list-breadcrumb">
          <bread-crumb
            :activeModule="$route.meta.moduleLabel"
            :activePage="$route.meta.label"
            :activePageUrl="$route.path"
          />
        </div>
        <div class="filter position-relative" v-clickoutside="closeFilter">
          <b-button variant="brand-secondary" @click="showFilter = !showFilter"
            ><i class="icon-filter"></i> Filter</b-button
          >
          <div
            class="filter-menu p-3 bg-white shadow-sm radius-25 position-absolute"
            v-if="showFilter"
            :class="filterStyle"
          >
            <b-form-group
              label-for="site-selection-form"
              label-class="form-label"
              :label="$t(`tag-history.table_head_siteName`)"
              v-slot="{ ariaDescribedby }"
            >
              <b-dropdown
                id="site-selection-form"
                :ariaDescribedby="ariaDescribedby"
                :text="$t(`tag-history.table_head_siteName`)"
                ref="siteSelection"
                block
                no-caret
                lazy
                variant="brand-select"
                toggle-class="searchable-column position-relative"
                boundary="window"
                @hide="toggleHeight('hide')"
                @show="toggleHeight('show')"
              >
                <b-dropdown-form @submit.prevent>
                  <div class="form-group">
                    <b-form-input
                      type="search"
                      class="nosubmit w-100"
                      :placeholder="
                        $t(`tag-history.table_head_siteName-search-placeholder`)
                      "
                      v-model="searchSiteText"
                    />
                  </div>
                  <b-form-checkbox-group
                    class="scrollable"
                    id="sites"
                    v-model="selected"
                    :options="sitesOptions"
                    name="sites"
                  ></b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-form-group>
            <hr />
            <b-form-group
              label-for="pricingType"
              label-class="form-label"
              :label="$t(`tag-history.table_head_status`)"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                class="scrollable"
                :ariaDescribedby="ariaDescribedby"
                id="tagStatus"
                v-model="tagStatus"
                :options="statuOption"
                name="tagStatus"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </div>
    </portal>
    <b-card class="mt-3">
      <b-overlay :show="isLoading" rounded="sm">
        <product-preview-failed v-if="productPreviewFailed" />
        <div class="product-preview" v-else>
          <!-- <table-pagination
            :fromLimit="fromLimit | addLeadingZero"
            :toLimit="toLimit | addLeadingZero"
            :totalRecords="totalRecords | addLeadingZero"
            :showExportButton="false"
            :currentPage="currentPage"
            :totalPages="totalPages"
            :countInfo="'tag-history.table_count-info'"
            @paginate="paginate"
            class="mb-3"
          /> -->

          <div class="row d-md-flex align-items-center mb-0 mb-md-3">
            <div
              class="col-xl-4 col-md-6 col-sm-12 pl-xxl-0 pr-md-0 mb-2 mb-md-2 mb-xl-0"
            >
              <input
                type="search"
                v-model="searchValue"
                :placeholder="$t(`tag-history.table_search_placeholder`)"
                class="nosubmit w-100"
              />
            </div>
          </div>

          <b-table
            hover
            responsive
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            tbody-tr-class="text-nowrap"
            show-empty
            :empty-text="$t('general.no-data-msg')"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            :filter-debounce="300"
            :filter-function="filterTable"
            v-model="displayedRows"
          >
            <!--- Custom configuration for table headers -->
            <template #head(tagManagerTitle)>
              {{ $t(`tag-history.table_head_descriptionTitle`) }}
            </template>
            <template #head(productTagManagerId)>
              {{ $t(`tag-history.table_head_tagManagerId`) }}
            </template>
            <template #head(siteName)>
              {{ $t(`tag-history.table_head_siteName`) }}
            </template>
            <template #head(tagName)>
              {{ $t(`tag-history.table_head_tagName`) }}
            </template>
            <template #head(tagManagerStatus)>
              {{ $t(`tag-history.table_head_status`) }}
            </template>
            <template #head(tagManagerLastEdit)>
              {{ $t(`tag-history.last-time-edit`) }}
            </template>

            <!--- Custom configuration for table cells -->
            <template #cell(tagManagerTitle)="data">
              {{ data.item.title }}
            </template>
            <template #cell(productTagManagerId)="data">
              {{ data.item.id }}
            </template>
            <template #cell(tagName)="data">
              {{ data.item.tag }}
            </template>
            <template #cell(tagManagerStatus)="data">
              <span
                class="tag-status"
                :class="{
                  inactive: data.item.isActive == 0,
                  active: data.item.isActive == 1,
                  scheduled: data.item.isActive == 2,
                }"
                >{{ data.item.status }}</span
              >
            </template>
            <template #cell(tagManagerLastEdit)="data">
              {{ data.item.lastEditTime | formatedHistoryDate }}
            </template>

            <template #cell(historyAction)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none menu-link position-relative"
                no-caret
                boundary="window"
              >
                <template #button-content>
                  <b-img
                    src="/media/icons/menu-black.svg"
                    class="me-2"
                    alt="Menu"
                    role="button"
                  />
                </template>
                <b-dropdown-item
                  @click="performEditClone({ id: data.item.id, mode: 'edit' })"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  @click="performEditClone({ id: data.item.id, mode: 'clone' })"
                  >Clone</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="data.item.isActive == 1 || data.item.isActive == 2"
                  @click="disableEntry(data.item.id)"
                  >Disable</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-table>
          <table-pagination
            class="mt-3"
            :fromLimit="fromLimit | addLeadingZero"
            :toLimit="toLimit | addLeadingZero"
            :totalRecords="totalRecords | addLeadingZero"
            :currentPage="currentPage"
            :totalPages="totalPages"
            :countInfo="'tag-history.table_count-info'"
            @paginate="paginate"
          />
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { forEach, ceil } from "lodash";
import config from "../../../../config";
import ProductPreviewFailed from "@/components/ProductPreviewFailed.vue";
import TablePagination from "@/components/TablePagination.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";

const { tableHeaders, tagManagerConfig } = config;

export default {
  name: "History",
  components: {
    ProductPreviewFailed,
    TablePagination,
    BreadCrumb,
  },
  data() {
    return {
      sitesOptions: [],
      currentPage: 1,
      totalRecords: 0,
      totalPages: 0,
      selected: [],
      showProductIcon: false,
      showFilter: false,
      exportData: false,
      isLoading: false,
      search: "",
      orderBy: "lastEditTime",
      orderByDirection: "DESC",
      formName: "tag-history",
      perPage: tagManagerConfig.perPage,
      displayedRows: [],
      filterOn: [],
      title: "",
      type: "",
      tagName: "",
      searchValue: "",
      searchSiteText: "",
      filter: {
        searchValue: "",
        title: "",
        siteName: [],
        isActive: [],
        tag: [],
      },
      tagStatus: [],
      statuOption: [
        { text: "Active", value: 1 },
        { text: "Inactive", value: 0 },
        { text: "Scheduled", value: 2 },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["siteAccess"]),
    ...mapGetters("tagManager", ["history"]),
    fields() {
      return tableHeaders[this.formName];
    },
    productPreviewFailed() {
      return this.history.length == 0 ? true : false;
    },
    // getHeight() {
    //   if (this.type == "show") return "filter-height";
    //   else return "";
    // },
    productdata() {
      // return this.history;
      let data = this.history.map((item) => {
        let siteNames = item.siteName.split(",");
        return { ...item, siteNames: siteNames };
      });
      return data;
    },
    items() {
      return this.productdata;
    },
    fromLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.fromlimit;
    },
    toLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.tolimit;
    },
  },
  watch: {
    searchSiteText(newValue) {
      if (this.siteAccess.length > 0) {
        let newOptions = [];
        forEach(this.siteAccess, (site) => {
          if (
            site.Name.toLowerCase().includes(newValue.toLowerCase()) ||
            newValue == ""
          ) {
            let obj = {
              text: site.Name,
              value: { siteId: site.SiteId, siteName: site.Name },
            };
            newOptions.push(obj);
          }
        });
        this.sitesOptions = newOptions;
      }
    },
    selected(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("siteName");

      if (newValue.length) {
        if (index == -1) {
          _filterOn.push("siteName");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["siteName"] = newValue.length
        ? newValue.map((a) => a.siteName)
        : [];
    },
    productdata(newValue) {
      this.currentPage = 1;
      if (newValue.length) {
        this.totalRecords = newValue.length;
        this.totalPages = ceil(newValue.length / this.perPage);
      }
    },
    title(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("title");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("title");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["title"] = newValue;
    },
    searchValue(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("searchValue");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("searchValue");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["searchValue"] = newValue;
    },
    tagName(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("tag");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("tag");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["tag"] = newValue;
    },
    tagStatus(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("isActive");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("isActive");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["isActive"] = newValue.length ? newValue : [];
    },
  },
  methods: {
    ...mapActions("tagManager", [
      "getHistory",
      "getTagHistory",
      "updateStatus",
    ]),
    ...mapMutations("tagManager", ["setItem"]),
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    getHistoryData() {
      let _siteIds = this.selected.map((_selected) => _selected.siteId);
      this.generalSetItem({ resource: "isLoading", payload: true });

      let filterObject = {
        siteIds: _siteIds.join(),
        userId: this.loggedUserId,
        orderBy: this.orderBy,
        orderByDirection: this.orderByDirection,
      };

      if (this.search) {
        filterObject = { ...filterObject, searchText: this.search };
      }

      // this.generalSetItem({ resource: "isLoading", payload: false });

      this.getHistory(filterObject)
        .then(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    toggleHeight(type) {
      this.type = type;
    },
    async disableEntry(id) {
      await this.updateStatus({
        productTagManagerId: id,
        userId: this.loggedUserId,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: "TagHistory.vue",
          moduleName: "Tag History",
        },
      });
      this.getHistoryData();
    },
    performEditClone(params) {
      let { id, mode } = params;

      this.generalSetItem({ resource: "isLoading", payload: true });
      this.getTagHistory({
        id: id,
        userId: this.loggedUserId,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: "TagHistory.vue",
          moduleName: "Tag History",
        },
      })
        .then((response) => {
          if (response.status == 1) {
            let _data = response.data;

            this.setItem({ resource: "mode", payload: mode });
            this.setItem({ resource: "preFilledData", payload: _data });

            this.$router.push({
              path: "/tag-manager",
            });
          } else {
            this.$bvToast.toast(response.error, {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          }
          this.generalSetItem({ resource: "isLoading", payload: false });
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    paginate(eventType) {
      if (eventType == "next") {
        this.currentPage = this.currentPage + 1;
      } else {
        this.currentPage = this.currentPage - 1;
      }
    },
    filterTable(row, filter) {
      if (filter.siteName.length == 0) return false;
      let filterCondition = {};

      if (this.filterOn.length) {
        this.filterOn.forEach((item) => {
          if (filter[item].length) {
            filterCondition[item] = true;
            if (item == "siteName") {
              let existingSites = {};
              row.siteNames.forEach((site) => {
                existingSites[site] = false;
                if (filter[item].includes(site)) existingSites[site] = true;
              });
              const areTrue = Object.values(existingSites).some(
                (value) => value === true
              );
              filterCondition[item] = areTrue;
            } else if (item == "title" || item == "tag") {
              if (!row[item].includes(filter[item])) {
                filterCondition[item] = false;
              }
            } else if (item == "isActive") {
              if (!filter[item].includes(row[item])) {
                filterCondition[item] = false;
              }
            } else if (item == "searchValue") {
              let idMatched = filter[item].includes(row["id"]);
              let titleMatched = row["title"]
                .toLowerCase()
                .includes(filter[item].toLowerCase());
              let tagMatched = row["tag"]
                .toLowerCase()
                .includes(filter[item].toLowerCase());

              filterCondition[item] = idMatched || titleMatched || tagMatched;
            }
          }
        });
      }

      const areTrue = Object.values(filterCondition).every(
        (value) => value === true
      );
      return areTrue;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
      this.totalRecords = filteredItems.length;
      this.totalPages = ceil(filteredItems.length / this.perPage);
    },
    closeFilter() {
      this.type = "hide";
      this.showFilter = false;
      this.toggleSiteDropdownFilter("close");
    },
  },
  beforeMount() {
    let options = [];
    forEach(this.siteAccess, (site) => {
      let obj = {
        text: site.Name,
        value: { siteId: site.SiteId, siteName: site.Name },
      };
      options.push(obj);
    });
    this.search = "";
    this.sitesOptions = options;
    this.selected = [];
    this.filterOn = [];
    this.title = "";
    this.searchValue = "";
    this.tagName = "";
    this.searchSiteText = "";
    (this.filter = {
      searchValue: "",
      title: "",
      siteName: [],
      isActive: [],
      tag: [],
    }),
      (this.tagStatus = []);
  },
  mounted() {
    forEach(this.sitesOptions, (option) => {
      this.selected.push(option.value);
    });
    this.getHistoryData();
  },
  beforeDestroy() {
    this.setItem({ resource: "history", payload: [] });
  },
};
</script>
