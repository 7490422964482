import service from "../service";

const actions = {
  async getAll({ state, commit }, payload) {
    try {
      let searchQuery = state.searchQuery;
      state.tblLoader = true;
      commit("setItem", { resource: "gridList", payload: [] });
      let searchObject = { userId: payload.userId };
      if (searchQuery) {
        searchObject = { ...searchObject, searchText: searchQuery };
      }
      const results = await service.getAll(searchObject);
      state.tblLoader = false;
      commit("setItem", { resource: "gridList", payload: results });
    } catch (e) {
      state.tblLoader = false;
    }
  },
  async getById({ commit }, id) {
    const result = await service.getById(id);
    commit("setItem", { resource: "store", payload: result });
    return result;
  },
  async manageSite(_, store) {
    const response = await service.manageSite(store);
    return response;
  },
  async deleteStore({ dispatch }, id) {
    await service.deleteById(id);
    dispatch("getAll", {});
  },
  async getStoreList({ commit }, payload) {
    const result = await service.getStoreList(payload);
    commit("setItem", { resource: "storeList", payload: result });
    return result;
  },
  async getNewSiteId(payload) {
    const result = await service.getNewSiteId(payload);
    return result;
  },
  async getStorePermission(_, params) {
    const result = await service.getStorePermission(params);
    return result;
  },
  async updateStorePermission(_, params) {
    const result = await service.updateStorePermission(params);
    return result;
  },
  async updateStatus(_, params) {
    await service.updateStatus(params);
  },
  async verifySiteApiKeys(_, params) {
    const response = await service.verifySiteApiKeys(params);
    return response;
  },
  async getGraphqlPathVersion(_, payload) {
    const response = await service.getGraphqlPathVersion(payload);
    return response;
  },
};

export default actions;
