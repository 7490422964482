<template>
  <router-view />
</template>

<script>
import { eventBus } from "./main";
export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "DRSG",
    // all titles will be injected into this template
    titleTemplate: "%s | Dufresne Retail Solution Group",
    script: [
      {
        src: "https://www.googletagmanager.com/gtag/js?id=G-K9HD0HBMLM",
        async: true,
        defer: false,
        skip: process.env.NODE_ENV == "production" ? false : true,
      },
      {
        skip: process.env.NODE_ENV == "production" ? false : true,
        innerHTML: `window.dataLayer = window.dataLayer || [];
        function gtag(){
          dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-K9HD0HBMLM');`,
      },
    ],
  },
  methods: {
    resizeHandler() {
      eventBus.$emit("close-mobilenav");
    },
  },
  beforeCreate() {
    // localStorage.clear();
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);

    eventBus.$on("apiError", (message) => {
      this.$bvToast.toast(message, {
        title: "Error",
        variant: "danger",
        autoHideDelay: 5000,
        solid: true,
      });
    });
  },

  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
};
</script>

<style lang="scss">
// @import "@/assets/styles/app.scss";
</style>
