const pricingManagerMenu = [
  {
    name: "Everyday Pricing",
    route: "everyday-pricing",
    slug: "everyday-pricing",
    languageSlug: "everyday-pricing",
    icon: "everyday-pricing",
    isAllowed: true,
    permissionName: "everyday-pricing-access",
    submenu: [
      {
        name: "Everyday Pricing Import",
        route: "everyday-pricing-import",
        slug: "everyday-pricing-import",
        languageSlug: "everyday-pricing-import",
        isAllowed: true,
        permissionName: "everyday-pricing-access",
      },
    ],
  },
  {
    name: "Promotional Pricing",
    route: "promotional-pricing",
    slug: "promotional-pricing",
    languageSlug: "promotional-pricing",
    icon: "promotional-pricing",
    isAllowed: true,
    permissionName: "promotional-pricing-access",
    submenu: [
      {
        name: "Promotional Pricing Import",
        route: "promotional-pricing-import",
        slug: "promotional-pricing-import",
        languageSlug: "promotional-pricing-import",
        isAllowed: true,
        permissionName: "promotional-pricing-access",
      },
    ],
  },
  // {
  // 	name: "Flyer Pricing",
  // 	route: "/flyer-pricing",
  // 	icon: "flyer-pricing",
  // 	slug: "flyer-pricing",
  // 	languageSlug: "flyer-pricing",
  // 	permissionName: "flyer-pricing-access",
  // 	isAllowed: true,
  // 	submenu: [
  // 		{
  // 			name: "Flat Flyer Pricing",
  // 			route: "/flat-flyer-pricing",
  // 			slug: "flat-flyer-pricing",
  // 			languageSlug: "flat-flyer-pricing",
  // 			isAllowed: true,
  // 			permissionName: "flat-flyer-pricing-access",
  // 		},
  // 	],
  // },
  {
    name: "Flyer Pricing",
    route: "flyer-pricing-import",
    icon: "flyer-pricing",
    slug: "flyer-pricing-import",
    languageSlug: "flyer-pricing",
    permissionName: "flyer-pricing-access",
    isAllowed: true,
    submenu: [
      {
        name: "Flat Flyer Pricing",
        route: "flat-flyer-pricing",
        slug: "flat-flyer-pricing",
        languageSlug: "flat-flyer-pricing",
        isAllowed: true,
        permissionName: "flat-flyer-pricing-access",
      },
    ],
  },
  {
    name: "Active Promotions",
    route: "active-promotion",
    icon: "active-promotion",
    slug: "active-promotion",
    languageSlug: "active-promotions",
    isAllowed: true,
    permissionName: "active-promotion-access",
  },
  {
    name: "History",
    route: "pricing-history",
    icon: "history",
    slug: "pricing-history",
    languageSlug: "pricing-history",
    isAllowed: true,
  },
];

const tagManagerMenu = [
  {
    name: "Tag Manager",
    route: "tag-manager",
    icon: "tag-manager",
    slug: "tag-manager",
    languageSlug: "tag-manager",
    permissionName: "tag-manager-access",
    isAllowed: true,
  },
  {
    name: "History",
    route: "tag-history",
    icon: "history",
    slug: "tag-history",
    languageSlug: "tag-history",
    isAllowed: true,
  },
];

const userManagerMenu = [
  {
    name: "All Users",
    route: "users-list",
    icon: "users",
    slug: "users-list",
    isAllowed: true,
  },
  {
    name: "Add New User",
    route: "create-users",
    icon: "add-user",
    slug: "create-users",
    isAllowed: true,
  },
  {
    name: "Permission",
    route: "user-permissions",
    icon: "permissions",
    slug: "users-permission",
    isAllowed: true,
  },
];

const siteManagerMenu = [
  {
    name: "All Sites",
    route: "sites-list",
    icon: "stores",
    slug: "sites-list",
    isAllowed: true,
  },
  {
    name: "Add New Site",
    route: "create-site",
    icon: "add-store",
    slug: "sites-create",
    isAllowed: true,
  },
  {
  	name: "Settings",
  	route: "sites-settings",
  	icon: "settings",
  	slug: "sites-setting",
  	isAllowed: true,
  },
  // {
  // 	name: "Site Analytics",
  // 	route: "/admin/sites/analytics",
  // 	icon: "store-analytics",
  // 	slug: "sites-analytics",
  // 	isAllowed: true,
  // },
];

const adminSettingMenu = [
  {
    name: "Translations",
    route: "translations",
    icon: "globe",
    slug: "translations",
    isAllowed: true,
  },
];

const siteVendorCategoryMenu = [
  {
    name: "Vendor Categories List",
    route: "site-vendor-categories",
    icon: "stores",
    slug: "site-vendor-categories",
    isAllowed: true,
  },
];

export default Object.freeze({
  enableProductGroupBoxStyling: false,
  enableAddProductGroup: false,
  navigationMenu: [
    {
      module: "pricing",
      title: "Pricing Manager",
      routes: pricingManagerMenu,
      id: "pricingMenu",
      languageSlug: "pricing-manager",
      isAllowed: true,
    },
    {
      module: "tag",
      title: "Tag Manager",
      routes: tagManagerMenu,
      id: "tagMenu",
      languageSlug: "tag-manager",
      isAllowed: true,
    },
  ],
  helpRoute: {
    name: "Do you need help?",
    route: "/help",
    icon: "help",
    slug: "help",
    languageSlug: "need-help",
  },
  adminNavigationMenu: [
    {
      module: "users",
      title: "User Manager",
      routes: userManagerMenu,
      id: "userMenu",
      isAllowed: true,
    },
    {
      module: "sites",
      title: "Site Manager",
      routes: siteManagerMenu,
      id: "siteMenu",
      isAllowed: true,
    },
    {
      module: "settings",
      title: "Setting",
      routes: adminSettingMenu,
      id: "settingMenu",
      isAllowed: true,
    },
    {
      module: "siteVendorCategories",
      title: "Site Vendor Categories",
      routes: siteVendorCategoryMenu,
      id: "siteVendorCategoriesMenu",
      isAllowed: true,
    },
  ],
  path: {
    //paths for user login start
    login: "pricing-manager/api/user",
    //paths for user login end

    //paths for get translations while user loggedin/change the language from top navigation start
    getLanguages: `pricing-manager/api/${process.env.VUE_APP_VERSION}/language`,
    getTranslations: `pricing-manager/api/${process.env.VUE_APP_VERSION}/GetLanguageTranslation`,
    //paths for get translations while user loggedin/change the language from top navigation end

    //paths for filter used in multiple forms
    getCategory: `pricing-manager/api/${process.env.VUE_APP_VERSION}/category`,
    getVendor: `pricing-manager/api/${process.env.VUE_APP_VERSION}/vendor`,
    getProductTag: `pricing-manager/api/${process.env.VUE_APP_VERSION}/producttags`,
    getFilteredProducts: `pricing-manager/api/${process.env.VUE_APP_VERSION}/everyday`,
    getSkuVmn: `pricing-manager/api/${process.env.VUE_APP_VERSION}/skuvendormodelnumberlist`,
    getVmn: `pricing-manager/api/${process.env.VUE_APP_VERSION}/vendormodelnumberlist`,
    //paths for filter used in multiple end

    getDrsgSites: `pricing-manager/api/${process.env.VUE_APP_VERSION}/drsgsites`,
    postPricingManager: `pricing-manager/api/${process.env.VUE_APP_VERSION}/finalpricingmanagersubmit`,
    flatflyervalidate: `pricing-manager/api/${process.env.VUE_APP_VERSION}/flatflyervalidate`,
    frontendLogin: "login",
    frontendEverydayPricing: "everyday-pricing",

    getHistory: `pricing-manager/api/${process.env.VUE_APP_VERSION}/getpricingmanagerhistory`,
    editFromHistory: `pricing-manager/api/${process.env.VUE_APP_VERSION}/pricingmanager`,
    updateStatus: `pricing-manager/api/${process.env.VUE_APP_VERSION}/updatestatus`,
    exportPreviewData: `pricing-manager/api/${process.env.VUE_APP_VERSION}/exportpreviewdata`,
    setUserManual: `pricing-manager/api/${process.env.VUE_APP_VERSION}/UserManual`,
    getTodayActivePrice: `pricing-manager/api/${process.env.VUE_APP_VERSION}/Getdrsgproductpricing`,
    tm_FilteredProducts: `pricing-manager/api/${process.env.VUE_APP_VERSION}/producttagmanager`,
    tm_postTagManager: `pricing-manager/api/${process.env.VUE_APP_VERSION}/finalproducttagmanagersubmit`,
    tm_getHistory: `pricing-manager/api/${process.env.VUE_APP_VERSION}/getproducttagmanagerhistory`,
    tm_editFromHistory: `pricing-manager/api/${process.env.VUE_APP_VERSION}/producttagmanager`,
    tm_exportPreviewData: `pricing-manager/api/${process.env.VUE_APP_VERSION}/exportproducttagDataforhistory`,
    tm_updateStatus: `pricing-manager/api/${process.env.VUE_APP_VERSION}/producttagupdatestatus`,
    tm_getTags: `pricing-manager/api/${process.env.VUE_APP_VERSION}/getusersitetag`,
    tm_postNewTag: `pricing-manager/api/${process.env.VUE_APP_VERSION}/saveusersitetag`,
    tm_deleteTag: `pricing-manager/api/${process.env.VUE_APP_VERSION}/usersitetagmasterremove`,

    //paths for exception trigger while any error start
    exception: `pricing-manager/api/${process.env.VUE_APP_VERSION}/Exception`,
    //paths for exception trigger while any error end

    //paths for status codes start
    getStatusCode: `pricing-manager/api/${process.env.VUE_APP_VERSION}/getcodertatusmaster`,
    //paths for status codes end

    getDetailUsersList: `pricing-manager/api/${process.env.VUE_APP_VERSION}/getuserdetailslist`,

    manageUsers: `pricing-manager/api/${process.env.VUE_APP_VERSION}/manageusers`,
    getUserDetail: `pricing-manager/api/${process.env.VUE_APP_VERSION}/getuserdetail`,
    updateUserStatus: `pricing-manager/api/${process.env.VUE_APP_VERSION}/useractiveinactivestatus`,

    userStoreMapping: `pricing-manager/api/${process.env.VUE_APP_VERSION}/userstoremapping`,
    getmodulemasterlist: `pricing-manager/api/${process.env.VUE_APP_VERSION}/getmodulemasterlist`,
    getUsermodulerPermissionById: `pricing-manager/api/${process.env.VUE_APP_VERSION}/Getusermodulerpermissionbyid`,
    userModuleMapping: `pricing-manager/api/${process.env.VUE_APP_VERSION}/usermodulemapping`,
    updateUserTypes: `pricing-manager/api/${process.env.VUE_APP_VERSION}/updateusertypes`,
    importCsvFlatFlyerDataVerify: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/ImportCsvFlatFlyerDataVerify`,
    importCsvFlatFlyerDataValidate: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/ImportCsvFlatFlyerDataValidate`,

    changePassword: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/changepassword`,

    //paths for user site managment start
    getStoresList: `pricing-manager/api/${process.env.VUE_APP_VERSION}/getstorelist`,
    getNewSiteId: `pricing-manager/api/${process.env.VUE_APP_VERSION}/getsiteid`,
    getDetailStoresList: `pricing-manager/api/${process.env.VUE_APP_VERSION}/getShopifyGQLSites`,
    manageSite: `pricing-manager/api/${process.env.VUE_APP_VERSION}/addstore`,
    getStorePermission: `pricing-manager/api/${process.env.VUE_APP_VERSION}/getstorepermissionbysiteid`,
    updateStorePermission: `pricing-manager/api/${process.env.VUE_APP_VERSION}/updatestorepermission`,
    updateStoreStatus: `pricing-manager/api/${process.env.VUE_APP_VERSION}/putstoreactiveinactive`,
    getgraphqlpathversion: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/getgraphqlpathversion`,
    graphgqlsiteverification: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/graphgqlsiteverification`,
    //paths for user site managment end

    //paths for user translation management start
    getTranslationModules: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/GetModules`,
    getTranslationFields: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/GetFields`,
    getTranslationFieldDetails: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/getFieldDetails`,
    updateTranslationFieldDetails: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/ManageFieldDetails`,
    //paths for user translation management end

    //paths for user dashboard data start
    getDashboardProductsCount: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/getDashboardProductsCount`,
    getProductsActivity: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/getProductsActivity`,
    getActivityFeedDetails: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/getActivityFeedDetails`,
    //paths for user dashboard data end

    //paths for version history start
    getVersionHistory: `pricing-manager/api/${process.env.VUE_APP_VERSION}/GetVersionHistory`,
    manageVersionHistory: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/ManageVersionHistory`,
    updateNotificationSeen: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/UpdateNotificationSeen`,
    //paths for version history end

    //paths for site vendor category start
    getSiteSettings: `pricing-manager/api/${process.env.VUE_APP_VERSION}/GetSiteSettings`,
    manageSiteSettings: `pricing-manager/api/${process.env.VUE_APP_VERSION}/ManageSiteSettings`,
    getSideVendorCategory: `pricing-manager/api/${process.env.VUE_APP_VERSION}/getSiteVendorCategoryMapping`,
    manageSiteVendorCategoryMapping: `pricing-manager/api/${process.env.VUE_APP_VERSION}/ManageSiteVendorCategoryMapping`,
    //paths for site vendor end

    importCsvDataVerify: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/ImportCsvDataVerify`, //path for data verification for the imported csv
    importCsvSubmit: `/pricing-manager/api/${process.env.VUE_APP_VERSION}/ImportCsvSubmit`, //path for data submit for the imported csv
  },
  tooltip: {
    priceEnding:
      "Price ending will allow the end number of pricing to be the number that you set up.",
    marginFactor:
      'The promotional retail price will be calculated against the Product cost automatically with the following formula: product cost*Margin Factor=New Retail Price<br/><br/><a href="">Learn More</a>',
    startDate:
      "Start Date will allow pricing to push to the website on the date you set up.",
    endDate:
      "End Date will allow pricing to stop to the website on the date you set up.",
    andOrTooltipCategory:
      "'Or' will allow selecting products that have at least one category. 'And' will allow selecting products that have all categories.",
    andOrTooltipProductTags:
      "'Or' will allow selecting products that have at least one product tag. 'And' will allow selecting products that have all product tags.",
    discount:
      "Discount will allow pricing to be discounted with the percentage or $ that you set up.",
    chooseProductsTooltip:
      "Create multiple product groups to separate the combination of filters for better organization.",
    customTagName:
      "Create a unique descriptive tag name without any spaces or special characters. (Eg: d123_boxingday) Site ID will be added as a prefix to the tag for identification.",
    customTagNameDRSG:
      "Select a tag to assign for your product selection or contact your DRSG Admin to create a new tag for your account.",
    comparedatPriceTooltip: "This is a regular price of the product.",
    priceTooltip:
      "Price will be used as a Flat Price. Price should be lower than regular price.",
  },
  messages: {
    loginFailed: "Login Failed",
    unauthorizedUser:
      "Sorry! Your token has expired. Please login again to continue",
    priceEndingEnableMessage: "I want to change the price ending.",
    nameRequired: "Name is required",
    siteRequired: "Site is required",
    productGroupRequired: "Choose Filters for Product Group",
    productGroupsRequired: "Choose Filters for Product Group: ",
    marginFactorRequired: "Margin Factor is required to be greater than 1.0",
    discountRequired: "Discount is required to be greater than 0",
    maxDiscountExceeds: "Discount can not be greater than 100",
    priceEndingRequired: "Price Ending is required",
    maxPriceEndingExceeds:
      "Add positive integer or decimal number between 0 to 99",
    startDateRequired: "Start Date is required",
    minimumDateError: "Date can't be older then current date",
    minimumEndDateError: "Date can't be older then start date",
    previewProductsLoadFailed: "No products found.",
    connectionError: "Connection Error, Please seek DRSG admin for assistance.",
    historyLoadFailed: "No pricing found.",
    editableColumn: "You can edit ‘Compare at price’, and ‘Price’.",

    noProductsFound: "No matching products found.",

    tagTitleRequired: "Description Title is required",
    tagNameRequired: "Tag name is required",
    tagAlreadyExists: "This tag already exists.",
    tagAlreadyUsedInForm: "Form has been created for this tag.",
    tagAlreadyUsed: "This tag already in use.",
    limitExceeds: "You reached maximum limit of Tags that you can create.",

    firstNameRequired: "First name is required",
    lastNameRequired: "Last name is required",
    emailRequired: "Email is required",
    siteAccessRequired: "Site access is required",
    roleTypeRequired: "Role type is required",
    currentPasswordRequired: "Current password is required",
    newPasswordRequired: "New password is required",
    confirmPasswordRequired: "Confirm password is required",
    confirmPasswordNotMatch:
      "New password and Confirm password needs to be same",

    profileUpdated: "Profile updated successfully",
    passwordUpdated: "Password has been successfully changed.",
    incorrectOldPassword: "Incorrect old password. Please retry.",
    samePassword:
      "Existing and new password could not be same. Please try new password.",

    duplicateEmail:
      "This email is already used. Please try with another email.",
    permissionUpdated: "Permission updated successfully",
    csvFileRequired: "CSV file is required",
    invalidCsvFileType:
      "File extension not supported. Allowed file with .csv extension",
    csvFileUploadWarning:
      "Beware that this action will push the products to multiple sites overriding current price rules set up by the individual sites. Do you want to continue?",
    invalidCSV:
      "Uploaded file is not same as sample file. Please edit and upload the file",
    invalidCSVSites: "Selected site(s) and CSV site(s) does not match",
    invalidCSVData: "Failed - CompareAtPrice cannot be lower than PromoPrice",

    somethingwentWrong: "Something went. Please try again",

    enterValidUrl: "Enter a valid URL",
    enterValidEmail: "Enter a valid email",
    shopifyShopURLRequired: "Shopify shop URL is required",
    businessNameRequired: "Business name is required",
    memberNameRequired: "Member name is required",
    memberShipIdRequired: "Membership Id is required",
    webSiteDomainURLRequired: "Website domain URL is required",
    primaryContactNameRequired: "Primary contact name is required",
    primaryContactEmailRequired: "Primary contact email is required",
    apikeyRequired: "API Key is required",
    secretKeyRequired: "Secret Key is required",
    sharedSecretKeyRequired: "Shared Secret Key is required",
    adminGraphQLPathRequired: "API Version Key is required",
    invalidAPIKeys:
      "Provided credentials are invalid. Please verify and enter again.",
    invalidPublicationAccessAPIKeys:
      "This private app does not have the publication rights. Please enable the access and try again.",

    duplicateUrl: "The site already exists with shope URL.",
    duplicateName: "The site already exists with business name.",

    valueRequired: "This field is required.",

    vendorCategoryUpdated: "Vendor categories updated successfully.",
  },
  filterConditions: {
    OR: "OR",
    AND: "AND",
  },
  responseStatus: {
    Success: 200,
    Unauthorized: 401,
    NotFound: 404,
    Accepted: 202,
    BadRequest: 400,
    InternalServerError: 500,
    TooManyRequests: 429,
    RequestTimeout: 408,
    Forbidden: 403,
    ServiceUnavailable: 503,
  },
  filterErrorObject: {
    vendorFilter: false,
    categoriesFilter: false,
    productTagFilter: false,
    vmnFilter: false,
    skuFilter: false,
  },
  productGroupObject: {
    groupId: "1",
    vendor: [],
    category: [],
    productTags: [],
    productVendorModel: [],
    productSKU: [],
  },
  userType: {
    drsgUser: 1,
    tdgUser: 2,
    adminUser: 3,
  },
  dateFormat: "DD/MM/YYYY",
  discountType: {
    percentageOff: "0",
    priceOff: "1",
    flatOff: "2",
  },
  formConfig: {
    priceEndingNull: "0",
    priceEndingNullValue: 2,
    priceEndingDisableValue: 1,
    defaultPriceEnding2: "00",
  },
  formPriority: {
    "everyday-pricing": 3,
    "promotional-pricing": 2,
    "flyer-pricing": 1,
    "flat-flyer-pricing": 1,
  },
  mapRouteToForm: {
    // 3: "everyday-pricing",
    3: {
      1: "everyday-pricing",
      3: "everyday-pricing-import",
    },
    // 2: "promotional-pricing",
    2: {
      1: "promotional-pricing",
      3: "promotional-pricing-import",
    },
    1: {
      1: "flyer-pricing",
      2: "flat-flyer-pricing",
      3: "flyer-pricing-import",
    },
  },
  tableHeaders: {
    "everyday-pricing": [
      {
        key: "products",
        label: "Products",
        tdClass: "filtered-products",
        thClass: ["text-nowrap", "pl-0"],
      },
      {
        key: "pricingManagerId",
        label: "Pricing Manager Id",
        thClass: ["text-nowrap"],
      },
      { key: "sku", label: "SKU", thClass: "text-nowrap" },
      { key: "vmn", label: "VMN", thClass: "text-nowrap" },
      { key: "everydayCost", label: "Cost", thClass: "text-nowrap" },
      { key: "regularPrice", label: "Price", thClass: "text-nowrap" },
    ],
    "filtered-everyday-pricing-import": [
      {
        key: "products",
        label: "Products",
        tdClass: "filtered-products",
        thClass: ["text-nowrap", "pl-0"],
      },
      { key: "sku", label: "SKU", thClass: "text-nowrap" },
      { key: "vmn", label: "VMN", thClass: "text-nowrap" },
      { key: "everydayCost", label: "Cost", thClass: "text-nowrap" },
    ],
    "everyday-pricing-import": [
      {
        key: "products",
        label: "Products",
        tdClass: "filtered-products",
        thClass: ["text-nowrap", "pl-0"],
      },
      {
        key: "siteName",
        label: "Site Name",
        thClass: "text-nowrap",
        tdClass: ["text-wrap"],
      },
      { key: "sku", label: "SKU", thClass: "text-nowrap" },
      { key: "vmn", label: "VMN", thClass: "text-nowrap" },
      { key: "everydayImportCost", label: "Cost", thClass: "text-nowrap" },
      { key: "everydayImportPrice", label: "Price", thClass: "text-nowrap" },
      { key: "everydayImportStatus", label: "", thClass: "text-nowrap" },
    ],
    "everyday-pricing-import-history": [
      {
        key: "products",
        label: "Products",
        tdClass: "filtered-products",
        thClass: ["text-nowrap", "pl-0"],
      },
      {
        key: "siteName",
        label: "Site Name",
        thClass: "text-nowrap",
        tdClass: ["text-wrap"],
      },
      { key: "sku", label: "SKU", thClass: "text-nowrap" },
      { key: "vnm", label: "VMN", thClass: "text-nowrap" },
      { key: "cost", label: "Cost", thClass: "text-nowrap" },
      { key: "price", label: "Price", thClass: "text-nowrap" },
      {
        key: "lastModified",
        label: "Last Modified Date",
        thClass: "text-nowrap",
      },
    ],
    "promotional-pricing": [
      {
        key: "products",
        label: "Products",
        tdClass: "filtered-products",
        thClass: ["text-nowrap", "pl-0"],
      },
      {
        key: "pricingManagerId",
        label: "Pricing Manager Id",
        thClass: ["text-nowrap"],
      },
      { key: "sku", label: "SKU", thClass: "text-nowrap" },
      { key: "vmn", label: "VMN", thClass: "text-nowrap" },
      { key: "regularPrice", label: "Regular Price", thClass: "text-nowrap" },
      { key: "promoPrice", label: "Promotional Price", thClass: "text-nowrap" },
    ],
    "promotional-pricing-import": [
      {
        key: "products",
        label: "Products",
        tdClass: "filtered-products",
        thClass: ["text-nowrap", "pl-0"],
      },
      {
        key: "siteName",
        label: "Site Name",
        thClass: "text-nowrap",
        tdClass: ["text-wrap"],
      },
      { key: "sku", label: "SKU", thClass: "text-nowrap" },
      { key: "vmn", label: "VMN", thClass: "text-nowrap" },
      { key: "promotionalImportCost", label: "Cost", thClass: "text-nowrap" },
      { key: "promotionalImportPrice", label: "Price", thClass: "text-nowrap" },
      { key: "promotionalImportStatus", label: "", thClass: "text-nowrap" },
    ],
    "promotional-pricing-import-history": [
      {
        key: "products",
        label: "Products",
        tdClass: "filtered-products",
        thClass: ["text-nowrap", "pl-0"],
      },
      {
        key: "siteName",
        label: "Site Name",
        thClass: "text-nowrap",
        tdClass: ["text-wrap"],
      },
      { key: "sku", label: "SKU", thClass: "text-nowrap" },
      { key: "vnm", label: "VMN", thClass: "text-nowrap" },
      { key: "cost", label: "Cost", thClass: "text-nowrap" },
      { key: "price", label: "Price", thClass: "text-nowrap" },
      {
        key: "lastModified",
        label: "Last Modified Date",
        thClass: "text-nowrap",
      },
    ],
    "flyer-pricing": [
      {
        key: "products",
        label: "Products",
        tdClass: "filtered-products",
        thClass: ["text-nowrap", "pl-0"],
      },
      { key: "sku", label: "SKU", thClass: "text-nowrap" },
      { key: "vmn", label: "VMN", thClass: "text-nowrap" },
      { key: "regularPrice", label: "Regular Price", thClass: "text-nowrap" },
      { key: "replacementCost", label: "Flyer Price", thClass: "text-nowrap" },
    ],
    "flyer-pricing-import": [
      {
        key: "products",
        label: "Products",
        tdClass: "filtered-products",
        thClass: ["text-nowrap", "pl-0"],
      },
      {
        key: "siteName",
        label: "Site Name",
        thClass: "text-nowrap",
        tdClass: ["text-wrap"],
      },
      { key: "sku", label: "SKU", thClass: "text-nowrap" },
      { key: "vmn", label: "VMN", thClass: "text-nowrap" },
      { key: "flyerImportCost", label: "Cost", thClass: "text-nowrap" },
      { key: "flyerImportPrice", label: "Price", thClass: "text-nowrap" },
      { key: "flyerImportStatus", label: "", thClass: "text-nowrap" },
    ],

    "flyer-pricing-import-history": [
      {
        key: "products",
        label: "Products",
        tdClass: "filtered-products",
        thClass: ["text-nowrap", "pl-0"],
      },
      {
        key: "siteName",
        label: "Site Name",
        thClass: "text-nowrap",
        tdClass: ["text-wrap"],
      },
      { key: "sku", label: "SKU", thClass: "text-nowrap" },
      { key: "vnm", label: "VMN", thClass: "text-nowrap" },
      { key: "cost", label: "Cost", thClass: "text-nowrap" },
      { key: "price", label: "Price", thClass: "text-nowrap" },
      {
        key: "lastModified",
        label: "Last Modified Date",
        thClass: "text-nowrap",
      },
    ],
    "flat-flyer-pricing": [
      {
        key: "products",
        label: "Products",
        tdClass: "filtered-products",
        thClass: ["text-nowrap", "pl-0"],
      },
      {
        key: "pricingManagerId",
        label: "Pricing Manager Id",
        thClass: ["text-nowrap"],
      },
      { key: "sku", label: "SKU", thClass: "text-nowrap" },
      { key: "vmn", label: "VMN", thClass: "text-nowrap" },
      {
        key: "flatFlyerRegularPrice",
        label: "Compare At Price",
        thClass: "text-nowrap",
      },
      { key: "flatFlyerPrice", label: "Price", thClass: "text-nowrap" },
    ],

    "active-promotion": [
      {
        key: "products",
        label: "Product Name",
        tdClass: "filtered-products",
        thClass: ["text-nowrap"],
        // tdClass: ["text-wrap"],
      },
      {
        key: "pricingManagerId",
        label: "Pricing Manager ID",
        thClass: "text-nowrap",
      },
      { key: "siteName", label: "Site Name", thClass: "text-nowrap" },
      { key: "sku", label: "SKU", thClass: "text-nowrap" },
      { key: "vmn", label: "VMN", thClass: "text-nowrap" },
      { key: "regularPrice", label: "Regular Price", thClass: "text-nowrap" },
      { key: "promoPrice", label: "Promotional Price", thClass: "text-nowrap" },
    ],
    "pricing-history": [
      {
        key: "pricingHistoryName",
        label: "Pricing name",
        thClass: "text-nowrap",
        tdClass: ["text-wrap"],
      },
      {
        key: "pricingManagerId",
        label: "Pricing Manager Id",
        thClass: ["text-nowrap"],
      },
      {
        key: "siteName",
        label: "Site Name",
        thClass: "text-nowrap",
        tdClass: ["text-wrap"],
      },
      {
        key: "pricingHistoryType",
        label: "Pricing type",
        thClass: "text-nowrap",
      },
      { key: "pricingHistoryStatus", label: "Status", thClass: "text-nowrap" },
      {
        key: "pricingHistoryLastEdit",
        label: "Last edit time",
        thClass: "text-nowrap",
      },
      { key: "historyAction", label: "", thClass: "text-nowrap" },
    ],
    "tag-manager": [
      {
        key: "products",
        label: "Products",
        tdClass: "filtered-products",
        thClass: ["text-nowrap", "pl-0"],
      },
      {
        key: "productTagManagerId",
        label: "Tag Manager ID",
        thClass: "text-nowrap",
      },
      { key: "sku", label: "SKU", thClass: ["text-nowrap"] },
      { key: "vmn", label: "VMN", thClass: ["text-nowrap"] },
    ],
    "tag-history": [
      {
        key: "tagManagerTitle",
        label: "Description Title",
        thClass: "text-nowrap",
        tdClass: ["text-wrap"],
      },
      {
        key: "productTagManagerId",
        label: "Tag Manager ID",
        thClass: "text-nowrap",
      },
      {
        key: "siteName",
        label: "Site Name",
        thClass: "text-nowrap",
        tdClass: ["text-nowrap"],
      },
      { key: "tagName", label: "Tag Name", thClass: "text-nowrap" },
      { key: "tagManagerStatus", label: "Status", thClass: "text-nowrap" },
      {
        key: "tagManagerLastEdit",
        label: "Last edit time",
        thClass: "text-nowrap",
      },
      { key: "historyAction", label: "", thClass: "text-nowrap" },
    ],
    "user-list": [
      { key: "checkbox", label: "", thClass: "text-nowrap" },
      {
        key: "email",
        label: "Email address",
        thClass: "text-nowrap",
      },
      {
        key: "firstName",
        label: "First name",
        thClass: "text-nowrap",
      },
      {
        key: "lastName",
        label: "Last name",
        thClass: "text-nowrap",
      },
      {
        key: "stores",
        label: "Stores",
        width: "25%",
        thClass: "text-nowrap",
        tdClass: ["text-wrap"],
      },
      { key: "role", label: "User Role", thClass: "text-nowrap" },
      { key: "status", label: "Status", thClass: "text-nowrap" },
    ],
    "sites-list": [
      { key: "checkbox", label: "", thClass: "text-nowrap" },
      {
        key: "name",
        label: "Business name",
        width: "15%",
        thClass: "text-nowrap",
      },
      {
        key: "url",
        label: "Website domain",
        width: "15%",
        thClass: "text-nowrap",
      },
      {
        key: "siteId",
        label: "Site ID",
        width: "15%",
        thClass: "text-nowrap",
      },
      { key: "status", label: "Status", thClass: "text-nowrap" },
    ],
    "version-history-list": [
      {
        key: "projectTitle",

        label: "Project Title",

        thClass: "text-nowrap",

        tdClass: ["text-wrap"],
      },
      {
        key: "version",

        label: "Version",

        thClass: "text-nowrap",

        tdClass: ["text-wrap"],
      },

      {
        key: "features",

        label: "Features",

        thClass: "text-nowrap",

        tdClass: ["text-wrap"],
      },

      {
        key: "deploymentDate",

        label: "Release Date",

        thClass: "text-nowrap",

        tdClass: ["text-wrap"],
      },

      {
        key: "language",
        label: "Language",
        thClass: "text-nowrap",
        tdClass: ["text-wrap"],
      },
      { key: "historyAction", label: "", thClass: "text-nowrap" },
    ],
    "dashboard-activity": [
      {
        key: "activityStatus",
        label: "Status",
        thClass: "text-nowrap",
        tdClass: ["text-wrap"],
      },
      {
        key: "activityDescription",
        label: "Activity",
        thClass: "text-nowrap",
        tdClass: ["text-nowrap"],
      },
      {
        key: "activityDate",
        label: "Date",
        thClass: "text-nowrap",
        tdClass: ["text-nowrap"],
      },
      {
        key: "activityDownload",
        label: "Download",
        thClass: ["text-nowrap", "text-right"],
        tdClass: ["text-wrap", "text-right"],
      },
    ],
  },
  exportCSVHeaders: {
    "everyday-pricing": [
      { key: "pricingManagerId", label: "Pricing ManagerID" },
      { key: "pricing", label: "Pricing" },
      { key: "productId", label: "ProductID(SKU)" },
      { key: "products", label: "Product Name" },
      // { key: "sku", label: "SKU" },
      { key: "vmn", label: "Vendor Model Number" },
      { key: "vendor", label: "Vendor" },
      { key: "category", label: "Category" },
      { key: "productTags", label: "Product Tags" },
      { key: "everydayCost", label: "Replacement Cost" },
      { key: "regularPrice", label: "Regular Price" },
    ],
    "everyday-pricing-import": [
      { key: "siteId", label: "SiteId" },
      { key: "siteName", label: "Site Name" },
      { key: "productId", label: "ProductID(SKU)" },
      { key: "products", label: "Product Name" },
      // { key: "sku", label: "SKU" },
      { key: "vmn", label: "Vendor Model Number" },
      { key: "compareAtPrice", label: "Cost" },
      { key: "promoPrice", label: "Price" },
      { key: "flyerImportStatus", label: "Status" },
    ],
    "everyday-pricing-import-history": [
      { key: "products", label: "Products" },
      { key: "siteName", label: "Site Name" },
      { key: "sku", label: "SKU" },
      { key: "vnm", label: "VMN" },
      { key: "cost", label: "Cost" },
      { key: "price", label: "Price" },
      { key: "lastModified", label: "Last Modified Date" },
    ],
    // "everyday-pricing-import": [
    //   { key: "siteId", label: "SiteId" },
    //   { key: "vendorModelNumber", label: "Vendor Model Number" },
    //   { key: "replacementCost", label: "Replacement Cost" },
    //   { key: "regularPrice", label: "Regular Price" },
    // ],
    // "everyday-pricing-import-filter": [
    //   { key: "siteId", label: "SiteId" },
    //   { key: "vendorModelNumber", label: "Vendor Model Number" },
    //   { key: "replacementCost", label: "Replacement Cost" },
    //   { key: "regularPrice", label: "Regular Price" },
    // ],
    "filtered-everyday-pricing-import": [
      { key: "siteId", label: "Site Id" },
      { key: "sku", label: "SKU" },
      { key: "vendorModelNumber", label: "Vendor Model Number" },
      { key: "replacementCost", label: "Replacement Cost" },
      { key: "price", label: "Price" },
    ],
    "promotional-pricing": [
      { key: "pricingManagerId", label: "Pricing ManagerID" },
      { key: "pricing", label: "Pricing" },
      { key: "productId", label: "ProductID(SKU)" },
      { key: "products", label: "Product Name" },
      { key: "vendor", label: "Vendor" },
      { key: "category", label: "Category" },
      { key: "productTags", label: "Product Tags" },
      { key: "vmn", label: "Vendor Model Number" },
      { key: "sku", label: "SKU" },
      { key: "regularPrice", label: "Regular Price" },
      { key: "promoPrice", label: "Promotional Price" },
    ],
    "promotional-pricing-import-template": [
      { key: "siteId", label: "Site Id" },
      { key: "sku", label: "SKU" },
      { key: "vendorModelNumber", label: "Vendor Model Number" },
      { key: "compareAtPrice", label: "Compare at Price" },
      { key: "price", label: "Price" },
    ],
    "flyer-pricing": [
      { key: "pricingManagerId", label: "Pricing ManagerID" },
      {
        key: "products",
        label: "Products",
      },
      { key: "sku", label: "SKU" },
      { key: "vmn", label: "Vendor Model Number" },
      { key: "regularPrice", label: "Regular Price" },
      { key: "replacementCost", label: "Flyer Price" },
    ],
    "flyer-pricing-import": [
      { key: "siteId", label: "SiteId" },
      { key: "siteName", label: "Site Name" },
      { key: "productId", label: "ProductID(SKU)" },
      { key: "products", label: "Product Name" },
      // { key: "sku", label: "SKU" },
      { key: "vmn", label: "Vendor Model Number" },
      { key: "compareAtPrice", label: "Cost" },
      { key: "promoPrice", label: "Price" },
      { key: "flyerImportStatus", label: "Status" },
    ],
    "promotional-pricing-import": [
      { key: "siteId", label: "SiteId" },
      { key: "siteName", label: "Site Name" },
      { key: "productId", label: "ProductID(SKU)" },
      { key: "products", label: "Product Name" },
      // { key: "sku", label: "SKU" },
      { key: "vmn", label: "Vendor Model Number" },
      { key: "compareAtPrice", label: "Cost" },
      { key: "promoPrice", label: "Price" },
      { key: "flyerImportStatus", label: "Status" },
    ],
    "flyer-pricing-import-history": [
      { key: "products", label: "Products" },
      { key: "siteName", label: "Site Name" },
      { key: "sku", label: "SKU" },
      { key: "vnm", label: "VMN" },
      { key: "cost", label: "Cost" },
      { key: "price", label: "Price" },
      { key: "lastModified", label: "Last Modified Date" },
    ],
    "promotional-pricing-import-history": [
      { key: "products", label: "Products" },
      { key: "siteName", label: "Site Name" },
      { key: "sku", label: "SKU" },
      { key: "vnm", label: "VMN" },
      { key: "cost", label: "Cost" },
      { key: "price", label: "Price" },
      { key: "lastModified", label: "Last Modified Date" },
    ],
    "flyer-pricing-import-template": [
      { key: "siteId", label: "Site Id" },
      { key: "sku", label: "SKU"},
      { key: "vendorModelNumber", label: "Vendor Model Number" },
      { key: "compareAtPrice", label: "Compare at Price" },
      { key: "price", label: "Price" },
    ],
    "flat-flyer-pricing": [
      { key: "pricingManagerId", label: "Pricing ManagerID" },
      { key: "pricing", label: "Pricing" },
      { key: "productId", label: "ProductID(SKU)" },
      { key: "products", label: "Product Name" },
      { key: "vendor", label: "Vendor" },
      { key: "category", label: "Category" },
      { key: "productTags", label: "Product Tags" },
      { key: "vmn", label: "Vendor Model Number" },
      { key: "sku", label: "SKU" },
      { key: "regularPrice", label: "Regular Price" },
      { key: "flyerPrice", label: "Promotional Price" },
    ],
    "active-promotion": [
      { key: "pricingManagerId", label: "Pricing ManagerID" },
      { key: "siteId", label: "SiteId" },
      { key: "siteName", label: "Site Name" },
      { key: "products", label: "Product Name" },
      { key: "vmn", label: "Vendor Model Number" },
      { key: "productId", label: "SKU" },
      { key: "regularPrice", label: "Regular Price" },
      { key: "promoPrice", label: "Promotional Price" },
    ],
    "tag-manager": [
      { key: "tagManagerId", label: "Tag ManagerID" },
      {
        key: "products",
        label: "Products",
      },
      { key: "sku", label: "SKU" },
      { key: "vmn", label: "Vendor Model Number" },
    ],
    "dashboard-activity": [
      {
        key: "activityProductId",
        label: "Product Id",
      },
      {
        key: "activityDate",
        label: "Date",
      },
      {
        key: "activityStatus",
        label: "Status",
      },
    ],
  },
  searchDebounceTime: 1500,
  pricingManagerConfig: {
    activePomotionPerPage: 20,
    pricingHistoryPerPage: 20,
    isPushPricing: false,
    statuOption: [
      { text: "Active", value: 1 },
      { text: "Inactive", value: 0 },
      { text: "Scheduled", value: 2 },
    ],
    pricingTypeOption: [
      { text: "Everyday Pricing", value: "EverydayPricing" },
      { text: "Promotional Pricing", value: "PromoPricing" },
      { text: "Flyer Pricing", value: "FlyerPricing" },
    ],
  },
  tagManagerConfig: {
    perPage: 20,
    maxTagLimit: 10,
    alreadyExists: 1,
    limitExceeds: 3,
    alreadyUsed: 4,
    isPushPricing: true,
  },
  userManager: {
    resultPerPage: 20,
    userType: [
      {
        text: "DRSG User",
        value: 1,
      },
      {
        text: "TDG User",
        value: 2,
      },
      {
        text: "Admin User",
        value: 3,
      },
    ],
    modalSetting: {
      changeRole: {
        title: "Change user role",
        description:
          "Are you sure you want change the role of all selected users?",
      },
      activateUser: {
        title: "Active User(s)",
        description: "Are you sure you want activate all selected users?",
      },
      inActiveUser: {
        title: "Inactive User(s)",
        description: "Are you sure you want Inactivate all selected users?",
      },
    },
  },
  siteManager: {
    resultPerPage: 20,
    modalSetting: {
      activateSite: {
        title: "Active Site(s)",
        description: "Are you sure you want activate all selected sites?",
      },
      inActiveSite: {
        title: "Inactive Site(s)",
        description: "Are you sure you want Inactivate all selected sites?",
      },
    },
  },
  statusOptions: [
    {
      text: "Active",
      value: 1,
    },
    {
      text: "Inactive",
      value: 0,
    },
  ],
});
