<template>
  <div
    class="form-success d-flex justify-content-center flex-column align-items-center text-center"
  >
    <i class="font-50" :class="icon"></i>
    <div class="message-detail">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormSuccess",
  props: {
    icon: {
      type: String,
      default: "icon-completed",
    },
  },
};
</script>
