import service from "../service";

const actions = {
  async getAll({ state, commit }, payload) {
    try {
      let sortBy = state.sortBy;
      let sortOrder = state.sortOrder;
      let searchQuery = state.searchQuery;

      commit("setItem", { resource: "gridList", payload: [] });
      let searchObject = {
        orderBy: sortBy,
        orderByDirection: sortOrder,
        userId: payload.userId,
      };
      if (searchQuery) {
        searchObject = { ...searchObject, searchText: searchQuery };
      }
      const results = await service.getAll(searchObject);
      commit("setItem", { resource: "gridList", payload: results });
    } catch (e) {
      state.tblLoader = false;
    }
  },
  async getById({ commit }, id) {
    const result = await service.getById(id);
    commit("setItem", { resource: "user", payload: result[0] });
    return result;
  },
  async manageUser(_, user) {
    const response = await service.manageUser(user);
    return response;
  },
  async deleteUser({ dispatch }, id) {
    await service.deleteUser(id);
    dispatch("getAll", {});
  },

  async updateUserTypes(_, params) {
    await service.updateUserTypes(params);
  },

  async updateStatus(_, params) {
    await service.updateStatus(params);
  },

  async userStoreMapping(_, params) {
    await service.userStoreMapping(params);
  },

  async getModuleMasterList({ commit }, params) {
    try {
      commit("setItem", { resource: "isLoading", payload: true });
      const response = await service.getModuleMasterList(params);
      const userPermissions = await service.getUsermodulerPermissionById(
        params
      );
      commit("setItem", { resource: "isLoading", payload: false });
      return { response: response, userPermissions: userPermissions };
    } catch (error) {
      commit("setItem", { resource: "isLoading", payload: false });
      return { response: [], userPermissions: [] };
    }
  },

  async getUsermodulerPermissionById(_, params) {
    const response = await service.getUsermodulerPermissionById(params);
    return response;
  },

  async userModuleMapping(_, params) {
    const response = await service.userModuleMapping(params);
    return response;
  },
};

export default actions;
