export default function auth({ next, store }) {
  if (!store.getters["auth/isAuthenticated"]) {
    store.commit("setItem", { resource: "isLoading", payload: false });
    store.commit("auth/LogOut");
    return next({
      path: "/login",
    });
  }

  return next();
}
