<template>
  <b-card>
    <b-form @submit.prevent>
      <div class="row border-bottom">
        <div class="col-sm-12 col-md-12 col-xl-4">
          <b-form-group
            id="language-selection"
            label="Language"
            label-for="language-dropdown"
            label-class="form-label required"
          >
            <b-dropdown
              id="language-dropdown"
              :text="selectedLanguageText"
              ref="language-dropdown"
              block
              menu-class="w-100 scrollable"
              no-caret
              lazy
              variant="brand-dropdown"
            >
              <b-dropdown-item
                v-for="(language, index) in languages"
                :key="language.languageCode + '_' + index"
                @click="selectedLanguage = language.languageId"
                :active="language.languageId == selectedLanguage"
                >{{ language.languageName }}</b-dropdown-item
              >
            </b-dropdown>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-12 col-xl-4">
          <b-form-group
            id="module-selection"
            label="Form Module"
            label-for="module-dropdown"
            label-class="form-label required"
          >
            <b-dropdown
              id="module-dropdown"
              :text="selectedModuleTitle"
              ref="module-dropdown"
              block
              menu-class="w-100 scrollable"
              no-caret
              lazy
              variant="brand-dropdown"
            >
              <b-dropdown-item
                v-for="(translationModule, index) in translationModules"
                :key="'translationModule_' + index"
                @click="selectedModule = translationModule.moduleId"
                :active="translationModule.moduleId == selectedModule"
                >{{ translationModule.module }}</b-dropdown-item
              >
            </b-dropdown>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-12 col-xl-4">
          <b-form-group
            id="field-selection"
            label="Field"
            label-for="field-dropdown"
            label-class="form-label required"
          >
            <b-dropdown
              id="field-dropdown"
              :text="selectedFieldTitle"
              ref="field-dropdown"
              block
              menu-class="w-100 scrollable"
              no-caret
              lazy
              variant="brand-dropdown"
              :disabled="selectedModule == 0 || translationFields.length == 0"
            >
              <b-dropdown-item
                v-for="(translationField, index) in translationFields"
                :key="'translationField_' + index"
                @click="selectedField = translationField.fieldId"
                :active="translationField.fieldId == selectedField"
                >{{ translationField.fieldName }}</b-dropdown-item
              >
            </b-dropdown>
          </b-form-group>
        </div>
      </div>

      <template v-if="translationFieldDetails.length">
        <div class="row mt-3">
          <div
            class="col-sm-12 col-md-12 col-xl-6"
            v-for="(v, index) in $v.translationFieldDetails.$each.$iter"
            :key="'translationFieldDetail_' + index"
          >
            <b-form-group
              :label="v.$model.detailsName"
              :label-for="'translationFieldDetail_' + v.$model.fieldDetailsId"
              label-class="form-label required"
            >
              <b-form-input
                v-model="v.$model.filedDetailValue"
                :id="'translationFieldDetail_' + v.$model.fieldDetailsId"
                v-if="v.$model.fieldType == 'string'"
                :class="{ 'is-invalid': v.filedDetailValue.$error }"
              />
              <b-form-textarea
                v-model="v.$model.filedDetailValue"
                :id="'translationFieldDetail_' + v.$model.fieldDetailsId"
                v-else-if="v.$model.fieldType == 'text'"
                :class="{ 'is-invalid': v.filedDetailValue.$error }"
                rows="3"
                max-rows="6"
              />
              <editor
                v-else
                api-key="no-api-key"
                v-model="v.$model.filedDetailValue"
                :init="{
                  height: 200,
                  menubar: false,
                  branding: false,
                  plugins:
                    'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                  toolbar:
                    'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                }"
                :class="{ 'is-invalid': v.filedDetailValue.$error }"
              />

              <b-form-invalid-feedback
                :id="
                  'translationFieldDetail_' +
                  v.$model.fieldDetailsId +
                  '-feedback'
                "
              >
                {{ sytemMessages.valueRequired }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <div class="d-flex flex-row-reverse mt-3">
          <b-button variant="brand-primary" @click="updateTranslations"
            >Update</b-button
          >
        </div>
      </template>
    </b-form>
  </b-card>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Translations",
  data() {
    return {
      selectedLanguage: 0,
      selectedModule: 0,
      selectedField: 0,
      translationFieldDetails: [],
    };
  },
  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters("adminSetting", ["translationModules", "translationFields"]),
    selectedLanguageText() {
      let _selected = this.languages.find(
        (item) => item.languageId == this.selectedLanguage
      );

      if (_selected && Object.keys(_selected).length)
        return _selected.languageName;
      else return "Select";
    },
    selectedModuleTitle() {
      let _selected = this.translationModules.find(
        (item) => item.moduleId == this.selectedModule
      );

      if (_selected && Object.keys(_selected).length) return _selected.module;
      else return "Select";
    },
    selectedFieldTitle() {
      let _selected = this.translationFields.find(
        (item) => item.fieldId == this.selectedField
      );

      if (_selected && Object.keys(_selected).length)
        return _selected.fieldName;
      else return "Select";
    },
  },
  watch: {
    selectedLanguage() {
      this.setItem({ resource: "translationFields", payload: [] });
      this.translationFieldDetails = [];
      this.selectedModule = 0;
      this.selectedField = 0;
    },
    selectedModule(newValue) {
      if (newValue == 0) return;
      this.setItem({ resource: "translationFields", payload: [] });
      this.translationFieldDetails = [];
      this.selectedField = 0;
      this.generalSetItem({ resource: "isLoading", payload: true });
      if (newValue != 0) {
        this.getTranslationFields({
          moduleId: newValue,
          userId: this.loggedUserId,
        })
          .then(() => {
            this.generalSetItem({ resource: "isLoading", payload: false });
          })
          .catch(() => {
            this.generalSetItem({ resource: "isLoading", payload: false });
          });
      }
    },
    selectedField(newValue) {
      if (newValue == 0) return;
      this.generalSetItem({ resource: "isLoading", payload: true });
      this.translationFieldDetails = [];
      if (newValue != 0) {
        this.getTranslationFieldDetails({
          langId: this.selectedLanguage,
          fieldId: newValue,
          userId: this.loggedUserId,
        })
          .then((response) => {
            this.generalSetItem({ resource: "isLoading", payload: false });
            this.translationFieldDetails = response;
          })
          .catch(() => {
            this.generalSetItem({ resource: "isLoading", payload: false });
          });
      }
    },
  },
  methods: {
    ...mapActions(["getLanguages"]),
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    ...mapMutations("adminSetting", ["setItem"]),
    ...mapActions("adminSetting", [
      "getTranslationModules",
      "getTranslationFields",
      "getTranslationFieldDetails",
      "updateTranslationFieldDetails",
    ]),
    updateTranslations() {
      this.$v.translationFieldDetails.$touch();
      if (this.$v.translationFieldDetails.$invalid) return;

      let _translationFieldDetails = this.translationFieldDetails.map(
        (item) => {
          let obj = {
            fieldDetailsId: item.fieldDetailsId,
            detailsName: item.detailsName,
            lngId: String(item.languageId),
            value: item.filedDetailValue,
            fieldType: item.fieldType,
            fieldId: String(item.fieldId),
            slug: item.slug,
          };
          return obj;
        }
      );

      let payload = {
        translationFieldDetails: _translationFieldDetails,
        fieldId: this.selectedField,
        userId: this.loggedUserId,
      };

      this.generalSetItem({ resource: "isLoading", payload: true });
      this.updateTranslationFieldDetails(payload)
        .then(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
  },
  mounted() {
    this.generalSetItem({ resource: "isLoading", payload: true });
    this.getLanguages()
      .then(() => {
        this.getTranslationModules({ userId: this.loggedUserId })
          .then(() => {
            this.generalSetItem({ resource: "isLoading", payload: false });
          })
          .catch(() => {
            this.generalSetItem({ resource: "isLoading", payload: false });
          });
      })
      .catch(() => {
        this.generalSetItem({ resource: "isLoading", payload: false });
      });
  },
  validations: {
    translationFieldDetails: {
      required,
      $each: {
        filedDetailValue: { required },
      },
    },
  },
};
</script>
