<template>
  <div class="user-permission">
    <b-form-group
      label="Select user"
      label-for="select-user-dropdown"
      label-class="form-label required"
    >
      <b-dropdown
        id="select-user-dropdown"
        :text="selectedUserText"
        ref="usersDropdown"
        menu-class="w-100"
        block
        no-caret
        lazy
        class="w-50"
        variant="brand-dropdown"
      >
        <b-dropdown-form @submit.prevent>
          <div class="form-group">
            <b-form-input
              type="search"
              class="nosubmit w-100"
              placeholder="Search"
              v-model="searchText"
            />
          </div>
          <div class="scrollable">
            <b-dropdown-item
              v-for="(user, index) in usersList"
              :key="index"
              @click="form.userId = user.value"
              :active="form.userId == user.value"
            >
              {{ user.text }}
            </b-dropdown-item>
          </div>
        </b-dropdown-form>
      </b-dropdown>

      <b-form-invalid-feedback id="dropdown-form-feedback">
        {{ sytemMessages.siteAccessRequired }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-card class="create-user">
      <b-alert v-model="showAlert" :variant="alertvariant" dismissible>{{
        alertMessage
      }}</b-alert>
      <div v-if="userModules.length" class="row">
        <div
          v-for="(data, index) in userModules"
          :key="index"
          class="col-sm-12 col-md-4"
        >
          <div class="">
            <label for="exampleFormControlInput1" class="form-label">{{
              data.module
            }}</label>
            <div class="d-flex">
              <label class="mr-4">
                <input type="radio" value="1" v-model="modules[data.id]" /> Yes
              </label>
              <label>
                <input type="radio" value="0" v-model="modules[data.id]" /> No
              </label>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row-reverse col-12">
          <b-button @click="handleSave" variant="brand-primary">Save</b-button>
        </div>
      </div>
      <div class="d-flex justify-content-center" v-else>
        <error-message
          message="Please select user to display the permission(s)"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import config from "../../../../../config";
import { forEach, orderBy } from "lodash";
import { required } from "vuelidate/lib/validators";
import ErrorMessage from "../../../../components/ErrorMessage.vue";
const { messages } = config;

export default {
  components: { ErrorMessage },
  name: "UserPermission",
  data() {
    return {
      usersList: [],
      modules: [],
      userModules: [],
      form: {
        userId: 0,
        loginUserId: 0,
        moduleAccessList: [],
      },
      searchText: "",
      showAlert: false,
      alertvariant: "",
      alertMessage: "",
      // permission_selectUser: constant.messages.permission_selectUser
    };
  },
  computed: {
    ...mapGetters("userManager", {
      allUsers: "gridList",
    }),
    selectedUserText() {
      if (this.form.userId == "") return "Select";
      let selectedUser = this.usersList.find(
        (user) => user.value == this.form.userId
      );
      return selectedUser.text;
    },
  },
  watch: {
    "form.userId": function () {
      this.generalSetItem({ resource: "isLoading", payload: true });
      this.setItem({ resource: "userModules", payload: [] });
      this.modules = this.userModules = [];
      this.getModulesByUser()
        .then(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    searchText(newValue) {
      if (this.allUsers.length > 0) {
        let newOptions = [];
        forEach(this.allUsers, (user) => {
          if (
            user.firstName.toLowerCase().includes(newValue.toLowerCase()) ||
            user.lastName.toLowerCase().includes(newValue.toLowerCase()) ||
            newValue == ""
          ) {
            let obj = {
              value: user.userId,
              text: `${user.firstName} ${user.lastName}`,
            };
            newOptions.push(obj);
          }
        });
        this.usersList = newOptions;
      }
    },
    allUsers: {
      handler: function (newValue) {
        if (newValue.length) {
          let users = [];
          forEach(newValue, (user) => {
            users.push({
              value: user.userId,
              text: `${user.firstName} ${user.lastName}`,
            });
          });

          const sortedUsers = orderBy(
            users,
            [(user) => user.text.toLowerCase()],
            ["asc"]
          );
          this.usersList = sortedUsers;
        } else {
          this.usersList = [];
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("userManager", [
      "getAll",
      "getModuleMasterList",
      "userModuleMapping",
    ]),
    ...mapMutations("userManager", ["setItem"]),
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    async getModulesByUser() {
      if (this.form.userId != null) {
        const { response, userPermissions } = await this.getModuleMasterList({
          userId: this.form.userId,
          loginId: this.loggedUserId,
        });
        this.userModules = response;
        let obj = {};
        forEach(this.userModules, (module) => {
          let _findData = userPermissions.find(
            (_data) => _data.moduleId == module.id
          );
          let isAccess = 0;
          if (_findData) {
            isAccess = _findData.isAccess;
          }
          obj[module.id] = isAccess;
        });
        this.modules = obj;
      }
    },
    handleSave() {
      try {
        this.generalSetItem({ resource: "isLoading", payload: true });
        this.form.loginUserId = this.loggedUserId;
        this.form.moduleAccessList = [];
        forEach(this.modules, (val, key) => {
          this.form.moduleAccessList.push({ moduleId: key, isAccess: val });
        });
        this.userModuleMapping(this.form)
          .then((response) => {
            this.showAlert = true;
            if (response == 1) {
              this.alertvariant = "success";
              this.alertMessage = messages.permissionUpdated;
            } else {
              this.alertvariant = "danger";
              this.alertMessage = messages.somethingwentWrong;
            }
            this.generalSetItem({ resource: "isLoading", payload: false });
          })
          .catch(() => {
            this.generalSetItem({ resource: "isLoading", payload: false });
          });
      } catch {
        this.generalSetItem({ resource: "isLoading", payload: false });
      }
    },
  },
  async beforeMount() {
    this.generalSetItem({ resource: "isLoading", payload: true });
    this.setItem({ resource: "searchQuery", payload: "" });
    this.getAll({ userId: this.loggedUserId })
      .then(() => {
        this.generalSetItem({ resource: "isLoading", payload: false });
      })
      .catch(() => {
        this.generalSetItem({ resource: "isLoading", payload: false });
      });
  },
  validations: {
    form: {
      userId: { required },
    },
  },
};
</script>
