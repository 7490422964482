const Index = () => import("./Index");
const Login = () => import("./components/Login");

const authRoute = {
  path: "",
  component: Index,
  children: [
    {
      path: "/login",
      name: "login",
      component: Login,
    },
  ],
};

export default authRoute;
