<template>
  <div id="wrapper" class="wrapper d-flex align-items-stretch w-100">
    <!-- Sidebar -->
    <sidebar :userPanel="!isAdminRoute" />
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column w-100">
      <!-- Topbar -->
      <top-navigation :userPanel="!isAdminRoute" />
      <!-- End of Topbar -->
      <!-- Begin Page Content -->
      <div class="container-fluid px-0 py-0">
        <!-- Main Content -->
        <div class="text-center permission-denied">
          <div class="denied-image">
            <b-img
              src="/media/images/404-notfound.svg"
              class="brand-image"
              alt="Permission Denied"
            />
          </div>
          <div class="text-center">
            <h4>404 - Page not found</h4>
            <p class="mb-0">Page doesn’t exist or can’t be found.</p>
            <p class="mb-0">Make sure the URL is correct.</p>
            <b-button
              @click="$router.go(-1)"
              variant="brand-primary"
              class="mt-3"
              >Go Back</b-button
            >
          </div>
          <!-- // container-fluid px-0 py-0 vh-100 d-flex align-items-center justify-content-center -->
        </div>
        <!-- End of Main Content -->
      </div>
      <!-- /.container-fluid -->
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import TopNavigation from "../components/TopNavigation.vue";

export default {
  name: "PageNotFound",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Page Not Found",
  },
  components: {
    Sidebar,
    TopNavigation,
  },
  computed: {
    isAdminRoute() {
      if (this.$route.path.indexOf("admin") > -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {},
};
</script>
