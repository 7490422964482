const Index = () => import("./index");
const Translations = () => import("./components/Translations");
const VersionHistoryList = () => import("./components/VersionHistoryList");
const VersionHistoryCreate = () => import("./components/VersionHistoryCreate");
const VersionHistoryEdit = () => import("./components/VersionHistoryEdit");

import auth from "@/router/middleware/auth";
// import maintenance from "@/router/middleware/checkMaintenanceMode";
import isAdmin from "@/router/middleware/isAdmin";

const settingsRoute = {
  path: "settings",
  meta: { label: "Settings" },
  component: Index,
  children: [
    {
      path: "translations",
      name: "translations",
      meta: {
        label: "Translations",
        controlLink: "translations",
        module: "settings",
        moduleMenuId: "settingMenu",
        moduleLabel: "Setting",
        middleware: [auth, isAdmin],
      },
      component: Translations,
    },
    {
      path: "version-history-list",
      name: "version-history-list",
      meta: {
        label: "Version History",
        controlLink: "version-history",
        module: "settings",
        moduleMenuId: "settingMenu",
        moduleLabel: "Setting",
        middleware: [auth, isAdmin],
      },
      component: VersionHistoryList,
    },
    {
      path: "version-history-create",
      name: "version-history-create",
      meta: {
        label: "Version History",
        controlLink: "version-history",
        module: "settings",
        moduleMenuId: "settingMenu",
        moduleLabel: "Setting",
        middleware: [auth, isAdmin],
      },
      component: VersionHistoryCreate,
    },
    {
      path: ":id/edit",
      name: "version-history-edit",
      meta: {
        label: "Version History",
        controlLink: "version-history",
        module: "settings",
        moduleMenuId: "settingMenu",
        moduleLabel: "Setting",
        middleware: [auth, isAdmin],
      },
      component: VersionHistoryEdit,
    },
  ],
};

export default settingsRoute;
