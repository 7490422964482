<template>
  <user-form mode="edit" />
</template>

<script>
import Form from "./Form.vue";
export default {
  name: "UsersEdit",
  components: {
    UserForm: Form,
  },
};
</script>
