<template>
  <div class="home">
    <section
      class="d-flex justify-content-between align-items-center mb-3"
      v-if="isVisiblePriceRules"
    >
      <div class="d-flex align-items-center">
        <span class="h7 mb-0 mr-2">Select a site:</span>
        <b-dropdown
          id="select-user-dropdown"
          :text="
            selectedSite == null || selectedSite == ''
              ? 'Site'
              : selectedSite.Name
          "
          ref="siteDropdown"
          block
          no-caret
          lazy
          variant="brand-dropdown"
        >
          <b-dropdown-form @submit.prevent>
            <div class="mb-4">
              <b-form-input
                type="search"
                class="nosubmit w-100"
                placeholder="Search"
                v-model="siteSearchText"
              />
            </div>
            <div class="scrollable">
              <b-dropdown-item
                v-for="(site, index) in sitesList"
                :key="index"
                @click="siteId = site.value"
                :active="selectedSite.SiteId == site.value"
                >{{ site.text }}
              </b-dropdown-item>
            </div>
          </b-dropdown-form>
        </b-dropdown>
      </div>
      <div class="d-flex align-items-center">
        <template v-if="showLastSync"
          ><span class="h7 mb-0 mr-2">Last Sync Time:</span
          ><span class="body-3 mb-0">02:30 AM CST</span></template
        >
      </div>
    </section>
    <b-card
      class="shadow-sm border-0"
      :title="$t('user-dashboard.welcome-header')"
    >
      <b-card-text>
        {{ $t("user-dashboard.welcome-text") }}
      </b-card-text>
      <b-button variant="brand-primary" to="/help">{{
        $t("user-dashboard.welcome-button")
      }}</b-button>
    </b-card>
    <section class="statistics mt-4" v-if="showProducts">
      <h5 class="mb-3">{{ $t("user-dashboard.products-header") }}</h5>
      <ul
        class="list-unstyled statistics-list d-inline-block float-none align-top w-100 mb-0 pb-0"
      >
        <!-- All Data -->
        <li role="button" class="float-left">
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center"
              style="background-color: #f1f1f1; color: #575757"
            >
              <i class="icon-all-products"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">{{ total_products }}</h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.pim-products-all") }}
              </p>
            </div>
          </div>
        </li>
        <li role="button" class="float-left">
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center"
              style="background-color: #f1f1f1; color: #575757"
            >
              <i class="icon-all-promotion-products"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">
                  {{ total_products_on_active_price_rules }}
                </h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.pim-products-active") }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </section>

    <section class="statistics mt-4" v-if="isVisiblePriceRules">
      <h5 class="mb-3">{{ $t("user-dashboard.active-price-rule-header") }}</h5>
      <ul
        class="list-unstyled statistics-list d-inline-block float-none align-top w-100 mb-0 pb-0"
      >
        <!-- All Data -->
        <li
          role="button"
          class="float-left"
          v-if="userModulerPermission['everyday-pricing-access']"
          @click="
            navigateHistory({
              pricingType: 'EverydayPricing',
              pricingStatus: 'all',
              count: all_everyday_pricing,
            })
          "
        >
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center bg-brand-light-yellow brand-dark-yellow"
            >
              <i class="icon-everyday-pricing"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">{{ all_everyday_pricing }}</h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.all-everyday-pricing") }}
              </p>
            </div>
          </div>
        </li>
        <li
          role="button"
          class="float-left"
          v-if="userModulerPermission['promotional-pricing-access']"
          @click="
            navigateHistory({
              pricingType: 'PromoPricing',
              pricingStatus: 'all',
              count: all_promotional_pricing,
            })
          "
        >
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center bg-brand-light-red brand-dark-red"
            >
              <i class="icon-promotional-pricing"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">
                  {{ all_promotional_pricing }}
                </h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.all-promotional-pricing") }}
              </p>
            </div>
          </div>
        </li>
        <li
          role="button"
          class="float-left"
          v-if="userModulerPermission['flyer-pricing-access']"
          @click="
            navigateHistory({
              pricingType: 'FlyerPricing',
              pricingStatus: 'all',
              count: flyer_pricing,
            })
          "
        >
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center bg-brand-light-purple brand-dark-purple"
            >
              <i class="icon-flyer-pricing"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">{{ flyer_pricing }}</h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.all-flyer-pricing") }}
              </p>
            </div>
          </div>
        </li>

        <!-- Active Data -->
        <li
          role="button"
          class="float-left"
          v-if="userModulerPermission['everyday-pricing-access']"
          @click="
            navigateHistory({
              pricingType: 'EverydayPricing',
              pricingStatus: '1',
              count: active_everyday_pricing,
            })
          "
        >
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center bg-brand-light-blue brand-dark-blue"
            >
              <i class="icon-everyday-pricing"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">
                  {{ active_everyday_pricing }}
                </h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.active-everyday-pricing") }}
              </p>
            </div>
          </div>
        </li>
        <li
          role="button"
          class="float-left"
          v-if="userModulerPermission['promotional-pricing-access']"
          @click="
            navigateHistory({
              pricingType: 'PromoPricing',
              pricingStatus: '1',
              count: active_promotional_pricing,
            })
          "
        >
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center bg-brand-light-blue brand-dark-blue"
            >
              <i class="icon-promotional-pricing"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">
                  {{ active_promotional_pricing }}
                </h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.active-promotional-pricing") }}
              </p>
            </div>
          </div>
        </li>
        <li
          role="button"
          class="float-left"
          v-if="userModulerPermission['flyer-pricing-access']"
          @click="
            navigateHistory({
              pricingType: 'FlyerPricing',
              pricingStatus: '1',
              count: active_flyer_pricing,
            })
          "
        >
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center bg-brand-light-blue brand-dark-blue"
            >
              <i class="icon-flyer-pricing"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">{{ active_flyer_pricing }}</h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.active-flyer-pricing") }}
              </p>
            </div>
          </div>
        </li>
        <!-- In-active Data -->
        <li
          role="button"
          class="float-left"
          v-if="userModulerPermission['everyday-pricing-access']"
          @click="
            navigateHistory({
              pricingType: 'EverydayPricing',
              pricingStatus: '0',
              count: inactive_everday_pricing,
            })
          "
        >
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center bg-brand-light-caramel brand-dark-yellow"
            >
              <i class="icon-everyday-pricing"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">
                  {{ inactive_everday_pricing }}
                </h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.inactive-everyday-pricing") }}
              </p>
            </div>
          </div>
        </li>
        <li
          role="button"
          class="float-left"
          v-if="userModulerPermission['promotional-pricing-access']"
          @click="
            navigateHistory({
              pricingType: 'PromoPricing',
              pricingStatus: '0',
              count: inactive_promotional_pricing,
            })
          "
        >
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center bg-brand-light-caramel brand-dark-yellow"
            >
              <i class="icon-promotional-pricing"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">
                  {{ inactive_promotional_pricing }}
                </h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.inactive-promotional-pricing") }}
              </p>
            </div>
          </div>
        </li>
        <li
          role="button"
          class="float-left"
          v-if="userModulerPermission['flyer-pricing-access']"
          @click="
            navigateHistory({
              pricingType: 'FlyerPricing',
              pricingStatus: '0',
              count: inactive_flyer_pricing,
            })
          "
        >
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center bg-brand-light-caramel brand-dark-yellow"
            >
              <i class="icon-flyer-pricing"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">{{ inactive_flyer_pricing }}</h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.inactive-flyer-pricing") }}
              </p>
            </div>
          </div>
        </li>
        <!-- Scheduled Data -->
        <li
          role="button"
          class="float-left"
          v-if="userModulerPermission['everyday-pricing-access']"
          @click="
            navigateHistory({
              pricingType: 'EverydayPricing',
              pricingStatus: '2',
              count: scheduled_everyday_pricing,
            })
          "
        >
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center bg-brand-light-green brand-dark-green"
            >
              <i class="icon-everyday-pricing"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">
                  {{ scheduled_everyday_pricing }}
                </h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.scheduled-everyday-pricing") }}
              </p>
            </div>
          </div>
        </li>
        <li
          role="button"
          class="float-left"
          v-if="userModulerPermission['promotional-pricing-access']"
          @click="
            navigateHistory({
              pricingType: 'PromoPricing',
              pricingStatus: '2',
              count: scheduled_promotional_pricing,
            })
          "
        >
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center bg-brand-light-green brand-dark-green"
            >
              <i class="icon-promotional-pricing"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">
                  {{ scheduled_promotional_pricing }}
                </h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.scheduled-promotional-pricing") }}
              </p>
            </div>
          </div>
        </li>
        <li
          role="button"
          class="float-left"
          v-if="userModulerPermission['flyer-pricing-access']"
          @click="
            navigateHistory({
              pricingType: 'FlyerPricing',
              pricingStatus: '2',
              count: scheduled_flyer_pricing,
            })
          "
        >
          <div class="box shadow-sm d-flex rounded align-items-center">
            <span
              class="box-icon d-flex justify-content-center align-items-center bg-brand-light-green brand-dark-green"
            >
              <i class="icon-flyer-pricing"></i>
            </span>
            <div class="ms-3 box-content">
              <div class="d-flex align-items-center">
                <h5 class="mb-0" role="button">
                  {{ scheduled_flyer_pricing }}
                </h5>
              </div>
              <p class="mb-0 body-3">
                {{ $t("user-dashboard.scheduled-flyer-pricing") }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </section>

    <section class="mt-4" v-if="showActicity">
      <h5 class="mb-3">{{ $t("user-dashboard.activity-header") }}</h5>
      <b-card class="user-activity">
        <b-table
          responsive
          :items="dashboardActivityData"
          :fields="dashboardActivityFields"
          :per-page="perPage"
          :current-page="currentPage"
          show-empty
          :empty-text="$t('general.no-data-msg')"
        >
          <template #head(activityStatus)>
            {{ $t(`user-dashboard.activity-table_head_status`) }}
          </template>
          <template #head(activityDescription)>
            {{ $t(`user-dashboard.activity-table_head_activity`) }}
          </template>
          <template #head(activityDate)>
            {{ $t(`user-dashboard.activity-table_head_activityDate`) }}
          </template>
          <template #head(activityDownload)>
            {{ $t(`user-dashboard.activity-table_head_activityDownload`) }}
          </template>

          <template #cell(activityStatus)="data">
            <span
              class="tag-status"
              :class="{
                active: data.item.action == 'Added',
                scheduled: data.item.action == 'Updated',
                failed: data.item.action == 'Deleted',
              }"
              role="button"
              >{{ data.item.action }}</span
            >
          </template>
          <template #cell(activityDescription)="data">
            <template v-if="data.item.action == 'Added'">
              {{
                $t("user-dashboard.activity-added_product-label", {
                  count: data.item.products,
                })
              }}
            </template>
            <template v-else-if="data.item.action == 'Updated'">
              {{
                $t("user-dashboard.activity-updated_product-label", {
                  count: data.item.products,
                })
              }}
            </template>
            <template v-else>
              {{
                $t("user-dashboard.activity-deleted_product-label", {
                  count: data.item.products,
                })
              }}
            </template>
          </template>
          <template #cell(activityDate)="data">
            {{ data.item.loggedOn | formatedActivityDate }}
          </template>
          <template #cell(activityDownload)="data">
            <a
              href="javascript:void(0)"
              @click="
                downloadActivityFeedDetails({
                  siteId: siteId,
                  logGroupId: data.item.logGroupId,
                  action: data.item.action,
                  createdOn: data.item.loggedOn,
                })
              "
              class="ml-3 brand-navi-selected"
              ><i class="icon-download"></i
            ></a>
          </template>
        </b-table>

        <table-pagination
          class="mb-3"
          :fromLimit="fromLimit | addLeadingZero"
          :toLimit="toLimit | addLeadingZero"
          :totalRecords="totalRecords | addLeadingZero"
          :currentPage="currentPage"
          :totalPages="totalPages"
          @paginate="paginate"
          :countInfo="'user-dashboard.activity-table_count-info'"
        />
      </b-card>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations, mapActions } from "vuex";
import { forEach, ceil } from "lodash";
import config from "../../config";
import TablePagination from "../components/TablePagination.vue";
import moment from "moment";

const { tableHeaders, exportCSVHeaders } = config;

export default {
  name: "Home",
  components: { TablePagination },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Dashboard",
  },
  data() {
    return {
      //temp data attributes
      showLastSync: false,
      showProducts: false,
      showActicity: true,

      sitesList: [],
      siteSearchText: "",
      siteId: "",

      total_products: 0,
      total_products_on_active_price_rules: 0,
      all_everyday_pricing: 0,
      all_promotional_pricing: 0,
      flyer_pricing: 0,
      active_everyday_pricing: 0,
      active_promotional_pricing: 0,
      active_flyer_pricing: 0,
      inactive_everday_pricing: 0,
      inactive_promotional_pricing: 0,
      inactive_flyer_pricing: 0,
      scheduled_everyday_pricing: 0,
      scheduled_promotional_pricing: 0,
      scheduled_flyer_pricing: 0,

      dashboardActivityFields: tableHeaders["dashboard-activity"],
      dashboardActivityData: [],
      currentPage: 1,
      perPage: 5,
    };
  },
  computed: {
    ...mapGetters("auth", ["userModulerPermission"]),
    ...mapGetters({ selectedSite: "userDashboardSite" }),
    ...mapGetters("auth", ["siteAccess"]),
    ...mapGetters("pricingManager", ["history"]),
    isVisiblePriceRules() {
      let tmp_modulerPermission = Object.assign({}, this.userModulerPermission);

      forEach(
        [
          "flat-flyer-pricing-access",
          "active-promotion-access",
          "tag-manager-access",
          "store-location-access",
          "recycle-fee-access",
        ],
        (module) => {
          delete tmp_modulerPermission[module];
        }
      );

      let allowed = Object.values(tmp_modulerPermission).some(
        (value) => value === true
      );

      return allowed;
    },
    totalRecords() {
      return this.dashboardActivityData.length;
    },
    totalPages() {
      return ceil(this.dashboardActivityData.length / this.perPage);
    },
    fromLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.fromlimit;
    },
    toLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.tolimit;
    },
    activityCSVHeader() {
      let header = {};
      let csvFields = exportCSVHeaders["dashboard-activity"];
      forEach(csvFields, (field) => {
        header[field.key] = field.label;
      });
      return header;
    },
  },
  watch: {
    siteId: function (newVal) {
      let selectedSite = this.siteAccess.find((site) => site.SiteId == newVal);
      this.generalSetItem({
        resource: "userDashboardSite",
        payload: selectedSite,
      });
      this.getActivePriceRules(newVal);
      this.fetchProductCount(newVal);
      this.fetchProductsActivity(newVal);
    },
    siteSearchText(newVal) {
      if (this.siteAccess.length > 0) {
        let newOptions = [];
        forEach(this.siteAccess, (site) => {
          if (
            site.Name.toLowerCase().includes(newVal.toLowerCase()) ||
            newVal == ""
          ) {
            let obj = { text: site.Name, value: site.SiteId };
            newOptions.push(obj);
          }
        });
        this.sitesList = newOptions;
      }
    },
    history: function (newVal) {
      if (newVal.length) {
        let data = newVal.filter((item) => {
          let siteNames = item.siteName.split(",");
          let areTrue = false;
          if (siteNames.includes(this.selectedSite.Name)) areTrue = true;
          return areTrue;
        });
        //set the count for all everyday, promotional and flyer rules
        this.all_everyday_pricing = data.filter(
          (obj) => obj.pricing == "EverydayPricing"
        ).length;
        this.all_promotional_pricing = data.filter(
          (obj) => obj.pricing == "PromoPricing"
        ).length;
        this.flyer_pricing = data.filter(
          (obj) => obj.pricing == "FlyerPricing"
        ).length;

        //set the count for all active everyday, promotional and flyer rules
        this.active_everyday_pricing = data.filter(
          (obj) => obj.pricing == "EverydayPricing" && obj.isActive == 1
        ).length;
        this.active_promotional_pricing = data.filter(
          (obj) => obj.pricing == "PromoPricing" && obj.isActive == 1
        ).length;
        this.active_flyer_pricing = data.filter(
          (obj) => obj.pricing == "FlyerPricing" && obj.isActive == 1
        ).length;

        //set the count for all inactive everyday, promotional and flyer rules
        this.inactive_everday_pricing = data.filter(
          (obj) => obj.pricing == "EverydayPricing" && obj.isActive == 0
        ).length;
        this.inactive_promotional_pricing = data.filter(
          (obj) => obj.pricing == "PromoPricing" && obj.isActive == 0
        ).length;
        this.inactive_flyer_pricing = data.filter(
          (obj) => obj.pricing == "FlyerPricing" && obj.isActive == 0
        ).length;

        //set the count for all scheduled everyday, promotional and flyer rules
        this.scheduled_everyday_pricing = data.filter(
          (obj) => obj.pricing == "EverydayPricing" && obj.isActive == 2
        ).length;
        this.scheduled_promotional_pricing = data.filter(
          (obj) => obj.pricing == "PromoPricing" && obj.isActive == 2
        ).length;
        this.scheduled_flyer_pricing = data.filter(
          (obj) => obj.pricing == "FlyerPricing" && obj.isActive == 2
        ).length;
      }
    },
  },
  methods: {
    ...mapActions("pricingManager", ["getHistory"]),
    ...mapActions([
      "getDashboardProductsCount",
      "getProductsActivity",
      "getActivityFeedDetails",
    ]),
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    getActivePriceRules(siteId) {
      this.generalSetItem({ resource: "isLoading", payload: true });
      let filterObject = {
        siteIds: siteId,
        userId: this.loggedUserId,
      };
      this.all_everyday_pricing = 0;
      this.all_promotional_pricing = 0;
      this.flyer_pricing = 0;
      this.active_everyday_pricing = 0;
      this.active_promotional_pricing = 0;
      this.active_flyer_pricing = 0;
      this.inactive_everday_pricing = 0;
      this.inactive_promotional_pricing = 0;
      this.inactive_flyer_pricing = 0;
      this.scheduled_everyday_pricing = 0;
      this.scheduled_promotional_pricing = 0;
      this.scheduled_flyer_pricing = 0;
      this.getHistory(filterObject)
        .then(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    navigateHistory(data) {
      if (data.count == 0) return;
      // this.$router.push({ name: 'pricing-history', params: { selectedSite: {siteId : this.selectedSite.SiteId, siteName: this.selectedSite.Name},pricingType: data.pricingType, pricingStatus: data.pricingStatus == 'all' ? '' : data.pricingStatus  } })
      this.$router.push({
        name: "pricing-history",
        params: {
          selectedSite: this.selectedSite.SiteId,
          pricingType: data.pricingType,
          pricingStatus: data.pricingStatus == "all" ? "" : data.pricingStatus,
        },
      });
    },
    fetchProductCount(siteId) {
      this.getDashboardProductsCount({ siteId }).then((response) => {
        this.total_products = response[0].productsCount;
        this.total_products_on_active_price_rules =
          response[0].productsOnPromotion;
      });
    },
    fetchProductsActivity(siteId) {
      this.generalSetItem({ resource: "isLoading", payload: true });
      this.getProductsActivity({ siteId: siteId })
        .then((response) => {
          this.generalSetItem({ resource: "isLoading", payload: false });
          this.dashboardActivityData = response;
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    paginate(eventType) {
      if (eventType == "next") {
        this.currentPage = this.currentPage + 1;
      } else {
        this.currentPage = this.currentPage - 1;
      }
    },
    async downloadActivityFeedDetails(params) {
      let activityAction = 0;

      switch (params.action) {
        case "Added":
          activityAction = 1;
          break;
        case "Updated":
          activityAction = 2;
          break;
        default:
          activityAction = 3;
        // code block
      }

      params.action = activityAction;
      // params.loggedOn = moment(params.loggedOn).format("MM-DD-YYYY");

      const data = await this.getActivityFeedDetails(params);

      let csvData = this.prepareCSVdata(this.activityCSVHeader, data);

      let fileTitle =
        this.selectedSite.Name +
        "_activity_" +
        moment(params.createdOn).format("YYYY/MM/DD");

      this.exportCSVFile(this.activityCSVHeader, csvData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
    },
    prepareCSVdata(headers, productdata) {
      let csvData = [];
      forEach(productdata, (data) => {
        let _csvData = {};
        forEach(headers, (val, key) => {
          _csvData[key] = "";

          if (key == "activityProductId") {
            if ("products" in data) {
              _csvData[key] = String(data.products).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }

          if (key == "activityDate") {
            // if ("loggedOn" in data) {
            //   _csvData[key] = String(data.loggedOn).replace(/,/g, ""); // remove commas to avoid errors,
            // }
            if ("createdOn" in data) {
              _csvData[key] = String(data.createdOn).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }

          if (key == "activityStatus") {
            if ("action" in data) {
              _csvData[key] = String(data.action).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
        });
        csvData.push(_csvData);
      });
      return csvData;
    },
  },
  beforeMount() {
    this.sitesList = [];
    let sites = [];
    forEach(this.siteAccess, (site) => {
      let obj = { text: site.Name, value: site.SiteId };
      sites.push(obj);
    });
    this.siteId = 0;
    this.sitesList = sites;
  },
  mounted() {
    if (this.selectedSite != null && Object.keys(this.selectedSite).length) {
      this.siteId = this.selectedSite.SiteId;
      this.getActivePriceRules(this.selectedSite.SiteId);
      this.fetchProductCount(this.selectedSite.SiteId);
      this.fetchProductsActivity(this.selectedSite.SiteId);
    }
  },
  filters: {
    addLeadingZero: function (value) {
      return value < 10 ? "0" + value.toString() : value.toString();
    },
    formatedActivityDate: function (value) {
      return moment(value).format("DD-MM-YYYY");
    },
  },
};
</script>
