import axios from "axios";
import config from "../config";

import Router from "./router";
import Store from "./store";

const { responseStatus } = config;

const ajax = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL,
  baseURL: process.env.VUE_APP_API_URL,
});

ajax.interceptors.request.use(
  (config) => {
    let token = Store.getters["auth/accessToken"];

    config.headers["Authorization"] = `Bearer ${token}`;

    return config;
  },

  (error) => Promise.reject(error)
);

ajax.interceptors.response.use(
  (response) => {
    let isUnauthorized = false;
    let result = [];
    let finalData = [];

    if (response.status === responseStatus.Success || response.status === 201) {
      finalData = response.data;

      //added below condition due to not consistant response in verify site api keys
      if (
        response.status === responseStatus.Success &&
        finalData["result"] == undefined
      ) {
        finalData = {
          status: "Success",
          statusCode: responseStatus.Success,
          message: "Success",
          result: response.data,
        };
      }

      if (
        finalData.statusCode === responseStatus.Success ||
        finalData.statusCode === responseStatus.NotFound
      ) {
        result = finalData.result;
      } else if (response.statusCode === responseStatus.Unauthorized) {
        isUnauthorized = true;
      }
    } else if (response.status === responseStatus.Unauthorized) {
      isUnauthorized = true;
    }

    if (isUnauthorized) {
      Store.dispatch("auth/LogOut");
      Router.push("/login");
    } else {
      return { statusCode: finalData.statusCode, data: result };
    }
  },
  (error) => {
    if (error.response.status == responseStatus.Unauthorized) {
      Store.dispatch("auth/LogOut");
      Router.push("/login");
    }
    return Promise.reject(error);
  }
);

const request = async ({ url, method, name = "", ...rest }) => {
  try {
    return await ajax({
      method,
      url,
      ...rest,
    });
  } catch (error) {
    throw error;
  }
};

export default {
  get: async (url, { ...rest }) =>
    await request({ method: "get", url, ...rest }),
  post: async (url, { body, ...rest }) =>
    await request({ method: "post", url, data: body, ...rest }),
  put: async (url, { body, ...rest }) =>
    await request({ method: "put", url, data: body, ...rest }),
  delete: async (url, { ...rest }) =>
    await request({ method: "delete", url, ...rest }),
  patch: async (url, { body, ...rest }) =>
    await request({ method: "patch", url, data: body, ...rest }),
};
