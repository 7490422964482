import config from "../../../config";

const { userType } = config;

export default function isAdmin({ next, store }) {
  let user = store.getters["auth/user"];
  let defaultLandingRoute = store.getters["auth/defaultLandingRoute"];

  if (user) {
    if (user.userType != userType.adminUser) {
      return next({
        path: defaultLandingRoute,
      });
    }
  } else {
    store.commit("setItem", { resource: "isLoading", payload: false });
    store.commit("auth/LogOut");
    return next({
      path: "/login",
    });
  }

  return next();
}
