import service from "../service";

import { toLower, startCase } from "lodash";

const actions = {
  async getTranslationModules({ commit }, payload) {
    try {
      const results = await service.getTranslationModules(payload);

      let module = results.map((item) => {
        return {
          ...item,
          module: startCase(toLower(item.module).replace("access", "")),
        };
      });

      // const sortedResponse = orderBy(
      //   module,
      //   [(_value) => toLower(_value.module)],
      //   ["asc"]
      // );

      commit("setItem", { resource: "translationModules", payload: module });
    } catch (e) {
      commit("setItem", { resource: "translationModules", payload: [] });
    }
  },
  async getTranslationFields({ commit }, params) {
    try {
      const results = await service.getTranslationFields(params);

      // const sortedResponse = orderBy(
      //   results,
      //   [(_value) => toLower(_value.fieldName)],
      //   ["asc"]
      // );

      commit("setItem", { resource: "translationFields", payload: results });
    } catch (e) {
      commit("setItem", { resource: "translationFields", payload: [] });
    }
  },
  async getTranslationFieldDetails(_, params) {
    try {
      const results = await service.getTranslationFieldDetails(params);
      return results;
    } catch (e) {
      return [];
    }
  },
  async updateTranslationFieldDetails(_, params) {
    try {
      const results = await service.updateTranslationFieldDetails(params);
      return results;
    } catch (e) {
      return [];
    }
  },
  async getVHistoryData(context, payload) {
    try {
      const results = await service.getVHistoryData(payload);
      context.commit("setItem", {
        resource: "versionHistory",
        payload: results,
      });
    } catch (error) {
      return [];
    }
  },
  async getVHistoryById(_, id) {
    const result = await service.getVHistoryById(id);
    // commit("setItem", { resource: "user", payload: result[0] });
    return result;
  },
  async manageversionhistory(_, versionhistory) {
    const response = await service.manageHistoryVersion(versionhistory);
    return response;
  },
};

export default actions;
