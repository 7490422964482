<template>
  <ul class="list-unstyled side-navigation p-0 m-0" role="tablist">
    <template v-for="(mainNavigation, index) in navigationMenu">
      <template v-if="mainNavigation.isAllowed">
        <li v-if="mainNavigation.routes" :key="index" role="tab">
          <b-link
            v-b-toggle="mainNavigation.id"
            class="text-uppercase"
            data-toggle="collapse"
            >{{
              isAdminNavigation
                ? mainNavigation.title
                : $t(`general.${mainNavigation.languageSlug}`)
            }}
            <i class="icon-angle-down"></i>
          </b-link>
          <b-collapse
            :visible="$route.meta.module == mainNavigation.module"
            accordion="navigation-links"
            role="tabpanel"
            :id="mainNavigation.id"
            class="list-unstyled submenu-navigation"
            tag="ul"
          >
            <!-- :class="{'active': $route.meta.controlLink == _route.slug}" -->
            <template v-for="(_route, index) in mainNavigation.routes">
              <li :key="index" v-if="_route.isAllowed">
                <b-link
                  :to="{ name: _route.route }"
                  :active="$route.meta.controlLink == _route.slug"
                >
                  <i :class="`icon-${_route.icon}`"></i>
                  <span>{{
                    isAdminNavigation
                      ? _route.name
                      : $t(`general.${_route.languageSlug}`)
                  }}</span>
                </b-link>
                <ul
                  class="list-unstyled child-navigation"
                  v-if="_route.submenu"
                >
                  <template v-for="(_route, index) in _route.submenu">
                    <li
                      v-if="_route.isAllowed"
                      :key="index"
                      :class="{
                        active: $route.meta.controlLink == _route.slug,
                      }"
                    >
                      <b-link
                        :to="{ name: _route.route }"
                        :class="{
                          active: $route.meta.controlLink == _route.slug,
                        }"
                      >
                        <i
                          :class="`icon-${_route.icon}`"
                          v-if="_route.icon"
                        ></i>
                        <span>{{
                          isAdminNavigation
                            ? _route.name
                            : $t(`general.${_route.languageSlug}`)
                        }}</span>
                      </b-link>
                    </li>
                  </template>
                </ul>
              </li>
            </template>
          </b-collapse>
        </li>
        <li
          class="nav-item direct-link"
          :class="{ active: $route.meta.controlLink == mainNavigation.slug }"
          :key="index"
          v-else
        >
          <b-link :to="{ name: mainNavigation.route }">
            <i
              :class="`icon-${mainNavigation.icon}`"
              v-if="mainNavigation.icon"
            ></i>
            <span>{{ mainNavigation.title }}</span>
          </b-link>
        </li>
      </template>
    </template>
    <template v-if="isMobile">
      <li>
        <b-link :to="{ name: 'profile' }">{{ $t(`general.profile`) }}</b-link>
      </li>
      <li v-if="isAdminUser && !isAdminNavigation">
        <b-link :to="{ name: 'admin-home' }">{{
          $t(`general.admin-panel`)
        }}</b-link>
      </li>
      <li v-if="!isAdminNavigation">
        <b-link :to="{ name: 'version-history' }">{{
          $t(`general.version-history`)
        }}</b-link>
      </li>
      <li v-if="isAdminUser && isAdminNavigation">
        <b-link :to="{ name: 'home' }">User Panel</b-link>
      </li>
      <li class="my-2">
        <div
          class="d-flex align-items-start flex-column"
          style="padding: 10px 16px"
        >
          <b-button variant="brand-secondary" @click="logout">{{
            $t(`general.logout`)
          }}</b-button>
          <b-link class="p-0 mt-1">{{
            user.firstName + " " + user.lastName
          }}</b-link>
        </div>
      </li>
      <li class="border-bottom-0" v-if="!isAdminNavigation">
        <b-link
          :to="helpRoute.route"
          class="help-link d-flex justify-content-start"
        >
          <i :class="`icon-${helpRoute.icon}`"></i>
          <span class="ml-2">{{
            $t(`general.${helpRoute.languageSlug}`)
          }}</span>
        </b-link>
      </li>
    </template>
  </ul>
</template>

<script>
import config from "../../config";
const { helpRoute } = config;
export default {
  name: "NavigationLinks",
  props: {
    navigationMenu: [Array, Object],
    isMobile: {
      type: Boolean,
      default: false,
    },
    isAdminNavigation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      helpRoute: helpRoute,
    };
  },
};
</script>
