<template>
  <div v-if="!isFormSubmitted">
    <b-card>
      <b-form @submit.prevent v-if="showForm">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="name-div"
              :label="$t(`tag-manager.name-label`)"
              label-for="name"
              :label-class="['form-label', 'required']"
              :class="{ 'form-group--error': $v.formData.title.$error }"
            >
              <b-form-input
                id="name"
                v-model="formData.title"
                :placeholder="$t(`tag-manager.name-placeholder`)"
                trim
                :class="{ 'is-invalid': $v.formData.title.$error }"
              ></b-form-input>

              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback id="name-feedback">
                {{ $t(`tag-manager.name-required-error-msg`) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="siteId"
              :label="$t(`tag-manager.select_site-label`)"
              label-for="siteId"
              :label-class="['form-label', 'required']"
              :class="{ 'form-group--error': $v.formData.siteId.$error }"
            >
              <b-dropdown
                id="dropdown-form"
                :text="
                  formData.siteId == ''
                    ? $t(`tag-manager.select_site-placeholder`)
                    : selectedSite.Name
                "
                ref="dropdown"
                block
                menu-class="w-100 scrollable"
                no-caret
                lazy
                variant="brand-dropdown"
                :toggle-class="{ 'is-invalid': $v.formData.siteId.$error }"
                :class="{ 'is-invalid': $v.formData.siteId.$error }"
              >
                <b-dropdown-item
                  v-for="(site, index) in sitesList"
                  :key="index"
                  @click="formData.siteId = site.value"
                  :active="formData.siteId == site.value"
                  >{{ site.text }}</b-dropdown-item
                >
              </b-dropdown>

              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback id="siteId-feedback">
                {{ $t(`tag-manager.select_site-required-error-msg`) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <div
          role="group"
          class="form-group"
          :class="{
            'form-group--error':
              formData.siteId != '' &&
              $v.productGroups.isInvalidProductGroup &&
              $v.productGroups.$dirty,
          }"
        >
          <label for="name" class="form-label required">{{
            $t(`tag-manager.choose_product-label`)
          }}</label>
          <i class="icon-tooltip ml-1" id="chooseProducts-tootltip"></i>
          <b-tooltip target="chooseProducts-tootltip"
            ><p v-html="$t(`tag-manager.choose_product-tooltip`)"
          /></b-tooltip>
          <div class="product-filters" id="productFilters">
            <product-filters
              filterLabel="Choose products"
              v-for="(productGroup, index) in productGroups"
              :key="index"
              :id="productGroup.groupId"
              :index="index"
              :totalGroups="productGroups.length"
              :requiredFilters="requiredFilters"
              :disabled="formData.siteId == ''"
              @removeProductGroup="removeProductGroup"
              :class="{
                'single-filter':
                  !enableProductGroupBoxStyling && !enableAddProductGroup,
                'is-invalid':
                  formData.siteId != '' &&
                  $v.productGroups.isInvalidProductGroup &&
                  $v.productGroups.$dirty,
              }"
              moduleSlug="tag-manager"
              :controllerName="controllerName"
              :moduleName="moduleName"
              :isPushPricing="isPushPricing"
            />
            <b-form-invalid-feedback>
              {{
                !enableProductGroupBoxStyling && !enableAddProductGroup
                  ? $t(`tag-manager.choose_product-required-error-msg`)
                  : $t(`tag-manager.choose_product-required-error-msg`) +
                    availableProductGroups
              }}
            </b-form-invalid-feedback>
          </div>
          <b-button
            variant="link"
            @click="createNewProductGroup"
            v-if="enableProductGroupBoxStyling && enableAddProductGroup"
            :disabled="formData.siteId == ''"
            >Add New Product-Group</b-button
          >
        </div>
        <div class="row">
          <div
            id="tagSelection-div"
            role="group"
            class="form-group col-sm-12 col-md-6"
            :class="{
              'form-group--error':
                isAddNewTagFailed ||
                (formData.siteId != '' && $v.formData.tagId.$error),
            }"
          >
            <label for="tagId" class="form-label required"
              >Custom tag name</label
            >
            <i class="icon-tooltip ml-1" id="customTag-tootltip"></i>
            <b-tooltip target="customTag-tootltip"
              ><p v-html="$t(`tag-manager.custom_tag-tooltip`)"
            /></b-tooltip>
            <b-form-radio
              v-model="addNewTag"
              name="radio-options"
              :value="true"
              :disabled="formData.siteId == ''"
              @change="handleChangeTagMethod"
              v-if="isEnableAddNewTag"
            >
              {{ $t("tag-manager.add_new_custom_tag-option-label") }}
            </b-form-radio>
            <b-input-group
              :prepend="tagPrefix"
              class="mt-3"
              v-if="addNewTag"
              :class="{
                'is-invalid':
                  isAddNewTagFailed ||
                  (formData.siteId != '' && $v.formData.tagId.$error),
              }"
            >
              <b-form-input
                id="inline-form-input-tagname"
                class="form-control"
                :placeholder="$t(`tag-manager.add_new_custom_tag-placeholder`)"
                v-model.trim="newTagStr"
                :disabled="formData.siteId == ''"
                @keypress="allowOnlyAlphaNumeric"
                :class="{
                  'is-invalid':
                    isAddNewTagFailed ||
                    (formData.siteId != '' && $v.formData.tagId.$error),
                }"
              />
            </b-input-group>
            <b-button
              id="add-tag-btn"
              variant="brand-secondary"
              class="mt-1"
              block
              @click="handleAddNewTag"
              v-if="enableAddNewTag"
            >
              <i class="icon-add mr-1"></i> Add '{{ tagPrefix }}{{ newTagStr }}'
              as a new tag</b-button
            >
            <!-- <b-form-invalid-feedback id="tag-feedback" v-if="isAddNewTagFailed">
              {{ isAddNewTagFailedError }}
            </b-form-invalid-feedback> -->
            <b-form-radio
              class="mt-3"
              v-model="addNewTag"
              name="radio-options"
              :value="false"
              :disabled="formData.siteId == ''"
              @change="handleChangeTagMethod"
              v-if="isEnableAddNewTag"
            >
              {{ $t("tag-manager.select_new_custom_tag-option-label") }}
            </b-form-radio>
            <b-dropdown
              v-if="!addNewTag"
              id="dropdown-form"
              :text="
                formData.tagId == ''
                  ? $t(`tag-manager.slect_new_custom_tag-placeholder`)
                  : selectedTag.tag
              "
              ref="dropdown"
              block
              menu-class="w-100 scrollable"
              no-caret
              lazy
              variant="brand-dropdown"
              :disabled="formData.siteId == '' || siteTags.length == 0"
              :toggle-class="{
                'is-invalid':
                  isAddNewTagFailed ||
                  (formData.siteId != '' && $v.formData.tagId.$error),
              }"
              :class="{
                'is-invalid':
                  isAddNewTagFailed ||
                  (formData.siteId != '' && $v.formData.tagId.$error),
                'mt-3': isEnableAddNewTag,
              }"
            >
              <b-dropdown-item
                v-for="(_siteTag, index) in siteTags"
                :key="index"
                @click="formData.tagId = _siteTag.id"
                :active="formData.tagId == _siteTag.id"
                >{{ _siteTag.tag }}</b-dropdown-item
              >
            </b-dropdown>
            <b-form-invalid-feedback id="tag-feedback" v-if="isAddNewTagFailed">
              {{ isAddNewTagFailedError }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              id="tag-feedback"
              v-else-if="formData.tagId == ''"
            >
              {{ $t(`tag-manager.tag-required-error-msg`) }}
            </b-form-invalid-feedback>

            <b-link
              @click="handleDeleteTag"
              v-if="formData.tagId != '' && isEnableAddNewTag"
              class="btn-link mt-2"
            >
              <i class="icon-close mr-1"></i>
              {{ $t(`tag-manager.delete_tab-btn-label`) }}</b-link
            >
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <b-form-group
                  id="startDate-div"
                  :label="$t(`tag-manager.start_date-label`)"
                  label-for="name"
                  :label-class="['form-label', 'required']"
                  :class="{ 'form-group--error': $v.formData.startDate.$error }"
                >
                  <div
                    class="flex w-full"
                    :class="{ 'is-invalid': $v.formData.startDate.$error }"
                  >
                    <v-date-picker
                      :locale="selected_language"
                      ref="startDate"
                      class="flex-grow"
                      :class="{ 'is-invalid': $v.formData.startDate.$error }"
                      :masks="masks"
                      v-model="formData.startDate"
                      is-required
                      :popover="{ visibility: 'click' }"
                      :model-config="modelConfig"
                      :disabled-dates="[
                        {
                          start: null,
                          end: yesterday,
                        },
                      ]"
                      :available-dates="{
                        start: new Date(formData.startDate),
                        end: new Date(formData.startDate),
                      }"
                    >
                      <template v-slot="{ inputValue }">
                        <div class="d-flex custom-datepicker">
                          <input
                            class="form-control bg-white rounded-top-right-0 rounded-bottom-right-0"
                            :class="{
                              'is-invalid': $v.formData.startDate.$error,
                            }"
                            :value="inputValue"
                            @click="showStartDatePicker"
                            :placeholder="dateFormat"
                            readonly
                          />
                          <div
                            class="picker-icon"
                            :class="{
                              'is-invalid': $v.formData.startDate.$error,
                            }"
                            slot="afterDateInput"
                            role="button"
                            @click="showStartDatePicker"
                          >
                            <i class="icon-date"></i>
                          </div>
                        </div>
                      </template>
                    </v-date-picker>
                  </div>

                  <b-form-invalid-feedback id="startDate-feedback">
                    {{
                      formData.startDate &&
                      $v.formData.startDate.$error &&
                      $v.formData.startDate.minValue == false
                        ? sytemMessages.minimumDateError
                        : sytemMessages.startDateRequired
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <b-form-group
                  id="endDate-div"
                  :label="$t(`tag-manager.end_date-label`)"
                  label-for="name"
                  :label-class="['form-label']"
                >
                  <div
                    class="flex w-full"
                    :class="{ 'is-invalid': $v.formData.endDate.$error }"
                  >
                    <v-date-picker
                      :locale="selected_language"
                      ref="endDate"
                      class="flex-grow"
                      :masks="masks"
                      v-model="formData.endDate"
                      :popover="{ visibility: 'click' }"
                      :model-config="modelConfig"
                      :disabled-dates="[
                        {
                          start: null,
                          end: yesterday,
                        },
                      ]"
                      :available-dates="{
                        start:
                          formData.endDate != ''
                            ? new Date(formData.endDate)
                            : new Date(),
                        end:
                          formData.endDate != ''
                            ? new Date(formData.endDate)
                            : null,
                      }"
                    >
                      <template v-slot="{ inputValue }">
                        <div class="d-flex custom-datepicker">
                          <input
                            class="form-control bg-white rounded-top-right-0 rounded-bottom-right-0"
                            :class="{
                              'is-invalid': $v.formData.endDate.$error,
                            }"
                            :value="inputValue"
                            @click="showEndDatePicker"
                            :placeholder="dateFormat"
                            readonly
                          />
                          <div
                            class="picker-icon"
                            :class="{
                              'is-invalid': $v.formData.endDate.$error,
                            }"
                            slot="afterDateInput"
                            role="button"
                            @click="showEndDatePicker"
                          >
                            <i class="icon-date"></i>
                          </div>
                        </div>
                      </template>
                    </v-date-picker>
                  </div>

                  <b-form-invalid-feedback id="endDate-feedback">
                    {{ $t(`tag-manager.end_date-invalid-error-msg`) }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </b-form>
      <preview-form
        :formName="formData.title"
        showProductGroups
        showCustomTag
        showStartDate
        :startDate="formData.startDate | formatedDate"
        showEndDate
        :endDate="formData.endDate | formatedDate"
        moduleSlug="tag-manager"
        :nameLabel="$t(`tag-manager.name-label`)"
        :siteLabel="$t(`tag-manager.selected_site-label`)"
        :chooseProductLabel="$t(`tag-manager.choose_product-label`)"
        :customTag="selectedTag.tag"
        :startDateLabel="$t(`tag-manager.start_date-label`)"
        :endDateLabel="$t(`tag-manager.end_date-label`)"
        v-if="showFormPreview"
      />

      <div class="d-flex flex-row-reverse">
        <b-button
          id="validate-btn"
          @click="handleValidate"
          v-if="showForm"
          variant="brand-primary"
          >{{ $t(`tag-manager.validate-btn-label`) }}
        </b-button>
        <b-button
          id="submit-btn"
          @click="submitForm"
          v-if="showFormPreview && !productPreviewFailed"
          variant="brand-primary"
          >{{ $t(`tag-manager.submit-btn-label`) }}</b-button
        >
        <b-button
          id="edit-btn"
          @click="handleEdit"
          v-if="showFormPreview"
          variant="brand-secondary"
          class="mr-3"
          >{{ $t(`tag-manager.edit-btn-label`) }}</b-button
        >
      </div>
    </b-card>
    <b-card
      v-if="productPreviewFailed || showFilteredProducts"
      class="mt-4 p-0"
    >
      <product-preview-failed v-if="productPreviewFailed" />
      <product-preview
        v-if="showFilteredProducts"
        formName="tag-manager"
        moduleSlug="tag-manager"
        :fileTitle="formData.title"
      />
    </b-card>
    <b-modal
      dialog-class="success-modal"
      centered
      hide-header
      hide-footer
      ref="success-modal"
    >
      <div
        class="d-flex justify-content-center flex-column align-items-center text-center"
      >
        <i class="font-50 icon-completed mb-2"></i>
        <div class="message-detail">
          <h5 class="msg-title">
            {{ $t(`tag-manager.success_message-title`) }}
          </h5>
          <i18n
            :path="`tag-manager.success_message-desc`"
            tag="p"
            class="msg-desc m-0"
          >
            <b-link to="/tag-history">{{
              $t(`tag-manager.success_message-desc_term`)
            }}</b-link>
          </i18n>
        </div>
      </div>
    </b-modal>
  </div>
  <div v-else>
    <b-card class="mt-4">
      <form-success>
        <h5 class="msg-title">
          {{ $t(`tag-manager.success_message-title`) }}
        </h5>
        <i18n
          :path="`tag-manager.success_message-desc`"
          tag="p"
          class="msg-desc m-0"
        >
          <b-link to="/tag-history">{{
            $t(`tag-manager.success_message-desc_term`)
          }}</b-link>
        </i18n>
      </form-success>
    </b-card>
  </div>
</template>

<script>
import { eventBus } from "../../../main";
import config from "../../../../config";
import ProductFilters from "../../../components/ProductFilters.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  debounce,
  forEach,
  map,
  max,
  isObject,
  trim,
  clone,
  isEmpty,
} from "lodash";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import PreviewForm from "../../../components/PreviewForm.vue";
import ProductPreviewFailed from "../../../components/ProductPreviewFailed.vue";
import ProductPreview from "../../../components/ProductPreview.vue";
import FormSuccess from "../../../components/FormSuccess.vue";

const isValidProductGroup = (productGroup) => {
  let isValid = false;
  if (
    productGroup.vendor.length != 0 ||
    productGroup.category.length != 0 ||
    productGroup.productTags.length != 0 ||
    productGroup.productVendorModel.length != 0 ||
    productGroup.productSKU.length != 0
  ) {
    isValid = true;
  }

  return isValid;
};

const isInvalidProductGroup = (value) => {
  let nonValidatedProductGroups = [];
  let validateStatus = [];
  forEach(value, (productGroup, index) => {
    let isValid = isValidProductGroup(productGroup);
    validateStatus.push(isValid);
    if (!isValid) nonValidatedProductGroups.push(index + 1);
  });

  return validateStatus.includes(false);
};

const { tagManagerConfig, dateFormat } = config;

export default {
  name: "TagManager",
  components: {
    ProductFilters,
    PreviewForm,
    ProductPreviewFailed,
    ProductPreview,
    FormSuccess,
  },
  props: {},
  data() {
    return {
      isPushPricing: tagManagerConfig.isPushPricing,
      enableProductGroupBoxStyling: config.enableProductGroupBoxStyling,
      enableAddProductGroup: config.enableAddProductGroup,
      showForm: true,
      showFormPreview: false,
      showFilteredProducts: false,
      productPreviewFailed: false,
      isFormSubmitted: false,
      isSiteTagLoading: false,
      requiredFilters: [
        "vendorFilter",
        "categoryFilter",
        "productTagFilter",
        "vendorModelFilter",
        "skuFilter",
      ],
      addNewTag: true,
      enableAddNewTag: false,
      newTagStr: "",
      formData: {
        id: 0,
        title: "",
        siteId: "",
        startDate: new Date(),
        endDate: "",
        tagId: "",
      },
      disabledStartDate: {
        customPredictor: (date) => {
          let today = new Date();
          var diff = today.getTime() - date.getTime();
          var daydiff = diff / (1000 * 60 * 60 * 24);

          if (daydiff >= 1) return true;
          else return false;
        },
      },
      dateFormat: dateFormat,
      masks: {
        input: dateFormat,
      },
      modelConfig: {
        type: "string",
        mask: "iso",
      },
    };
  },
  async mounted() {
    // this.setProductGroups({ type: "reset" });
    this.resetForm();
    let preFilledData = this.preFilledData;
    if (
      preFilledData &&
      isObject(preFilledData) &&
      Object.keys(preFilledData).length > 0
    ) {
      this.formData.id = this.mode == "edit" ? preFilledData.Id : 0;
      this.formData.title =
        this.mode == "edit"
          ? preFilledData.Title
          : "Copy of " + preFilledData.Title;
      this.formData.siteId = preFilledData.SiteId;
      this.formData.startDate = new Date(preFilledData.StartDate);

      if (
        preFilledData.EndDate == "2099-12-31T00:00:00" ||
        preFilledData.EndDate == "2099-12-31T00:00:00Z" ||
        preFilledData.EndDate == "2099-12-31T00:00:00.000Z"
      ) {
        this.formData.endDate = "";
      } else {
        this.formData.endDate = new Date(preFilledData.EndDate);
      }

      let productcondition = preFilledData.ptmc;

      await this.getSiteTags({
        siteId: preFilledData.SiteId,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        },
      });

      let selectedTag = this.siteTags.find(
        (_siteTag) => _siteTag.id == preFilledData.Tagid
      );

      if (selectedTag) {
        this.formData.tagId = preFilledData.Tagid;
      }

      this.addNewTag = this.enableAddNewTag = false;

      let _productGroups = [];

      let siteId = this.formData.siteId;

      let proccessData = productcondition.reduce(function (results, item) {
        let loopIndex = "key_" + item.ProductGroupId;

        results[loopIndex] = results[loopIndex] || [];

        results[loopIndex].vendor = results[loopIndex].vendor || [];
        results[loopIndex].category = results[loopIndex].category || [];
        results[loopIndex].productTags = results[loopIndex].productTags || [];
        results[loopIndex].productVendorModel =
          results[loopIndex].productVendorModel || [];
        results[loopIndex].productSKU = results[loopIndex].productSKU || [];

        if (item.ConditionType == "Vendor") {
          let vendorObj = {
            condition: trim(item.Condition),
            siteId: siteId,
            vendorId: trim(item.ConditionId),
            vendorName: trim(item.ConditionValue),
          };

          results[loopIndex].vendor.push(vendorObj);
        } else if (item.ConditionType == "Category") {
          let cateObj = {
            roomGroupingId: trim(item.ConditionId),
            roomGrouping: trim(item.ConditionValue),
            condition: trim(item.Condition),
            siteId: siteId,
            storeBrandId: item.storeBrandId ? trim(item.storeBrandId) : null,
          };

          results[loopIndex].category.push(cateObj);
        } else if (item.ConditionType == "ProductTag") {
          let tagObj = {
            id: trim(item.ConditionId),
            tag: trim(item.ConditionValue),
            condition: trim(item.Condition),
            siteId: this.formData.siteId,
          };

          results[loopIndex].productTags.push(tagObj);
        } else if (item.ConditionType == "VendorModelNumber") {
          let vmnObj = {
            productVendorModelId: trim(item.ConditionId),
            productVendorModel: trim(item.ConditionValue),
            condition: trim(item.Condition),
          };

          results[loopIndex].productVendorModel.push(vmnObj);
        } else if (item.ConditionType == "ProductSKU") {
          let skuObj = {
            productSKUId: trim(item.ConditionId),
            productSKU: trim(item.ConditionValue),
            condition: trim(item.Condition),
          };

          results[loopIndex].productSKU.push(skuObj);
        }
        return results;
      }, {});

      let _productGroupObject = {
        groupId: "1",
        vendor: [],
        category: [],
        productTags: [],
        productVendorModel: [],
        productSKU: [],
      };

      for (const key in proccessData) {
        let groupId = key.replace("key_", "");

        let obj = clone(_productGroupObject);
        obj.groupId = groupId;
        obj.vendor = proccessData[key]["vendor"];
        obj.category = proccessData[key]["category"];
        obj.productTags = proccessData[key]["productTags"];
        obj.productVendorModel = proccessData[key]["productVendorModel"];
        obj.productSKU = proccessData[key]["productSKU"];

        _productGroups.push(obj);

        setTimeout(() => {
          eventBus.$emit("setFilter", {
            id: groupId,
            filterData: [
              {
                filterName: "vendorFilter",
                filters: obj.vendor,
              },
              {
                filterName: "categoryFilter",
                filters: obj.category,
              },
              {
                filterName: "productTagFilter",
                filters: obj.productTags,
              },
              {
                filterName: "vmnFilter",
                filters: obj.productVendorModel,
              },
              {
                filterName: "skuFilter",
                filters: obj.productSKU,
              },
            ],
          });
          // eventBus.$emit("setVendorFilter", {
          //   id: groupId,
          //   vendor: obj.vendor,
          // });
          // eventBus.$emit("setCategoryFilter", {
          //   id: groupId,
          //   category: obj.category,
          // });
          // eventBus.$emit("setTagFilter", { id: groupId, tag: obj.productTags });
          // eventBus.$emit("setVmnFilter", {
          //   id: groupId,
          //   vmn: obj.productVendorModel,
          // });
          // eventBus.$emit("setSkuFilter", { id: groupId, sku: obj.productSKU });
        }, 900);
      }
      setTimeout(() => {
        this.setProductGroups({ type: "update", data: _productGroups });
      }, 700);
    }
  },
  computed: {
    ...mapGetters([
      "productGroups",
      "filteredProductError",
      "selectedSite",
      "selected_language",
    ]),
    ...mapGetters("auth", ["siteAccess"]),
    ...mapGetters("tagManager", [
      "tagManagerId",
      "siteTags",
      "selectedTag",
      "mode",
      "preFilledData",
      "isAddNewTagFailed",
      "isAddNewTagFailedError",
    ]),
    options() {
      return [
        {
          text: this.$t("tag-manager.add_new_custom_tag-option-label"),
          value: true,
          selected: true,
        },
        {
          text: this.$t("tag-manager.select_new_custom_tag-option-label"),
          value: false,
        },
      ];
    },
    isEnableAddNewTag() {
      return (
        this.user.userType == this.tdgUser ||
        this.user.userType == this.adminUser
      );
    },
    tagPrefix() {
      return this.formData.siteId == ""
        ? "dxxx_"
        : "d" + this.selectedSite.SiteId + "_";
    },
    tagTooltip() {
      if (this.isEnableAddNewTag) {
        return this.tooltipMessages.customTagName;
      } else {
        return this.tooltipMessages.customTagNameDRSG;
      }
    },
    sitesList() {
      let sites = [];
      forEach(this.siteAccess, (site) => {
        let obj = { text: site.Name, value: site.SiteId };
        sites.push(obj);
      });
      return sites;
    },
    availableProductGroups() {
      let group = [];
      forEach(this.productGroups, (productGroup, index) => {
        group.push(index + 1);
      });
      return group.join();
    },
    controllerName() {
      return "TagManager.vue";
    },
    moduleName() {
      return "Tag Manager";
    },
  },
  methods: {
    ...mapActions([
      "addNewProductGroup",
      "setProductGroups",
      "resetFormState",
      "getTagFilteredProducts",
    ]),
    ...mapActions("tagManager", [
      "getSiteTags",
      "addSiteTag",
      "deleteSiteTag",
      "submitTagForm",
    ]),
    ...mapMutations(["setItem"]),
    ...mapMutations("tagManager", {
      tagSetItem: "setItem",
    }),
    createNewProductGroup() {
      let groupIds = map(this.productGroups, (_data) => Number(_data.groupId));
      let lastGroupId = max(groupIds);
      this.addNewProductGroup(lastGroupId + 1);
    },
    removeProductGroup(index) {
      let _productGroup = this.productGroups;
      _productGroup.splice(index, 1);
      this.setProductGroups({ type: "update", data: _productGroup });
    },
    showStartDatePicker() {
      this.$nextTick(() => {
        this.$refs.endDate.hidePopover();
        this.$refs.startDate.togglePopover();
      });
    },
    showEndDatePicker() {
      this.$nextTick(() => {
        this.$refs.startDate.hidePopover();
        this.$refs.endDate.togglePopover();
      });
    },
    hideStartDatePicker() {
      this.$nextTick(() => {
        this.$refs.startDate.hidePopover();
      });
    },
    hideEndDatePicker() {
      this.$nextTick(() => {
        this.$refs.endDate.hidePopover();
      });
    },
    showSuccessModal() {
      this.$refs["success-modal"].show();
    },
    hideSuccessModal() {
      this.$refs["success-modal"].hide();
    },
    async handleValidate() {
      this.tagSetItem({ resource: "isAddNewTagFailed", payload: false });
      this.tagSetItem({ resource: "isAddNewTagFailedError", payload: "" });
      this.productPreviewFailed = false;

      this.$v.formData.$touch();
      this.$v.productGroups.$touch();

      if (
        this.$v.formData.$invalid ||
        this.$v.productGroups.isInvalidProductGroup
      ) {
        return;
      } else {
        let _priceEndDate = "2099-12-31T00:00:00Z";

        this.formData.userId = this.loggedUserId;
        this.formData.productGroups = this.productGroups;
        this.formData.startDate = new Date(
          this.formData.startDate
        ).toISOString();
        this.formData.endDate =
          this.formData.endDate == "" || this.formData.endDate == null
            ? _priceEndDate
            : new Date(this.formData.endDate).toISOString();

        let response = await this.getTagFilteredProducts(this.formData);

        if (this.filteredProductError == false) {
          if (response.status == 1) {
            this.showForm = false;
            this.showFilteredProducts = this.showFormPreview = true;
          } else {
            this.tagSetItem({ resource: "isAddNewTagFailed", payload: true });
            this.tagSetItem({
              resource: "isAddNewTagFailedError",
              payload: response.error,
            });
          }
        } else {
          this.productPreviewFailed = true;
        }
      }
    },
    handleEdit() {
      this.setItem({ resource: "filteredProductError", payload: false });

      if (
        this.formData.endDate == "2099-12-31T00:00:00" ||
        this.formData.endDate == "2099-12-31T00:00:00Z" ||
        this.formData.endDate == "2099-12-31T00:00:00.000Z"
      ) {
        this.formData.endDate = "";
      }

      this.showForm = true;
      this.showFormPreview =
        this.showFilteredProducts =
        this.productPreviewFailed =
          false;
    },
    resetForm() {
      this.resetFormState();
      if (this.isEnableAddNewTag == false) {
        this.addNewTag = false;
        this.enableAddNewTag = false;
        this.showForm = true;
      } else {
        this.addNewTag = true;
        this.showForm = true;
      }
      this.showFormPreview = false;
      this.showFormPreview = false;
      this.showFilteredProducts = false;
      this.productPreviewFailed = false;
      this.$v.$reset();
      this.setItem({ resource: "selectedSite", payload: null });
      this.tagSetItem({ resource: "siteTags", payload: [] });

      // this.formData.id = 0;
      // this.formData.title = "";
      // this.formData.startDate = new Date();
      // this.formData.endDate = "";
      // this.formData.tagId = "";

      this.formData = {
        id: 0,
        title: "",
        siteId: "",
        startDate: new Date(),
        endDate: "",
        tagId: "",
      };

      this.handleChangeTagMethod();
    },
    async submitForm() {
      this.setItem({ resource: "isLoading", payload: true });
      let response = await this.submitTagForm({
        id: this.tagManagerId,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        },
      });
      this.setItem({ resource: "isLoading", payload: false });
      if (response == 1) {
        // this.isFormSubmitted = true;
        this.showSuccessModal();
        // this.formData.siteId = "";
        this.resetForm();
      }
    },
    handleAddNewTag() {
      this.setItem({ resource: "isLoading", payload: true });
      this.addSiteTag({
        id: 0,
        limit: tagManagerConfig.maxTagLimit,
        siteId: this.selectedSite.SiteId,
        tag: `${this.tagPrefix}${this.newTagStr}`,
        userId: this.loggedUserId,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        },
      })
        .then(async (response) => {
          if (response.status == 1) {
            await this.getSiteTags({ siteId: this.selectedSite.SiteId });
            this.formData.tagId = response.data;
            this.addNewTag = this.enableAddNewTag = false;
          }
          this.setItem({ resource: "isLoading", payload: false });
        })
        .catch(() => {
          this.setItem({ resource: "isLoading", payload: false });
        });
    },
    handleDeleteTag() {
      const h = this.$createElement;

      const titleVNode = h("h5", {
        class: ["modal-title font-weight-bold"],
        domProps: { innerHTML: "Delete Tag" },
      });

      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete this tag?", {
          title: [titleVNode],
          size: "sm",
          buttonSize: "md",
          okVariant: "brand-primary w-50",
          cancelVariant: "brand-secondary border-0 w-50",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          headerClass:
            "justify-content-center p-3 border-bottom-0 font-weight-bolder",
          footerClass: "p-3 border-top-0 flex-nowrap",
          hideHeaderClose: true,
          centered: true,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
        })
        .then((value) => {
          if (value) {
            this.setItem({ resource: "isLoading", payload: true });
            this.tagSetItem({ resource: "isAddNewTagFailed", payload: false });
            this.tagSetItem({
              resource: "isAddNewTagFailedError",
              payload: "",
            });
            this.deleteSiteTag({
              siteId: this.selectedSite.SiteId,
              tagId: this.selectedTag.id,
              userId: this.loggedUserId,
              exceptionParams: {
                userId: this.loggedUserId,
                controllerName: this.controllerName,
                moduleName: this.moduleName,
              },
            })
              .then(async (response) => {
                if (response.status == 1) {
                  this.formData.tagId = "";
                  await this.getSiteTags({ siteId: this.selectedSite.SiteId });
                  this.tagSetItem({ resource: "selectedTag", payload: null });
                } else {
                  this.tagSetItem({
                    resource: "isAddNewTagFailed",
                    payload: true,
                  });
                  this.tagSetItem({
                    resource: "isAddNewTagFailedError",
                    payload: response.error,
                  });
                }
                this.setItem({ resource: "isLoading", payload: false });
              })
              .catch(() => {
                // An error occurred
                this.setItem({ resource: "isLoading", payload: false });
              });
          }
        })
        .catch(() => {
          // An error occurred
          this.setItem({ resource: "isLoading", payload: false });
        });
    },
    handleChangeTagMethod() {
      this.enableAddNewTag = false;
      this.newTagStr = this.formData.tagId = "";
      this.tagSetItem({ resource: "selectedTag", payload: null });
      this.tagSetItem({ resource: "isAddNewTagFailed", payload: false });
      this.tagSetItem({ resource: "isAddNewTagFailedError", payload: "" });
    },
  },
  watch: {
    "formData.siteId": function (newVal, oldVal) {
      let selectedSite = this.siteAccess.find((site) => site.SiteId == newVal);

      if (oldVal != "") this.resetForm();
      if (!selectedSite) return;

      this.setItem({ resource: "selectedSite", payload: selectedSite });
      this.tagSetItem({ resource: "siteTags", payload: [] });
      this.getSiteTags({
        siteId: this.selectedSite.SiteId,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        },
      });
      eventBus.$emit("resetFilters");
    },
    selected_language() {
      this.$v.$reset();
    },
    // addNewTag: function () {
    //   this.formData.tagId = ""
    //   this.tagSetItem({ resource: "selectedTag", payload: null });
    // },
    "formData.tagId": function (newVal) {
      if (newVal != "") {
        let selectedTag = this.siteTags.find(
          (_siteTag) => _siteTag.id == newVal
        );
        if (selectedTag)
          this.tagSetItem({ resource: "selectedTag", payload: selectedTag });
      }
    },

    newTagStr: debounce(function (newVal) {
      this.enableAddNewTag = false;
      if (newVal != "") {
        this.enableAddNewTag = true;
      }
    }, 500),
  },
  beforeDestroy() {
    this.resetForm();
    this.tagSetItem({ resource: "tagManagerId", payload: 0 });
    this.tagSetItem({ resource: "mode", payload: "" });
    this.tagSetItem({ resource: "preFilledData", payload: null });
  },
  validations: {
    formData: {
      title: { required },
      siteId: { required },
      tagId: { required },
      startDate: {
        required,
        minValue(value) {
          /*if (this.mode == "edit") {
            return true;
          } else {
            let today = moment();
            today.set("hour", 0);
            today.set("minute", 0);
            today.set("second", 0);
            today.set("millisecond", 0);

            let date = moment(value);
            date.set("hour", 0);
            date.set("minute", 0);
            date.set("second", 0);
            date.set("millisecond", 0);

            if (Date.parse(date) >= Date.parse(today)) {
              //start is greater than/equal to today
              return true;
            } else {
              //start is lesser than today
              return false;
            }
          }*/

          /*let today = moment();
          today.set("hour", 0);
          today.set("minute", 0);
          today.set("second", 0);
          today.set("millisecond", 0);

          let date = moment(value);
          date.set("hour", 0);
          date.set("minute", 0);
          date.set("second", 0);
          date.set("millisecond", 0);

          if (Date.parse(date) >= Date.parse(today)) {
            //start is greater than/equal to today
            return true;
          } else {
            //start is lesser than today
            return false;
          }*/

          let sdate = moment(value);
          sdate.set("hour", 0);
          sdate.set("minute", 0);
          sdate.set("second", 0);
          sdate.set("millisecond", 0);

          let edate = "";

          if (!isEmpty(this.formData.endDate)) {
            edate = moment(this.formData.endDate);
            edate.set("hour", 0);
            edate.set("minute", 0);
            edate.set("second", 0);
            edate.set("millisecond", 0);

            if (
              moment(edate)._i == "2099-12-31T00:00:00" ||
              moment(edate)._i == "2099-12-31T00:00:00Z" ||
              moment(edate)._i == "2099-12-31T00:00:00.000Z"
            ) {
              edate = "";
            }
          }

          let status = this.checkStartDateValidation(sdate, edate, this.mode);

          return status;
        },
      },
      endDate: {
        minValue(value) {
          /*if (value == "" || value == null) return true;
          let startDate = moment(this.formData.startDate);
          startDate.set("hour", 0);
          startDate.set("minute", 0);
          startDate.set("second", 0);
          startDate.set("millisecond", 0);

          let date = moment(value);
          date.set("hour", 0);
          date.set("minute", 0);
          date.set("second", 0);
          date.set("millisecond", 0);

          if (Date.parse(date) >= Date.parse(startDate)) {
            //end is greater than/equal to start
            return true;
          } else {
            //end is less than start
            return false;
          }*/

          let sdate = moment(this.formData.startDate);
          sdate.set("hour", 0);
          sdate.set("minute", 0);
          sdate.set("second", 0);
          sdate.set("millisecond", 0);

          let edate = moment(value);
          edate.set("hour", 0);
          edate.set("minute", 0);
          edate.set("second", 0);
          edate.set("millisecond", 0);

          if (
            moment(edate)._i == "2099-12-31T00:00:00" ||
            moment(edate)._i == "2099-12-31T00:00:00Z" ||
            moment(edate)._i == "2099-12-31T00:00:00.000Z"
          ) {
            edate = "";
          }

          let status = this.checkEndDateValidation(sdate, edate);

          return status;
        },
      },
    },
    productGroups: {
      isInvalidProductGroup,
    },
  },
};
</script>
