import { eventBus } from "@/main";
import ajax from "@/customApi";
const qs = require("qs");
import config from "../../../config";

const { path, messages, responseStatus } = config;

const service = {
  getActivePromotions,
  getHistory,
  getExportPreviewData,
  submitPricingForm,
  getPricingHistory,
  validateFlatFlyerProducts,
  updateStatus,
  verifyFlatFlyerCsv,
  submitFlatFlyerCsv,
  verifyPromotionalImportCsv,
  submitPromotionalImportCsv,

  verifyImportedCsv,
  submitImportedCsv,
};

async function getActivePromotions(params) {
  try {
    const { data } = await ajax.get(path.getTodayActivePrice, {
      name: "getActivePromotions",
      params,
    });

    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);
    ajax.post(path.exception, {
      name: "getActivePromotions",
      body: {
        id: 0,
        userId: params.userId,
        exceptionMessage: error.response.data,
        controllerName: "ActivePromotion.vue",
        actionName: "getActivePromotions",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getTodayActivePrice}`,
        moduleName: "Active Promotions",
        isApi: 0,
      },
    });

    return [];
  }
}

async function getHistory(params) {
  try {
    const { data } = await ajax.get(path.getHistory, {
      name: "getHistory",
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "getHistory",
      body: {
        id: 0,
        userId: params.userId,
        exceptionMessage: error.response.data,
        controllerName: "Pricingmanager-History.vue",
        actionName: "getHistory",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.getHistory}`,
        moduleName: "Pricing Manager History",
        isApi: 0,
      },
    });

    return [];
  }
}

async function getExportPreviewData(params) {
  let { exceptionParams, pricingManagerId } = params;
  delete params.exceptionParams;
  try {
    const { data } = await ajax.get(path.exportPreviewData, {
      name: "getExportPreviewData",
      params: {
        pricingManagerId: pricingManagerId,
      },
    });

    return data;
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return [];
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "getExportPreviewData",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "getExportPreviewData",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.exportPreviewData}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return [];
  }
}

async function submitPricingForm(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    const { data } = await ajax.post(path.postPricingManager, {
      name: "submitPricingForm",
      body: null,
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    if (data == 1) {
      return { status: 1 };
    } else {
      return { status: 0 };
    }
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return { status: 0, error: error };
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "submitPricingForm",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "submitPricingForm",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.postPricingManager}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return { status: 0, error: error };
  }
}

async function getPricingHistory(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    const { data, statusCode } = await ajax.get(path.editFromHistory, {
      name: "getPricingHistory",
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    if (statusCode == responseStatus.NotFound) {
      return { status: 0, data: [], error: messages.somethingwentWrong };
    }

    let resData = JSON.parse(data[0].jsonResult);

    return { status: 1, data: resData[0] };
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return { status: 0, data: [], error: error };
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "getPricingHistory",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "getPricingHistory",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.editFromHistory}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return { status: 0, data: [], error: error };
  }
}

async function validateFlatFlyerProducts(_params) {
  let { exceptionParams } = _params;

  delete _params.exceptionParams;

  try {
    const { data } = await ajax.post(path.flatflyervalidate, {
      name: "validateFlatFlyerProducts",
      body: _params.data,
      params: {
        pricingmanagerId: _params.pricingManagerId,
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    return { status: 1, data: data };
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return { status: 0, data: [], error: error };
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "validateFlatFlyerProducts",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "validateFlatFlyerProducts",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.flatflyervalidate}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return { status: 0, data: [], error: error };
  }
}
async function updateStatus(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    await ajax.post(path.updateStatus, {
      name: "updateStatus",
      body: null,
      params: params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return;
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "updateStatus",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "Update Pricing Status",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.updateStatus}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });
  }
}

async function verifyFlatFlyerCsv(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    const { data } = await ajax.post(path.importCsvFlatFlyerDataVerify, {
      name: "verifyFlatFlyerCsv",
      body: params.data,
    });
    return { status: 1, data: data };
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return { status: 0, data: null, error: error };
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "verifyFlatFlyerCsv",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "verifyFlatFlyerCsv",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.importCsvFlatFlyerDataVerify}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return { status: 0, data: null, error: error };
  }
}

async function submitFlatFlyerCsv(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    await ajax.post(path.importCsvFlatFlyerDataValidate, {
      name: "importCsvFlatFlyerDataValidate",
      body: params,
    });
    return { status: 1, data: null };
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return { status: 0, data: null, error: error };
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "importCsvFlatFlyerDataValidate",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "importCsvFlatFlyerDataValidate",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.importCsvFlatFlyerDataValidate}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return { status: 0, data: null, error: error };
  }
}

async function verifyPromotionalImportCsv(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    const { data } = await ajax.post(path.verifyPromotionalImportCsv, {
      name: "verifyPromotionalImportCsv",
      body: params.data,
    });
    return { status: 1, data: data };
  } catch (error) {
    eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "verifyPromotionalImportCsv",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "verifyPromotionalImportCsv",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.verifyPromotionalImportCsv}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return { status: 0, data: null, error: error };
  }
}

async function submitPromotionalImportCsv(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    await ajax.post(path.importCsvPromotionalPricingDataValidate, {
      name: "importCsvPromotionalPricingDataValidate",
      body: params,
    });
    return { status: 1, data: null };
  } catch (error) {
    eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "importCsvPromotionalPricingDataValidate",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "importCsvPromotionalPricingDataValidate",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.importCsvPromotionalPricingDataValidate}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return { status: 0, data: null, error: error };
  }
}

async function verifyImportedCsv(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    const { data } = await ajax.post(path.importCsvDataVerify, {
      name: "verifyImportedCsv",
      body: params.data,
    });
    return { status: 1, data: data };
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return { status: 0, data: null, error: error };
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "verifyImportedCsv",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "verifyImportedCsv",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.importCsvDataVerify}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return { status: 0, data: null, error: error };
  }
}

async function submitImportedCsv(params) {
  let { exceptionParams } = params;

  delete params.exceptionParams;

  try {
    await ajax.post(path.importCsvSubmit, {
      name: "submitImportedCsv",
      body: params,
    });
    return { status: 1, data: null };
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
      return { status: 0, data: null, error: error };
    } else eventBus.$emit("apiError", error.message);

    ajax.post(path.exception, {
      name: "submitImportedCsv",
      body: {
        id: 0,
        userId: exceptionParams.userId,
        exceptionMessage: error.response.data,
        controllerName: exceptionParams.controllerName,
        actionName: "submitImportedCsv",
        // exceptionStackTrace: error.stack,
        exceptionStackTrace: `API : ${path.importCsvSubmit}`,
        moduleName: exceptionParams.moduleName,
        isApi: 0,
      },
    });

    return { status: 0, data: null, error: error };
  }
}

export default service;
